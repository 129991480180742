import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.transactions)

const transactionsReducer = (state = Data.transactions, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_TRANSACTION: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.RESET_TRANSACTION_DATA:
      return {
        ...state,
        mer_summury: {
          txnSummary: []
        }
      }

    case actionTypes.TRANSACTION_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata
      }

    case actionTypes.ERROR_MEESAGE_TRANSACTION: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.TXN_STATEMENT_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.TXN_STATEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        statement: action.responseData
      };
    }

    case actionTypes.TXN_STATEMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        statement: action.responseData
      };
    }

    case actionTypes.MER_TXN_SUMMURY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.MER_TXN_SUMMURY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        mer_summury: action.responseData
      };
    }

    case actionTypes.MER_TXN_SUMMURY_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        mer_summury: action.responseData
      };
    }

    default: {
      return state;
    }
  }
};

export default transactionsReducer;
