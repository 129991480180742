import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
// import { push } from 'connected-react-router';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography, Dialog, DialogContent, DialogActions, Button, DialogContentText, DialogTitle } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import { logout } from 'actions';
import version from '../../../../utils/version';

import _ from 'lodash';

// import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  // const router = useRouter();
  const session = useSelector(state => state.session);

  const [dialog, setDialogLogout] = React.useState({
    open:false,
    title: '',
    message: ''
  });

  const setDialog = (value) => {
    onMobileClose();
    setDialogLogout(value);
    // console.log(value);
  }

  const handleClose = () => {
    dispatch(logout());
  }

  // useEffect(() => {
  //   if (openMobile) {
  //     onMobileClose && onMobileClose();
  //   }
  // }, [router.location.pathname]);

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt={session.user.fullname}
          className={classes.avatar}
          component={RouterLink}
          src={session.user.avatar}
        />
        <Typography
          className={classes.name}
          variant="h4"
        >
          {_.startCase(_.camelCase(session.user.fullname))}
        </Typography>
        <Typography variant="body2">{session.user.phone_number}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
            setDialog={setDialog}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
            <div style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              textAlign: 'center',
              padding: '10px',
              background: '#7ebe28',
              color: 'white'
            }}>
              <span style={{fontSize: '17px', fontWeight: 'bold'}}>Version: {version.number}</span>
            </div>
          </div>
          
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>

      <Dialog
        open={dialog.open}
        onClose={() => setDialogLogout({
          ...dialog,
          open: false
        })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogLogout({
            ...dialog,
            open: false
          })} color="primary">
            No
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
