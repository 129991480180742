import _ from 'lodash';
// import moment from 'moment';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';

import { logout } from 'actions';

// GET PACKAGE LIST
export const GET_PACKAGE_LIST_COMBO_REQUEST = 'GET_PACKAGE_LIST_COMBO_REQUEST';
export const GET_PACKAGE_LIST_COMBO_SUCCESS = 'GET_PACKAGE_LIST_COMBO_SUCCESS';
export const GET_PACKAGE_LIST_COMBO_FAILURE = 'GET_PACKAGE_LIST_COMBO_FAILURE';

// GET CARD LIST
export const CARD_LIST_COMBO_REQUEST = 'CARD_LIST_COMBO_REQUEST';
export const CARD_LIST_COMBO_SUCCESS = 'CARD_LIST_COMBO_SUCCESS';
export const CARD_LIST_COMBO_FAILURE = 'CARD_LIST_COMBO_FAILURE';

// GET CHARGES TOPUP
export const GET_CHARGES_COMBO_REQUEST = 'GET_CHARGES_COMBO_REQUEST';
export const GET_CHARGES_COMBO_SUCCESS = 'GET_CHARGES_COMBO_SUCCESS';
export const GET_CHARGES_COMBO_FAILURE = 'GET_CHARGES_COMBO_FAILURE';

// TOPUP
export const TOPUP_COMBO_REQUEST = 'TOPUP_COMBO_REQUEST';
export const TOPUP_COMBO_SUCCESS = 'TOPUP_COMBO_SUCCESS';
export const TOPUP_COMBO_FAILURE = 'TOPUP_COMBO_FAILURE';

// TOPUP SW
export const TOPUP_SW_COMBO_REQUEST = 'TOPUP_SW_COMBO_REQUEST';
export const TOPUP_SW_COMBO_SUCCESS = 'TOPUP_SW_COMBO_SUCCESS';
export const TOPUP_SW_COMBO_FAILURE = 'TOPUP_SW_COMBO_FAILURE';

// LIMIT CHECK
export const COMBO_LIMIT_CHECK_REQUEST = 'COMBO_LIMIT_CHECK_REQUEST';
export const COMBO_LIMIT_CHECK_SUCCESS = 'COMBO_LIMIT_CHECK_SUCCESS';
export const COMBO_LIMIT_CHECK_FAILURE = 'COMBO_LIMIT_CHECK_FAILURE';

// COMBO TOPUP SEND OTP
export const COMBO_OTP_SEND_REQUEST = 'COMBO_OTP_SEND_REQUEST';
export const COMBO_OTP_SEND_SUCCESS = 'COMBO_OTP_SEND_SUCCESS';
export const COMBO_OTP_SEND_FAILURE = 'COMBO_OTP_SEND_FAILURE';

// GET TRANSACTION ENQUIRY
export const GET_TRANSACTION_ENQUIRY_COMBO_REQUEST = 'GET_TRANSACTION_ENQUIRY_COMBO_REQUEST';
export const GET_TRANSACTION_ENQUIRY_COMBO_SUCCESS = 'GET_TRANSACTION_ENQUIRY_COMBO_SUCCESS';
export const GET_TRANSACTION_ENQUIRY_COMBO_FAILURE = 'GET_TRANSACTION_ENQUIRY_COMBO_FAILURE';

// Form Data Change
export const COMBO_FORM_DATA_CHANGE = 'COMBO_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_COMBO = 'BACK_HOME_COMBO';

// Error Message
export const ERROR_MEESAGE_COMBO = 'ERROR_MEESAGE_COMBO';

// RESET CHARGES
export const RESET_COMBO_CHARGES = 'RESET_COMBO_CHARGES';

// DIALOG TOPUP
export const DIALOG_COMBO_TOPUP = 'DIALOG_COMBO_TOPUP'

// CHOOSE_PACKAGE_TYPE
export const CHOOSE_PACKAGE_COMBO_TYPE = 'CHOOSE_PACKAGE_COMBO_TYPE';

// Back Action
export const BACK_ERROR_COMBOUP = 'BACK_ERROR_COMBOUP';

export const dialogShow = (dialog) => dispatch => {
  dispatch({
    type: DIALOG_COMBO_TOPUP,
    dialog: dialog
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_COMBO,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: COMBO_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const choosePackage = (packageType) => dispatch => {
  dispatch({
    type: CHOOSE_PACKAGE_COMBO_TYPE,
    packageType: packageType
  })
}

export const SelectCardInfo = (cardId) => dispatch => {
  let formdata = store.getState().combo.formdata;
  formdata.cardId = cardId;
  formdata.selectCardInfo = _.find(store.getState().combo.cardList.cardList, {cardId: cardId});
  dispatch({
    type: COMBO_FORM_DATA_CHANGE,
    formdata: formdata,
  });
}

export const getPackages = () => dispatch => {
  let user = store.getState().session.user;
  dispatch({
    type: GET_PACKAGE_LIST_COMBO_REQUEST,
  });

  Instance
    .post('/citycash-bc/getPackages/', {
      requestId: requestId(),
      txnType: 'get_packages',
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      loadType: 'combo',
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200) {
          dispatch({
            type: GET_PACKAGE_LIST_COMBO_SUCCESS,
            packageList: response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_COMBO,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_PACKAGE_LIST_COMBO_FAILURE,
        packageList: error
      });
    })
}

export const getCardList = () => dispatch => {
  let formdata = store.getState().combo.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: CARD_LIST_COMBO_REQUEST
  });

  Instance
    .post('/citycash-bc/getCardListForOnlineRecharge/', {
      customerIdentifierType: formdata.customerIdentifierValue.toString().length === 10 ? 'phone_number' : 'card_id',
      customerIdentifierValue: formdata.customerIdentifierValue,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      rechargeType: 'sw_topup',
      amount: formdata.amount,
      requestId: requestId(),
      txnType: 'get_card_list_for_online_recharge',
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          console.log('>>>>', response.data);
          if(response.data.cardList.length > 0){
            // let cardList = response.data.cardList.filter(card =>card.cardStatus === 'ISD');
            // formdata.cardId = cardList.length > 0 ? cardList[0].cardId : '';
            // formdata.selectCardInfo = _.find(response.data.cardList, {cardId: formdata.cardId});
            formdata.activeStep = formdata.activeStep + 1;
          } else {
            dispatch({
              type: ERROR_MEESAGE_COMBO,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: formdata.customerIdentifierValue.length === 10 ? 'Invalid mobile number' : 'Invalid card number'
              }
            });
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CARD_LIST_COMBO_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.error(error)
      dispatch({
        type: ERROR_MEESAGE_COMBO,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CARD_LIST_COMBO_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const getCharges = () => dispatch => {
  let formdata = store.getState().combo.formdata;
  // let dialog = store.getState().combo.dialog;
  let user = store.getState().session.user;
  dispatch({
    type: GET_CHARGES_COMBO_REQUEST
  });
  Instance
    .post('/citycash-bc/OTCPkgTWSWCharges/', {
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      amountType: 2,
      txnType: 'get_otc_reg_charges',
      amount: formdata.amount,
      packageType: formdata.packageType,
      packageAmount: formdata.amount,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.selectCardInfo.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: GET_CHARGES_COMBO_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_COMBO,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_CHARGES_COMBO_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const topupCombo = () => dispatch => {
  let combo = store.getState().combo;
  let formdata = combo.formdata;
  let getCharges = combo.getCharges;
  let user = store.getState().session.user;
  dispatch({
    type: TOPUP_COMBO_REQUEST
  });

  let topupdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    auth: {
      otp: formdata.otp,
      referenceId: combo.otpInfo.referenceId
    },
    txnType: 'sw_topup',
    paymentMode: formdata.paymentMode,
    amountType: 2,
    amount: getCharges.amount,
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.selectCardInfo.cardId
    },
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    cardType: formdata.selectCardInfo.cardType === 'Over the counter card' ? 'OTC': 'PPC',
    packageType: formdata.packageType,
    packageAmount: formdata.amount,
    loginId: user.loginId,
    LoadingFeeSw: getCharges.ChargeList.LoadingFeeSw,
    LoadingFeeTw: getCharges.ChargeList.LoadingFeeTw,
    GSTSw: getCharges.ChargeList.GSTSw,
    GSTTw: getCharges.ChargeList.GSTTw,
    BonusAmt: getCharges.ChargeList.BonusAmt,
    CardFee: getCharges.ChargeList.CardFee,
    BCChargesSw: getCharges.ChargeList.BCChargesSw,
    BCChargesTw: getCharges.ChargeList.BCChargesTw,
    TWePurseFees: getCharges.ChargeList.TWePurseFees,
    SWePurseFees: getCharges.ChargeList.SWePurseFees,
    TotalCharge: getCharges.ChargeList.TotalCharge,
    discount: getCharges.ChargeList.discount,
    cashbackTw: getCharges.ChargeList.cashbackTw,
    cashbackSw: getCharges.ChargeList.cashbackSw,
    EffectiveAmountTw: getCharges.EffectiveAmountTw,
    EffectiveAmountSw: getCharges.EffectiveAmountSw
  };

  console.log('>>>>>>', topupdata);

  let url = '/citycash-bc/OTCPkgTWSWTopup/';
  Instance
    .post(url, topupdata)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
          formdata.backhide = true;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Combo_Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else if(step === 'Topup_Success') {
              console.log('>>>> Remove')
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: TOPUP_COMBO_SUCCESS,
        formdata: formdata,
        topupdata: topupdata,
        responseData: response.data
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verification'){
            allSteps.add('Otp_Verification');
            allSteps.add('Combo_Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else if(step === 'Topup_Success') {
            console.log('>>>> Remove')
          } else {
            allSteps.add(step);
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = [...allSteps];
        dispatch({
          type: TOPUP_COMBO_SUCCESS,
          formdata: formdata,
          topupdata: topupdata,
          responseData: error
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Combo_Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else if(step === 'Topup_Success') {
              console.log('>>>> Remove')
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
          dispatch({
            type: TOPUP_COMBO_SUCCESS,
            formdata: formdata,
            topupdata: topupdata,
            responseData: error
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: TOPUP_COMBO_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const checkLimit = (cardId) => dispatch => {
  let formdata = store.getState().combo.formdata;
  let user = store.getState().session.user;

  let selectCardInfo = _.find(store.getState().combo.cardList.cardList, {cardId: cardId});
  
  dispatch({
    type: COMBO_LIMIT_CHECK_REQUEST
  });

  let checklimitdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'sw_limit',
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : selectCardInfo.phoneNumber,
      custCardId: selectCardInfo.cardId
    },
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    packageType: formdata.packageType,
    packageAmount: formdata.amount,
    loginId: user.loginId,
  };

  let url = '/citycash-bc/OTCPkgTWSWLimit/';
  Instance
    .post(url, checklimitdata)
    .then(response => {
      if(response.status === 200) {
        console.log('>>>>>>', response.data);
        if(response.data.responseCode === 200) {
          formdata.cardId = cardId;
          formdata.selectCardInfo = selectCardInfo;
          console.log('>>>> sucess');
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
        dispatch({
          type: COMBO_LIMIT_CHECK_SUCCESS,
          formdata: formdata,
          checklimitdata: checklimitdata,
          responseData: response.data,
        });
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_COMBO,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: COMBO_LIMIT_CHECK_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const topupSW = () => dispatch => {
  let formdata = store.getState().combo.formdata;
  let getCharges = store.getState().combo.getCharges;
  let user = store.getState().session.user;
  dispatch({
    type: TOPUP_SW_COMBO_REQUEST
  });
  let topupdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'sw_topup',
    paymentMode: formdata.paymentMode,
    amountType: 2,
    amount: formdata.amount,
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.cardId
    },
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    loginId: user.loginId,
    LoadingFee: getCharges.ChargeList.LoadingFeeSw,
    GST: getCharges.ChargeList.GSTSw,
    totalChargeableAmount: 0,
    CardFee: 0,
    BCCharges: getCharges.ChargeList.BCChargesSw,
    TotalCharge: getCharges.ChargeList.TotalCharge,
    EffectiveAmount: getCharges.EffectiveAmountSw,
    packageType: formdata.packageType,
    packageAmount: formdata.amount,
    ePurseFees: 0,
    discount: getCharges.ChargeList.discount,
    cashbackTw: getCharges.ChargeList.cashbackTw,
    cashbackSw: getCharges.ChargeList.cashbackSw,
    auth: {
      otp: formdata.otp,
      referenceId: store.getState().combo.otpInfo.referenceId
    }
  };

  let url = '/citycash-bc/SWTopUp/';
  Instance
    .post(url, topupdata)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
          formdata.backhide = true;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 3000){
          formdata.activeStep += 1;
          formdata.backhide = true;
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
            } else {
              allSteps.add(step);
            }
          });
          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Topup_Combo_Success');
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: TOPUP_SW_COMBO_SUCCESS,
        formdata: formdata,
        topupdataSW: topupdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verification'){
            allSteps.add('Otp_Verification');
            allSteps.add('Transaction_Enquiry');
          } else {
            allSteps.add(step);
          }
        });
        // formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Topup_Combo_Success');
        dispatch({
          type: TOPUP_SW_COMBO_SUCCESS,
          formdata: formdata,
          topupdataSW: topupdata,
          responseData: error
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
            } else {
              allSteps.add(step);
            }
          });
          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Topup_Combo_Success');
          dispatch({
            type: TOPUP_SW_COMBO_SUCCESS,
            formdata: formdata,
            topupdataSW: topupdata,
            responseData: error
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({      
            type: TOPUP_SW_COMBO_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiryCombo = () => dispatch => {
  let formdata = store.getState().combo.formdata;
  let EnquiryData = store.getState().combo.topupdata;
  let user = store.getState().session.user;
  // let getCharges = store.getState().combo.getCharges;
  let txnType = 'sw_txn_enquiry';
  let mobileNumber = (formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber);
  dispatch({
    type: GET_TRANSACTION_ENQUIRY_COMBO_REQUEST
  });

  let topupEnquiry = {
    txnType: txnType,
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    loginId: user.loginId,
    agentId: user.agentId,
    divisionId: user.divisionId,
    depoCode: user.depotCode,
    custIdentifier: {
      mobileNumber: mobileNumber,
      custCardId: formdata.cardId
    },
    token: user.tokenInfo.token,
    RequestData: EnquiryData,
    // RequestData: {
    //   paymentMode: formdata.paymentMode,
    //   packageType: formdata.packageType,
    //   packageAmount: formdata.amount,
    //   amount: formdata.amount,
    //   amountType: 2,
    //   requestId: store.getState().combo.topup.requestId,
    //   initiatorId: user.initiatorId,
    //   initiatorType: user.initiatorType,
    //   loginId: user.loginId,
    //   sessionId: user.sessionId,
    //   LoadingFeeSw: getCharges.ChargeList.LoadingFeeSw,
    //   LoadingFeeTw: getCharges.ChargeList.LoadingFeeTw,
    //   GSTSw: getCharges.ChargeList.GSTSw,
    //   GSTTw: getCharges.ChargeList.GSTTw,
    //   BonusAmt: getCharges.ChargeList.BonusAmt,
    //   CardFee: getCharges.ChargeList.CardFee,
    //   TWePurseFees: getCharges.ChargeList.TWePurseFees,
    //   SWePurseFees: getCharges.ChargeList.SWePurseFees,
    //   BCChargesSw: getCharges.ChargeList.BCChargesSw,
    //   BCChargesTw: getCharges.ChargeList.BCChargesTw,
    //   TotalCharge: getCharges.ChargeList.TotalCharge,
    //   discount: getCharges.ChargeList.discount,
    //   cashbackTw: getCharges.ChargeList.cashbackTw,
    //   cashbackSw: getCharges.ChargeList.cashbackSw,
    //   EffectiveAmountTw: getCharges.EffectiveAmountTw,
    //   EffectiveAmountSw: getCharges.EffectiveAmountSw
    // }
  };

  let url = '/citycash-bc/OTCPkgTWSWEnquiry/';
  Instance
    .post(url , topupEnquiry)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 4999){
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          formdata.activeStep += 1;
          formdata.backhide = true;
        }
        dispatch({
          type: GET_TRANSACTION_ENQUIRY_COMBO_SUCCESS,
          formdata: formdata,
          transactionEnquiry: response.data,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_COMBO,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_TRANSACTION_ENQUIRY_COMBO_FAILURE,
        formdata: formdata,
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().combo.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_COMBO,
    error: errorObject
  });
}

export const resetCharges = () => dispatch => {
  dispatch({
    type: RESET_COMBO_CHARGES
  });
}

export const sendOtp = (resend) => dispatch => {
  let formdata = store.getState().combo.formdata;
  let cardType = formdata.selectCardInfo.cardType;
  let user = store.getState().session.user;
  dispatch({
    type: COMBO_OTP_SEND_REQUEST,
  });
  Instance
    .post('/citycash-bc/generateOTP', {
      txnType: 'otp_generation',
      cardType: cardType === 'Over the counter card' ? 'OTC': 'PPC',
      messageId: 8,
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(resend === 0){
            formdata.activeStep = formdata.activeStep + 1;
          }
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `Otp send the mobile number (${formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber})`
            }
          });
          dispatch({
            type: COMBO_OTP_SEND_SUCCESS,
            formdata: formdata,
            otpInfo:  response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_COMBO,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
          dispatch({
            type: COMBO_OTP_SEND_SUCCESS,
            formdata: formdata,
            otpInfo:  response.data
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_COMBO,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: COMBO_OTP_SEND_FAILURE,
        otpInfo: error
      });
    })
}

export const backAction = (status) => dispatch => {
  let formdata = store.getState().combo.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_COMBOUP,
    formdata: formdata
  })
}