import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// GET CARD LIST
export const SURRENDER_CARD_LIST_REQUEST = 'SURRENDER_CARD_LIST_REQUEST';
export const SURRENDER_CARD_LIST_SUCCESS = 'SURRENDER_CARD_LIST_SUCCESS';
export const SURRENDER_CARD_LIST_FAILURE = 'SURRENDER_CARD_LIST_FAILURE';

// GET CHARGES TOPUP
export const GET_CHARGES_SURRENDER_REQUEST = 'GET_CHARGES_SURRENDER_REQUEST';
export const GET_CHARGES_SURRENDER_SUCCESS = 'GET_CHARGES_SURRENDER_SUCCESS';
export const GET_CHARGES_SURRENDER_FAILURE = 'GET_CHARGES_SURRENDER_FAILURE';

// VALIDATION SURRENDER
export const SURRENDER_VALIDATION_REQUEST = 'SURRENDER_VALIDATION_REQUEST';
export const SURRENDER_VALIDATION_SUCCESS = 'SURRENDER_VALIDATION_SUCCESS';
export const SURRENDER_VALIDATION_FAILURE = 'SURRENDER_VALIDATION_FAILURE';

// SURRENDER
export const SURRENDER_REQUEST = 'SURRENDER_REQUEST';
export const SURRENDER_SUCCESS = 'SURRENDER_SUCCESS';
export const SURRENDER_FAILURE = 'SURRENDER_FAILURE';

// GET TRANSACTION ENQUIRY
export const GET_SURRENDER_TRANSACTION_ENQUIRY_REQUEST = 'GET_SURRENDER_TRANSACTION_ENQUIRY_REQUEST';
export const GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS = 'GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS';
export const GET_SURRENDER_TRANSACTION_ENQUIRY_FAILURE = 'GET_SURRENDER_TRANSACTION_ENQUIRY_FAILURE';

// Otp Send
export const SURRENDER_OTP_SEND_REQUEST = 'SURRENDER_OTP_SEND_REQUEST';
export const SURRENDER_OTP_SEND_SUCCESS = 'SURRENDER_OTP_SEND_SUCCESS';
export const SURRENDER_OTP_SEND_FAILURE = 'SURRENDER_OTP_SEND_FAILURE';

// Form Data Change
export const SURRENDER_FORM_DATA_CHANGE = 'SURRENDER_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_SURRENDER = 'BACK_HOME_SURRENDER';

// Error Message
export const ERROR_MEESAGE_SURRENDER = 'ERROR_MEESAGE_SURRENDER';

// Back Action
export const BACK_ERROR_SURRENDER = 'BACK_ERROR_SURRENDER';

export const backAction = (status) => dispatch => {
  let formdata = store.getState().surrender.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_SURRENDER,
    formdata: formdata
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_SURRENDER,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: SURRENDER_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const SelectCardInfo = (cardId) => dispatch => {
  let formdata = store.getState().surrender.formdata;
  formdata.cardId = cardId;
  formdata.selectCardInfo = _.find(store.getState().surrender.cardList.cardList, {cardId: cardId});
  dispatch({
    type: SURRENDER_FORM_DATA_CHANGE,
    formdata: formdata,
  });
}

export const getCardList = (type) => dispatch => {
  let formdata = store.getState().surrender.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: SURRENDER_CARD_LIST_REQUEST
  });

  Instance
    .post('/citycash-bc/getCardListForOnlineRecharge/', {
      customerIdentifierType: formdata.customerIdentifierValue.toString().length === 10 ? 'phone_number' : 'card_id',
      customerIdentifierValue: formdata.customerIdentifierValue.toString(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      rechargeType: 'surrender',
      amount: '',
      requestId: requestId(),
      txnType: 'get_card_list_for_online_recharge',
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(response.data.cardList.length > 0){
            formdata.activeStep = formdata.activeStep + 1;
            if(type === 'sw'){
              let cardList = response.data.cardList.filter(card =>card.cardStatus === 'ISD');
              formdata.cardId = cardList.length === 1 ? cardList[0].cardId : '';
              if(formdata.cardId !== ''){
                formdata.selectCardInfo = _.find(response.data.cardList, {cardId: formdata.cardId});
              }
            }
          } else {
            dispatch({
              type: ERROR_MEESAGE_SURRENDER,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: formdata.customerIdentifierValue.length === 10 ? 'Invalid mobile number' : 'Invalid card number'
              }
            });
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: SURRENDER_CARD_LIST_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_SURRENDER,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: SURRENDER_CARD_LIST_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const getCharges = () => dispatch => {
  let formdata = store.getState().surrender.formdata;
  // let dialog = store.getState().surrender.dialog;
  let user = store.getState().session.user;

  dispatch({
    type: GET_CHARGES_SURRENDER_REQUEST
  });

  Instance
    .post('/citycash-bc/GetOnlineTransactionCharges/', {
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      txnType: 'get_surrender_charges',
      amount: 0,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId,
      ePurseFees: 0,
      divisionId: ''
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: GET_CHARGES_SURRENDER_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_SURRENDER,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_CHARGES_SURRENDER_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const surrenderCheck = (resend) => dispatch => {
  let formdata = store.getState().surrender.formdata;
  let cardType = formdata.selectCardInfo.cardType;
  let user = store.getState().session.user;

  dispatch({
    type: SURRENDER_VALIDATION_REQUEST,
  });

  const requestData = {
    txnType: 'surrender_validate',
    cardType: cardType === 'Over the counter card' ? 'OTC': cardType === 'Physical Personalised Card' ? 'PPC' : 'U18',
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.cardId
    },
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    loginId: user.loginId,
    depoCode: user.depotCode,
    hopType: ''
  };

  Instance
    .post('/citycash-bc/preSurrenderValidation/', requestData)
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(resend === 0){
            formdata.activeStep = formdata.activeStep + 1;
          }

          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `OTP send the mobile number (${formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber})`
            }
          });
          dispatch({
            type: SURRENDER_OTP_SEND_SUCCESS,
            formdata: formdata,
            otpInfo:  response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
      } else {
        dispatch({
          type: ERROR_MEESAGE_SURRENDER,
          error: {
            errorStatus: true,
            errorType: 'error',
            errorMessage: response.statusText
          }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_SURRENDER,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: SURRENDER_VALIDATION_FAILURE,
        otpInfo: error
      });
    })
}

export const surrender = () => dispatch => {
  let formdata = store.getState().surrender.formdata;
  let getCharges = store.getState().surrender.getCharges;
  let user = store.getState().session.user;
  let otpInfo = store.getState().surrender.otpInfo;
  let cardType = formdata.selectCardInfo.cardType === 'Over the counter card' ? 'OTC': formdata.selectCardInfo.cardType === 'Physical Personalised Card' ? 'PPC' : 'U18';

  dispatch({
    type: SURRENDER_REQUEST
  });

  let surrenderdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'surrender',
    paymentMode: formdata.paymentMode,
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.cardId
    },
    auth: {
      otp: formdata.otp,
      referenceId: otpInfo.referenceId
    },
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    loginId: user.loginId,
    hopType: '',
    ChargeList: getCharges.ChargeList,
    cardType: cardType
  };

  let url = '/citycash-bc/surrenderCard/';
  
  Instance
    .post(url, surrenderdata)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Surrender_Success');
        } else if([4190].includes(response.data.responseCode) && ['U18'].includes(cardType)) {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else if([3000].includes(response.data.responseCode) && ['OTC', 'PPC'].includes(cardType)) {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          formdata.activeStep += 1;
          formdata.backhide = true;
        }
      }
      dispatch({
        type: SURRENDER_SUCCESS,
        formdata: formdata,
        surrenderdata: surrenderdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error.code));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verification'){
            allSteps.add('Otp_Verification');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            allSteps.add(step);
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Surrender_Success');
        dispatch({
          type: SURRENDER_SUCCESS,
          formdata: formdata,
          surrenderdata: surrenderdata,
          responseData: error,
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Surrender_Success');
          dispatch({
            type: SURRENDER_SUCCESS,
            formdata: formdata,
            surrenderdata: surrenderdata,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: SURRENDER_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiry = () => dispatch => {
  let formdata = store.getState().surrender.formdata;
  let user = store.getState().session.user;
  let inqueryData = store.getState().surrender.surrenderdata;

  dispatch({
    type: GET_SURRENDER_TRANSACTION_ENQUIRY_REQUEST
  });

  let surrenderdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'surrender_txn_enquiry',
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    RequestData: inqueryData
  };
  
  Instance
    .post('/citycash-bc/surrenderTxnEnquiry/', surrenderdata)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if([200].includes(response.data.responseCode)) {
          if(response.data.ResponseData.responseCode === 4999){
            dispatch({
              type: ERROR_MEESAGE_SURRENDER,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: response.data.ResponseData.responseMessage
              }
            });
          } else {
            formdata.activeStep += 1;
            formdata.backhide = true;
            dispatch({
              type: GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS,
              formdata: formdata,
              responseData: response.data,
            });
          }
        } else {
          formdata.activeStep += 1;
          formdata.backhide = true;
          dispatch({
            type: GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS,
            formdata: formdata,
            surrenderdata: surrenderdata,
            responseData: response.data,
          });
        }
      }
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verification'){
            allSteps.add('Otp_Verification');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            allSteps.add(step);
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Surrender_Success');
        dispatch({
          type: GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS,
          formdata: formdata,
          surrenderdata: surrenderdata,
          responseData: error,
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Surrender_Success');
          dispatch({
            type: GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS,
            formdata: formdata,
            surrenderdata: surrenderdata,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: GET_SURRENDER_TRANSACTION_ENQUIRY_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const sendOtp = (resend) => dispatch => {
  let formdata = store.getState().surrender.formdata;
  let cardType = formdata.selectCardInfo.cardType;
  let user = store.getState().session.user;

  dispatch({
    type: SURRENDER_OTP_SEND_REQUEST,
  });

  Instance
    .post('/citycash-bc/generateOTP', {
      txnType: 'otp_generation',
      cardType: cardType === 'Over the counter card' ? 'OTC': cardType === 'Physical Personalised Card' ? 'PPC' : 'U18',
      messageId: 10,
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          // if its not resend otp
          if(resend === 0){
            formdata.activeStep = formdata.activeStep + 1;
          }

          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `OTP send the mobile number (${formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber})`
            }
          });
          dispatch({
            type: SURRENDER_OTP_SEND_SUCCESS,
            formdata: formdata,
            otpInfo:  response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_SURRENDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
      } else {
        dispatch({
          type: ERROR_MEESAGE_SURRENDER,
          error: {
            errorStatus: true,
            errorType: 'error',
            errorMessage: response.statusText
          }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_SURRENDER,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: SURRENDER_OTP_SEND_FAILURE,
        otpInfo: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().surrender.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_SURRENDER,
    error: errorObject
  });
}
