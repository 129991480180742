// import _ from 'lodash';

// Utility Action Network
export const NETWORK_STATUS = 'NETWORK_STATUS';
export const ONE_TIME = 'ONE_TIME';

export const networkStatus = (status) => dispatch => {
  dispatch({
    type: NETWORK_STATUS,
    status: status,
  });
}

export const oneTime = (count) => dispatch => {
  dispatch({
    type: ONE_TIME,
    oneTime: count,
  });
}
