import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// GET CARD LIST
export const CARD_LIST_UPI_LINK_REQUEST = 'CARD_LIST_UPI_LINK_REQUEST';
export const CARD_LIST_UPI_LINK_SUCCESS = 'CARD_LIST_UPI_LINK_SUCCESS';
export const CARD_LIST_UPI_LINK_FAILURE = 'CARD_LIST_UPI_LINK_FAILURE';

// SET VPA
export const SET_VPA_REQUEST = 'SET_VPA_REQUEST';
export const SET_VPA_SUCCESS = 'SET_VPA_SUCCESS';
export const SET_VPA_FAILURE = 'SET_VPA_FAILURE';

// SET VPA ENQUIRY
export const SET_VPA_ENQUIRY_REQUEST = 'SET_VPA_ENQUIRY_REQUEST';
export const SET_VPA_ENQUIRY_SUCCESS = 'SET_VPA_ENQUIRY_SUCCESS';
export const SET_VPA_ENQUIRY_FAILURE = 'SET_VPA_ENQUIRY_FAILURE';

// Form Data Change
export const UPI_LINK_FORM_DATA_CHANGE = 'UPI_LINK_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_UPI_LINK = 'BACK_HOME_UPI_LINK';

// Error Message
export const ERROR_MEESAGE_UPI_LINK = 'ERROR_MEESAGE_UPI_LINK';

// TIMER
export const TIMER_UPI_LINK = 'TIMER_UPI_LINK';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_UPI_LINK,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: UPI_LINK_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const SelectCardInfo = (cardId) => dispatch => {
  let formdata = store.getState().upiLink.formdata;
  formdata.cardId = cardId;
  formdata.selectCardInfo = _.find(store.getState().upiLink.cardList.cardList, {cardId: cardId});
  dispatch({
    type: UPI_LINK_FORM_DATA_CHANGE,
    formdata: formdata,
  });
}

export const timerSet = (timer) => dispatch => {
  dispatch({
    type: TIMER_UPI_LINK,
    timer: timer
  });
}

export const getCardList = () => dispatch => {
  let formdata = store.getState().upiLink.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: CARD_LIST_UPI_LINK_REQUEST
  });
  Instance
    .post('/citycash-bc/getCardListForOnlineRecharge/', {
      customerIdentifierType: formdata.customerIdentifierValue.toString().length === 10 ? 'phone_number' : 'card_id',
      customerIdentifierValue: formdata.customerIdentifierValue,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      rechargeType: 'tw_topup',
      amount: formdata.amount,
      requestId: requestId(),
      txnType: 'get_card_list_for_online_recharge',
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(response.data.cardList.length > 0){
            formdata.activeStep = formdata.activeStep + 1;
          } else {
            dispatch({
              type: ERROR_MEESAGE_UPI_LINK,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: formdata.customerIdentifierValue.length === 10 ? 'Invalid mobile number' : 'Invalid card number'
              }
            });
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_UPI_LINK,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CARD_LIST_UPI_LINK_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_UPI_LINK,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CARD_LIST_UPI_LINK_FAILURE,
        responseData: error
      });
    })
}

export const setVPA = (transactionEnquiry) => dispatch => {
  let formdata = store.getState().upiLink.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: transactionEnquiry ? SET_VPA_ENQUIRY_REQUEST : SET_VPA_REQUEST
  });

  let requestData = {
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    requestId: requestId(),
    txnType: 'SET_VPA',
    custIdentifier: {
      custCardId: formdata.selectCardInfo.trimaxId
    },
    amount: 1,
    hopType: user.hopType,
    upi_vpa: formdata.upi_vpa,
    InquiryRequestId: transactionEnquiry ? store.getState().upiLink.setvpa.requestId : ''
  };

  console.log(requestData);

  Instance
    .post('/citycash-bc/setVPA', requestData)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(!transactionEnquiry){
            formdata.activeStep = formdata.activeStep + 1;
          }
          if(transactionEnquiry) {
            if(response.data.ResponseData.responseCode !== 4640){
              clearInterval(store.getState().upiLink.timer);
            }
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_UPI_LINK,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: transactionEnquiry ? SET_VPA_ENQUIRY_SUCCESS : SET_VPA_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_UPI_LINK,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: transactionEnquiry ? SET_VPA_ENQUIRY_FAILURE : SET_VPA_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().upiLink.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_UPI_LINK,
    error: errorObject
  });
}
