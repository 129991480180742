// import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout, userUpdate } from 'actions';

import { writeStorage } from '@rehooks/local-storage';

// AGENT BALANCE AGENT
export const GET_AGENT_BALANCE_REQUEST = 'GET_AGENT_BALANCE_REQUEST';
export const GET_AGENT_BALANCE_SUCCESS = 'GET_AGENT_BALANCE_SUCCESS';
export const GET_AGENT_BALANCE_FAILURE = 'GET_AGENT_BALANCE_FAILURE';


// Back Navigation
export const BACK_HOME_DASHBOARD = 'BACK_HOME_DASHBOARD';

// Error Message
export const ERROR_MEESAGE_DASHBOARD = 'ERROR_MEESAGE_DASHBOARD';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_DASHBOARD,
  })
}

export const getAgentBalance = () => dispatch => {
  let user = store.getState().session.user;
  dispatch({
    type: GET_AGENT_BALANCE_REQUEST
  });
  Instance
    .post('/citycash-bc/getAgentBalance/', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      requestId: requestId(),
      txnType: 'get_agent_balance',
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          let user = JSON.parse(localStorage.getItem('user'));
          user.balance = response.data.agent_balance;
          user.remin_card = response.data.remaining_cards;
          writeStorage('user', user);
          dispatch(userUpdate(user));
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_DASHBOARD,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: GET_AGENT_BALANCE_SUCCESS,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error.message)
      if(error.message === 'Network Error'){
        dispatch(errorMessage({
          status: true,
          type: 'error',
          message: 'Internet connection lost'
        }));
      } else {
        dispatch({
          type: ERROR_MEESAGE_DASHBOARD,
          error: {
            errorStatus: true,
            errorType: 'error',
            errorMessage: error.message
          }
        });
        dispatch({
          type: GET_AGENT_BALANCE_FAILURE,
          responseData: error
        });
      }
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().dashboard.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_DASHBOARD,
    error: errorObject
  });
}

