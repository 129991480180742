import { createMuiTheme } from '@material-ui/core/styles';
const defaultTheme = createMuiTheme();

export default {
  gutters: {
    [defaultTheme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '14px',
    }
  }
};
