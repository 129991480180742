import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { RouteWithLayout, AuthGuard } from './components';
import { 
  AuthLayout, 
  ErrorLayout, 
  DashboardLayout, 
  SinglePageLayout 
} from './layouts';
// import { history } from './store/configureStore';

// import disableBrowserBackButton from 'disable-browser-back-navigation';

const Routes = () => {

  // disableBrowserBackButton();

  return (
    <Switch>

      <Redirect
        exact
        from="/"
        to="/dashboard"
      />

      <RouteWithLayout
        component={lazy(() => import('views/Error401'))}
        exact
        layout={ErrorLayout}
        path="/errors/error-401"
      />

      <RouteWithLayout
        component={lazy(() => import('views/Error404'))}
        exact
        layout={ErrorLayout}
        path="/errors/error-404"
      />
      
      {/* Protected Route */}
      <AuthGuard>

        <RouteWithLayout
          component={lazy(() => import('views/Login'))}
          exact
          layout={AuthLayout}
          path="/auth/login"
        />

        <RouteWithLayout
          component={lazy(() => import('views/ForgotPassword'))}
          exact
          layout={AuthLayout}
          path="/auth/forgot-password"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Dashboard'))}
          exact
          layout={DashboardLayout}
          path="/dashboard"
        />

        <RouteWithLayout
          component={lazy(() => import('views/TransactionHistory'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/transaction-history"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Block'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/block-card"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Surrender'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/surrender-card"
        />

        <RouteWithLayout
          component={lazy(() => import('views/OTCCardOrder'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/cardorder"
        />

        <RouteWithLayout
          component={lazy(() => import('views/AgentLoad'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/agentload"
        />
        
        <RouteWithLayout
          component={lazy(() => import('views/AddLoadTxnResponse'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/agentload/online"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Topup'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/topup/:type"
        />

        <RouteWithLayout
          component={lazy(() => import('views/BusPassRenewal'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/buspass"
        />

        <RouteWithLayout
          component={lazy(() => import('views/UPILink'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/upi-link"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Combo'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/combo"
        />

        <RouteWithLayout
          component={lazy(() => import('views/CardAcceptance'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/card-acceptance"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Calculator'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/calculator"
        />

        <RouteWithLayout
          component={lazy(() => import('views/PackageOTCRegistration'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/otc-package-registration"
        />

        <RouteWithLayout
          component={lazy(() => import('views/OTCRegistration'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/otc-registration"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Feedback'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/feedback"
        />

        <RouteWithLayout
          component={lazy(() => import('views/Support'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/contact-us"
        />

        <RouteWithLayout
            component={lazy(() => import('views/FAQs'))}
            exact
            layout={SinglePageLayout}
            path="/dashboard/faq"
          />

        <RouteWithLayout
          component={lazy(() => import('views/Contact'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/contact"
        />

        <RouteWithLayout
          component={lazy(() => import('views/TC'))}
          exact
          layout={SinglePageLayout}
          path="/dashboard/tnc/:id"
        />
      </AuthGuard>
      <Redirect from='*' to='/errors/error-404' />
    </Switch>
  )
}

export default Routes;
