import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
}));

const Buttons = props => {
  const { activeStep, handleBack, confirmText, disabled } = props;

  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      <Button className={classes.button}
        disabled={activeStep === 0 || disabled}
        onClick={handleBack}
      >
        Back
      </Button>
      <Button
        className={classes.button}
        color="primary"
        type='submit'
        variant="contained"
        disabled={disabled}
      >
        {confirmText}
      </Button>
    </div>
  );
};

Buttons.propTypes = {
  activeStep: PropTypes.number.isRequired,
  confirmText: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default Buttons;
