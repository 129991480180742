import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.busPass)

const busPassReducer = (state = Data.busPass, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_BUS_PASS: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_BUS_PASS_RENEWAL: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.BUS_PASS_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.CARD_LIST_BUS_PASS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CARD_LIST_BUS_PASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.CARD_LIST_BUS_PASS_FAILURE: {
      return {
        ...state,
        loading: false,
        cardList: action.responseData
      };
    }

    case actionTypes.BUS_PASS_STATUS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.BUS_PASS_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        busPassStatus: action.responseData
      };
    }

    case actionTypes.BUS_PASS_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        busPassStatus: action.responseData
      };
    }

    case actionTypes.CALCULATE_FARE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CALCULATE_FARE_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        fare: action.responseData
      };
    }

    case actionTypes.CALCULATE_FARE_FAILURE: {
      return {
        ...state,
        loading: false,
        fare: action.responseData
      };
    }

    case actionTypes.BUS_PASS_RENEWAL_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.BUS_PASS_RENEWAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        busPass: action.busPass,
        passRenewal: action.responseData
      };
    }

    case actionTypes.BUS_PASS_RENEWAL_FAILURE: {
      return {
        ...state,
        loading: false,
        passRenewal: action.responseData
      };
    }

    case actionTypes.BUS_PASS_TRANSACTION_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.BUS_PASS_TRANSACTION_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        passRenewalTransEnquiry: action.responseData
      };
    }

    case actionTypes.BUS_PASS_TRANSACTION_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        passRenewalTransEnquiry: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_BUS_PASS: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.RESET_CHARGES: {
      return {
        ...state,
        fare: {}
      }
    }

    default: {
      return state;
    }
  }
};

export default busPassReducer;
