import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.agentLoad)

const addBalanceReducer = (state = Data.agentLoad, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_ADD_BALANCE: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_ADD_BALANCE: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.ADD_BALANCE_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.ERROR_MEESAGE_ADD_BALANCE: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.ADD_BALANCE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case actionTypes.ADD_BALANCE_SUCCESS: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        addBalance: action.responseData,
        enquiry: action.enquiry
      }
    }

    case actionTypes.ADD_BALANCE_FAILURE: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        addBalance: action.responseData
      }
    }

    case actionTypes.LOAD_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case actionTypes.LOAD_ENQUIRY_SUCCESS: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        transactionEnquiry: action.transactionEnquiry
      }
    }

    case actionTypes.LOAD_ENQUIRY_FAILURE: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        transactionEnquiry: action.transactionEnquiry
      }
    }

    default: {
      return state;
    }
  }
};

export default addBalanceReducer;
