import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import sessionReducer from './sessionReducer';
import otcRegistrationReducer from './otcRegistrationReducer';
import topUpReducer from './topUpReducer';
import busPassReducer from './busPassReducer';
import otcCardOrderReducer from './otcCardOrderReducer';
import upiLinkReducer from './upiLinkReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import addBalanceReducer from './addBalanceReducer';
import transactionsReducer from './transactionsReducer';
import calculatorReducer from './calculatorReducer';
import supportReducer from './supportReducer';
import dashboardReducer from './dashboardReducer';
import packageOTCRegistrationReducer from './packageOTCRegistrationReducer';
import cardAcceptance from './cardAcceptanceReducer';
import combo from './comboReducer';
import UtilityReducer from './utilityReducer';
import blockReducer from './blockReducer';
import surrenderReducer from './surrenderReducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  session: sessionReducer,
  otcRegistration: otcRegistrationReducer,
  topup: topUpReducer,
  busPass: busPassReducer,
  otcCardOrder: otcCardOrderReducer,
  upiLink: upiLinkReducer,
  forgotPassword: forgotPasswordReducer,
  addBalance: addBalanceReducer,
  transactions: transactionsReducer,
  calculator: calculatorReducer,
  support: supportReducer,
  dashboard: dashboardReducer,
  packageOTCRegistration: packageOTCRegistrationReducer,
  cardAcceptance: cardAcceptance,
  combo: combo,
  utility: UtilityReducer,
  block: blockReducer,
  surrender: surrenderReducer
});

export default rootReducer;
