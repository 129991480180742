import axios from 'axios';

export const token = process.env.REACT_APP_TOKEN;
export const Instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `token ${token}`
  },
  timeout: 180000, // set 180 sec
  // timeout: 10000, // default 10 sec
})
