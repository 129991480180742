import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.combo)

const comboReducer = (state = Data.combo, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_COMBO: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_COMBOUP: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }
    case actionTypes.COMBO_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.CHOOSE_PACKAGE_COMBO_TYPE:
      state.formdata.packageType = action.packageType
      return {
        ...state
      }

    case actionTypes.GET_PACKAGE_LIST_COMBO_REQUEST: {
      return {
        ...state,
        loading: false,
      }
    }

    case actionTypes.GET_PACKAGE_LIST_COMBO_SUCCESS: {
      return {
        ...state,
        loading: false,
        packageList: action.packageList
      }
    }

    case actionTypes.GET_PACKAGE_LIST_COMBO_FAILURE: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.CARD_LIST_COMBO_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CARD_LIST_COMBO_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.CARD_LIST_COMBO_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_COMBO_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_CHARGES_COMBO_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        getCharges: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_COMBO_FAILURE: {
      return {
        ...state,
        loading: false,
        getCharges: action.responseData
      };
    }

    case actionTypes.TOPUP_COMBO_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.TOPUP_COMBO_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topupdata: action.topupdata,
        topup: action.responseData,
        transactionEnquiry: action.responseData
      };
    }
    

    case actionTypes.TOPUP_COMBO_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topup: action.responseData
      };
    }

    case actionTypes.COMBO_OTP_SEND_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.COMBO_OTP_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        otpInfo: action.otpInfo,
      };
    }
    

    case actionTypes.COMBO_OTP_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        otpInfo: action.responseData
      };
    }


    case actionTypes.COMBO_LIMIT_CHECK_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.COMBO_LIMIT_CHECK_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        checkLimit: action.responseData,
      };
    }
    

    case actionTypes.COMBO_LIMIT_CHECK_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        checkLimit: action.responseData
      };
    }

    case actionTypes.GET_TRANSACTION_ENQUIRY_COMBO_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_TRANSACTION_ENQUIRY_COMBO_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topup: action.transactionEnquiry,
        transactionEnquiry: action.transactionEnquiry
      };
    }
    

    case actionTypes.GET_TRANSACTION_ENQUIRY_COMBO_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata
      };
    }

    case actionTypes.TOPUP_SW_COMBO_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.TOPUP_SW_COMBO_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topupdataSW: action.topupdataSW,
        topupSW: action.responseData
      };
    }

    case actionTypes.TOPUP_SW_COMBO_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topupSW: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_COMBO: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.DIALOG_COMBO_TOPUP: {
      return {
        ...state,
        dialog: action.dialog
      }
    }

    default: {
      return state;
    }
  }
};

export default comboReducer;
