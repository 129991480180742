import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.support)

const supportReducer = (state = Data.support, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_SUPPORT: {
      copyInitialValue.formdata.activeStep = 0;
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.SUPPORT_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.ERROR_MEESAGE_SUPPORT: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.SEND_FEEDBACK_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case actionTypes.SEND_FEEDBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        feedback: action.responseData
      }
    }

    case actionTypes.SEND_FEEDBACK_FAILURE: {
      return {
        ...state,
        loading: false,
        feedback: action.responseData
      }
    }

    default: {
      return state;
    }
  }
};

export default supportReducer;
