// import _ from 'lodash';
// import moment from 'moment';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';
import { push } from 'connected-react-router';

// Data
// import * as Data from 'data';

// Form Data Change
export const CARD_ACCEPTANCE_FORM_DATA_CHANGE = 'CARD_ACCEPTANCE_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_CARD_ACCEPTANCE = 'BACK_HOME_CARD_ACCEPTANCE';

// CARD ACCEPTANCE
export const CARD_ACCEPTANCE_REQUEST = 'CARD_ACCEPTANCE_REQUEST';
export const CARD_ACCEPTANCE_SUCCESS = 'CARD_ACCEPTANCE_SUCCESS';
export const CARD_ACCEPTANCE_FAILURE = 'CARD_ACCEPTANCE_FAILURE';

// ACCEPT CARD ORDER
export const ACCEPT_CARD_ORDER_REQUEST = 'ACCEPT_CARD_ORDER_REQUEST';
export const ACCEPT_CARD_ORDER_SUCCESS = 'ACCEPT_CARD_ORDER_SUCCESS';
export const ACCEPT_CARD_ORDER_FAILURE = 'ACCEPT_CARD_ORDER_FAILURE';

// Reset Transaction Data
export const RESET_CARD_ACCEPTANCE_DATA = 'RESET_CARD_ACCEPTANCE_DATA';

// Error Message
export const ERROR_MEESAGE_CARD_ACCEPTANCE = 'ERROR_MEESAGE_CARD_ACCEPTANCE';

export const resetData = () => dispatch => {
  dispatch({
    type: RESET_CARD_ACCEPTANCE_DATA,
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_CARD_ACCEPTANCE,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: CARD_ACCEPTANCE_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const getAgentAssignedOrders = () => dispatch => {
  let user = store.getState().session.user;
  dispatch({
    type: CARD_ACCEPTANCE_REQUEST
  });
  Instance
    .post('/citycash-bc/getAgentAssignedOrders/', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      txnType: 'get_agent_assigned_card_orders',
      requestId: requestId(),
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          dispatch({
            type: CARD_ACCEPTANCE_SUCCESS,
            responseData: response.data,
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_CARD_ACCEPTANCE,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
    })
    .catch(error => {
      console.error(error)
      dispatch({
        type: ERROR_MEESAGE_CARD_ACCEPTANCE,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CARD_ACCEPTANCE_FAILURE,
        responseData: error
      });
    })
}

export const acceptCardOrder = (ref) => dispatch => {
  let user = store.getState().session.user;
  dispatch({
    type: ACCEPT_CARD_ORDER_REQUEST
  });
  Instance
    .post('/citycash-bc/acceptCardOrder/', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      txnType: 'accept_card_order',
      requestId: requestId(),
      token: user.tokenInfo.token,
      agentId: user.agentId,
      order_ref: ref
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          // console.log('>>>>', response.data);
          dispatch({
            type: ERROR_MEESAGE_CARD_ACCEPTANCE,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
          setTimeout(function() {
            dispatch(push('/dashboard'))
          }, 1000);
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_CARD_ACCEPTANCE,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: ACCEPT_CARD_ORDER_SUCCESS,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.error(error)
      dispatch({
        type: ERROR_MEESAGE_CARD_ACCEPTANCE,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: ACCEPT_CARD_ORDER_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().cardAcceptance.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_CARD_ACCEPTANCE,
    error: errorObject
  });
}
