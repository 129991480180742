// import _ from 'lodash';
// import { Instance } from '../utils/axiosConfig';
// import requestId from '../utils/requestId';
import { configureStore as store } from './../store';

// Form Data Change
export const CALCULATOR_FORM_DATA_CHANGE = 'CALCULATOR_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_CALCULATOR = 'BACK_HOME_CALCULATOR';

// Error Message
export const ERROR_MEESAGE_CALCULATOR = 'ERROR_MEESAGE_CALCULATOR';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_CALCULATOR,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: CALCULATOR_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().calculator.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_CALCULATOR,
    error: errorObject
  });
}
