import _ from 'lodash';
import moment from 'moment';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// Data
import * as Data from 'data';

// TXN STATEMENT
export const TXN_STATEMENT_REQUEST = 'TXN_STATEMENT_REQUEST';
export const TXN_STATEMENT_SUCCESS = 'TXN_STATEMENT_SUCCESS';
export const TXN_STATEMENT_FAILURE = 'TXN_STATEMENT_FAILURE';

// MER TXN SUMMURY
export const MER_TXN_SUMMURY_REQUEST = 'MER_TXN_SUMMURY_REQUEST';
export const MER_TXN_SUMMURY_SUCCESS = 'MER_TXN_SUMMURY_SUCCESS';
export const MER_TXN_SUMMURY_FAILURE = 'MER_TXN_SUMMURY_FAILURE';

// Form Data Change
export const TRANSACTION_FORM_DATA_CHANGE = 'TRANSACTION_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_TRANSACTION = 'BACK_HOME_TRANSACTION';

// Reset Transaction Data
export const RESET_TRANSACTION_DATA = 'RESET_TRANSACTION_DATA';

// Error Message
export const ERROR_MEESAGE_TRANSACTION = 'ERROR_MEESAGE_TRANSACTION';

export const resetData = () => dispatch => {
  dispatch({
    type: RESET_TRANSACTION_DATA,
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_TRANSACTION,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: TRANSACTION_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const txnStatement = () => dispatch => {
  let formdata = store.getState().transactions.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: TXN_STATEMENT_REQUEST
  });
  Instance
    .post('/citycash-bc/getMerchantTxnSummary', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      requestId: requestId(),
      txnStatus: formdata.txnStatusSummary,
      fromDate: moment(formdata.startDateTxnSummary).format('YYYY-MM-DD'),
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          console.log('>>>>', response.data);
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_TRANSACTION,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: TXN_STATEMENT_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.error(error)
      dispatch({
        type: ERROR_MEESAGE_TRANSACTION,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: TXN_STATEMENT_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const merTxnStatement = () => dispatch => {
  let getData = store.getState().transactions;
  let formdata = getData.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: MER_TXN_SUMMURY_REQUEST
  });

  Instance
    .post('/citycash-bc/MerchantTxnStatement', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      txnType:  _.find(Data.Historytabs, { id: formdata.txnType}).txnType,
      requestId: requestId(),
      txnStatus: formdata.txnStatusHistory,
      fromDate: moment(formdata.startDateTxnHistory).format('YYYY-MM-DD'),
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      pages: formdata.pages,
      offset: formdata.offset,
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200 || response.data.responseCode === 470){
          if(_.has(getData, 'mer_summury.txnSummary')) {
            response.data.txnSummary = [...getData.mer_summury.txnSummary, ...response.data.txnSummary];
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_TRANSACTION,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: MER_TXN_SUMMURY_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.error(error)
      dispatch({
        type: ERROR_MEESAGE_TRANSACTION,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: MER_TXN_SUMMURY_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().transactions.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_TRANSACTION,
    error: errorObject
  });
}
