import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.otcRegistration)

const otcRegistrationReducer = (state = Data.otcRegistration, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_OTC_CARD_REGISTRATION: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_OTC_REG: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.OTC_REGISTRATION_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }
    case actionTypes.CUSTOMER_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.CUSTOMER_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerSearch: action.customersearch,
        formdata: action.formdata
      }
    }
    case actionTypes.CUSTOMER_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        customerSearch: action.customersearch
      }
    }
    case actionTypes.OTC_CARD_CHARGES_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.OTC_CARD_CHARGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        charges: action.charges,
        formdata: action.formdata
      }
    }
    case actionTypes.OTC_CARD_CHARGES_FAILURE: {
      return {
        ...state,
        loading: false,
        charges: action.charges
      }
    }
    case actionTypes.OTP_SEND_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.OTP_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        authInfo: action.authInfo,
        formdata: action.formdata
      }
    }
    case actionTypes.OTP_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        authInfo: action.authInfo
      }
    }
    case actionTypes.WALLET_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        walletCreation: action.walletCreation
      }
    }
    case actionTypes.WALLET_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        walletCreation: action.walletCreation,
        formdata: action.formdata,
        requestData: action.requestData
      }
    }
    case actionTypes.WALLET_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
        walletCreation: action.walletCreation
      }
    }
    case actionTypes.GET_TID_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.GET_TID_SUCCESS: {
      return {
        ...state,
        loading: false,
        TID: action.responseData
      }
    }
    case actionTypes.GET_TID_FAILURE: {
      return {
        ...state,
        loading: false,
        TID: action.responseData
      }
    }
    case actionTypes.CHANGE_UNIQUE_ID: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }
    case actionTypes.CHANGE_UNIQUE_FORM: {
      return {
        ...state,
        uniqueIdForm: action.uniqueIdForm,
      }
    }
    case actionTypes.WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        walletCreationTransactionInquiry: action.walletCreationTransactionInquiry
      }
    }
    case actionTypes.WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        walletCreationTransactionInquiry: action.walletCreationTransactionInquiry
      }
    }
    case actionTypes.WALLET_CREATE_REPOSTING_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.WALLET_CREATE_REPOSTING_SUCCESS: {
      return {
        ...state,
        loading: false,
        walletCreationReposting: action.walletCreationReposting
      }
    }
    case actionTypes.WALLET_CREATE_REPOSTING_FAILURE: {
      return {
        ...state,
        loading: false,
        walletCreationReposting: action.walletCreationReposting
      }
    }
    case actionTypes.VPA_LINKING_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.VPA_LINKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        setvpa: action.responseData
      };
    }

    case actionTypes.VPA_LINKING_FAILURE: {
      return {
        ...state,
        loading: false,
        setvpa: action.responseData
      };
    }

    case actionTypes.VPA_LINKING_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: false
      };
    }

    case actionTypes.VPA_LINKING_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        setvpaenquery: action.responseData
      };
    }

    case actionTypes.VPA_LINKING_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        setvpaenquery: action.responseData
      };
    }
    case actionTypes.ERROR_MEESAGE_OTC_REG: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.TIMER_OTC_REG: {
      return {
        ...state,
        timer: action.timer
      }
    }

    default: {
      return state;
    }
  }
};

export default otcRegistrationReducer;
