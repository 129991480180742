import React from 'react';
// import _ from "lodash";
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { push } from 'connected-react-router';
import { backHome as backHomeOTCCardRegistration} from './../../actions/otcRegistrationActions';
import { backHome as backHomeTopup } from './../../actions/topUpActions';
import { backHome as backHomeBusPass } from './../../actions/busPassActions';
import { backHome as backHomeOTCCardOrder } from './../../actions/otcCardOrderActions';
import { backHome as backHomeAgentLoad } from './../../actions/addBalanceActions';
import { backHome as backHomeTransactionHistory, formDataChange } from './../../actions/transactionsActions';

import { backHome as backHomeCardAccept } from './../../actions/cardAcceptanceActions';

import { backHome as backHomeUpiLink } from './../../actions/upiLinkActions';

import { backHome as backHomeCombo } from './../../actions/comboActions';

import { backHome as backHomeBlock } from './../../actions/blockActions';

import { backHome as backHomeSurrender } from './../../actions/surrenderActions';

import Poll from '@material-ui/icons/Poll';

import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Button, 
  Hidden,
  LinearProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  toolbarText: {
    color: '#fff',
  },
  homeLink: {
    color: '#fff',
    marginLeft: theme.spacing(1)
  },
  summaryButton: {
    marginLeft: theme.spacing(1)
  },
  summaryIcon: {
    marginRight: theme.spacing(1)
  },
}));

const SingleToolbar = props => {
  const { homeStatus, title, backMessage, backTitle, stateReset, backhide, transactionSummary, loading, className, ...rest } = props;
  const dispatch = useDispatch()
  const getState = useSelector(state => state.transactions);
  const formdataTransactionHistory = getState.formdata;
  const transactionsData = getState.formdata;
  const confirm = useConfirm();

  const handleClickOpen = () => {
    if(['transactions', 'tnc', 'faq', 'contact', 'agentload'].includes(stateReset)){
      if(formdataTransactionHistory.title === 'Transaction Summary') {
        formdataTransactionHistory.title = 'Transaction History';
        formdataTransactionHistory.activeStep = 0;
        dispatch(formDataChange(formdataTransactionHistory));
      } else {
        dispatch(backHomeTransactionHistory());
        dispatch(push('/dashboard'));
      }
    } else {
      confirm({ description: `${backMessage}`, title: backTitle })
        .then(() => handleClose())
        .catch(() => console.log('Deletion cancelled.'));
    }
  };

  const handleClose = () => {
    if(stateReset === 'otcReg'){
      dispatch(backHomeOTCCardRegistration());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'topup') {
      dispatch(backHomeTopup());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'buspass') {
      dispatch(backHomeBusPass());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'otccardorder') {
      dispatch(backHomeOTCCardOrder());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'agentload') {
      dispatch(backHomeAgentLoad());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'combo') {
      dispatch(backHomeCombo());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'upi-link') {
      dispatch(backHomeUpiLink());
      dispatch(push('/dashboard'));
    } else if(stateReset === 'cardAccept') {
      dispatch(backHomeCardAccept());
      dispatch(push('/dashboard'));
    }else if(stateReset === 'block') {
      dispatch(backHomeBlock());
      dispatch(push('/dashboard'));
    }else if(stateReset === 'surrender') {
      dispatch(backHomeSurrender());
      dispatch(push('/dashboard'));
    } else {
      dispatch(push('/dashboard'));
    }
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        color="primary"
      >
        { loading && ( <LinearProgress />) }
        <Toolbar>
          {
            backhide ? <IconButton
              color="inherit"
            /> : <IconButton
              color="inherit"
              onClick={handleClickOpen}
            >
              <ArrowBackIcon />
            </IconButton>
          }
          <Typography className={classes.toolbarText} variant="button" display="block">
            { title }
          </Typography>
          <div className={classes.flexGrow} />
          {
            homeStatus ? <RouterLink to="/">
              <Typography className={[classes.toolbarText, classes.homeLink]} variant="button" display="block">
                Home
              </Typography>
            </RouterLink> : ''
          }
          {
            transactionSummary ? 
              transactionsData.title === 'Transaction History' ? <Hidden mdDown>
                <Button
                  className={classes.summaryButton}
                  color="inherit"
                  onClick={() => {
                    transactionsData.activeStep += 1;
                    transactionsData.title = 'Transaction Summary';
                    dispatch(formDataChange(transactionsData))}}
                >
                  <Poll className={classes.summaryIcon} />
              Transaction Summary
                </Button>
              </Hidden> : '' : ''
          }
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

SingleToolbar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  homeStatus: PropTypes.bool,
  backMessage: PropTypes.string,
  backTitle: PropTypes.string,
  stateReset: PropTypes.string.isRequired
};

export default SingleToolbar;
