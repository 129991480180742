export const Historytabs = [
  {
    id: 0,
    label: 'All',
    txnType: 'all_txn'
  },
  {
    id: 1,
    label: 'OTC Card Order',
    txnType: 'confirm_card_order'
  },
  {
    id: 2,
    label: 'ST Bus Wallet Topup',
    txnType: 'tw_topup'
  },
  {
    id: 3,
    label: 'Retail Wallet Topup',
    txnType: 'sw_topup'
  },
  {
    id: 4,
    label: 'Bus Pass Renewal',
    txnType: 'bp_bc_debit_cash'
  },
  {
    id: 5,
    label: 'Issuance',
    txnType: 'issuance'
  },
  {
    id: 6,
    label: 'TW Claim',
    txnType: 'tw_claim'
  },
  {
    id: 7,
    label: 'SW Claim',
    txnType: 'sw_claim'
  },
  {
    id: 8,
    label: 'UPI Linked',
    txnType: 'set_vpa'
  },
  {
    id: 9,
    label: 'Agent Balance',
    txnType: 'agent_bal_load'
  },
  {
    id: 10,
    label: 'Registration',
    txnType: 'wallet_creation'
  },
  {
    id: 11,
    label: 'Block Card',
    txnType: 'report_loss'
  },
  {
    id: 12,
    label: 'Surrender Card',
    txnType: 'surrender'
  },
];
