import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// GET CARD ORDER
export const GET_CARD_ORDER_REQUEST = 'GET_CARD_ORDER_REQUEST';
export const GET_CARD_ORDER_SUCCESS = 'GET_CARD_ORDER_SUCCESS';
export const GET_CARD_ORDER_FAILURE = 'GET_CARD_ORDER_FAILURE';

// CONFIRN CARD ORDER
export const CONFIRM_CARD_ORDER_REQUEST = 'CONFIRM_CARD_ORDER_REQUEST';
export const CONFIRM_CARD_ORDER_SUCCESS = 'CONFIRM_CARD_ORDER_SUCCESS';
export const CONFIRM_CARD_ORDER_FAILURE = 'CONFIRM_CARD_ORDER_FAILURE';

// CARD ORDER ENQUIRY
export const CARD_ORDER_ENQUIRY_REQUEST = 'CARD_ORDER_ENQUIRY_REQUEST';
export const CARD_ORDER_ENQUIRY_SUCCESS = 'CARD_ORDER_ENQUIRY_SUCCESS';
export const CARD_ORDER_ENQUIRY_FAILURE = 'CARD_ORDER_ENQUIRY_FAILURE';

// Form Data Change
export const CARD_ORDER_FORM_DATA_CHANGE = 'CARD_ORDER_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_CARD_ORDER = 'BACK_HOME_CARD_ORDER';

// Error Message
export const ERROR_MEESAGE_OTC_CARD_ORDER = 'ERROR_MEESAGE_OTC_CARD_ORDER';

// Back Action
export const BACK_ERROR_CARD_ORDER = 'BACK_ERROR_CARD_ORDER';

export const backAction = (status) => dispatch => {
  let formdata = store.getState().otcCardOrder.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_CARD_ORDER,
    formdata: formdata
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_CARD_ORDER,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: CARD_ORDER_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const getCardOrder = () => dispatch => {
  let formdata = store.getState().otcCardOrder.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: GET_CARD_ORDER_REQUEST
  });
  Instance
    .post('/citycash-bc/createCardOrder/', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      txnType: 'create_card_order',
      totalCards: formdata.totalCards,
      cardType: 'OTC',
      requestId: requestId()
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep = formdata.activeStep + 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_CARD_ORDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: GET_CARD_ORDER_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_OTC_CARD_ORDER,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_CARD_ORDER_FAILURE,
        responseData: error
      });
    })
}

export const otcCardConfirm = () => dispatch => {
  let formdata = store.getState().otcCardOrder.formdata;
  let agentCountData = store.getState().otcCardOrder.noOfCard.chargeDetails;
  let user = store.getState().session.user;
  dispatch({
    type: CONFIRM_CARD_ORDER_REQUEST
  });

  let requestData = {
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'confirm_card_order',
    requestId: requestId(),
    token: user.tokenInfo.token,
    agentId: user.agentId,
    cardType: 'OTC',
    chargeDetails: {
      totalCards: agentCountData.totalCards,
      perCardAmount: agentCountData.perCardAmount,
      totalAmount: agentCountData.totalAmount
    }
  }

  Instance
    .post('/citycash-bc/confirmCardOrder/', requestData)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.backhide = true;
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_CARD_ORDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CONFIRM_CARD_ORDER_SUCCESS,
        formdata: formdata,
        responseData: response.data,
        enquiry: requestData,
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        allSteps.add('Number_Of_Card');
        allSteps.add('Charges_Form');
        allSteps.add('Enquiry');
        allSteps.add('Enquiry_Success');

        formdata.steps = [...allSteps];
        formdata.backhide = true;
        formdata.activeStep += 1;
        dispatch({
          type: CONFIRM_CARD_ORDER_SUCCESS,
          formdata: formdata,
          enquiry: requestData,
          responseData: error,  
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          allSteps.add('Number_Of_Card');
          allSteps.add('Charges_Form');
          allSteps.add('Enquiry');
          allSteps.add('Enquiry_Success');
          
          formdata.steps = [...allSteps];
          formdata.backhide = true;
          formdata.activeStep += 1;
          dispatch({
            type: CONFIRM_CARD_ORDER_SUCCESS,
            formdata: formdata,
            enquiry: requestData,
            responseData: error,  
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_CARD_ORDER,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: CONFIRM_CARD_ORDER_FAILURE,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiry = () => dispatch => {
  let formdata = store.getState().otcCardOrder.formdata;
  let user = store.getState().session.user;

  dispatch({
    type: CARD_ORDER_ENQUIRY_REQUEST
  });

  let Enquiry = {
    requestId: requestId(),
    txnType: 'confirm_card_order_enquiry',
    token: user.tokenInfo.token,
    agentId: user.agentId,
    enquiryRequestId: store.getState().otcCardOrder.enquiry.requestId,
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType
  };

  Instance
    .post('/citycash-bc/confirmCardOrderEnquiry/', Enquiry)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          dispatch({
            type: ERROR_MEESAGE_OTC_CARD_ORDER,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          formdata.backhide = true;
          formdata.activeStep += 1;
          dispatch({
            type: CARD_ORDER_ENQUIRY_SUCCESS,
            formdata: formdata,
            transactionEnquiry: response.data,
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_OTC_CARD_ORDER,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CARD_ORDER_ENQUIRY_FAILURE,
        formdata: formdata,
        transactionEnquiry: error
      });
    })
}


export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().otcCardOrder.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_OTC_CARD_ORDER,
    error: errorObject
  });
}
