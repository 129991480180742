import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.topup)

const topUpReducer = (state = Data.topup, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_TOPUP: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_TOPUP: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.CHANGE_AMOUNT_TEXTFIELD: {
      return {
        ...state,
        formdata: Object.assign( state.formdata, { amount: action.amount })
      }
    }

    case actionTypes.TOP_UP_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.CARD_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CARD_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.CARD_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_TOPUP_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_CHARGES_TOPUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        getCharges: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_TOPUP_FAILURE: {
      return {
        ...state,
        loading: false,
        getCharges: action.responseData
      };
    }

    case actionTypes.TOPUP_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.TOPUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topupdata: action.topupdata,
        topup: action.responseData
      };
    }

    case actionTypes.TOPUP_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        topup: action.responseData
      };
    }

    case actionTypes.GET_TRANSACTION_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_TRANSACTION_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        transactionEnquiry: action.transactionEnquiry,
        topup: action.transactionEnquiry
      };
    }

    case actionTypes.GET_TRANSACTION_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        transactionEnquiry: action.transactionEnquiry
      };
    }

    case actionTypes.ERROR_MEESAGE_TOPUP: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.OTP_SEND_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.OTP_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        otpInfo: action.otpInfo,
        formdata: action.formdata
      }
    }
    case actionTypes.OTP_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        otpInfo: action.otpInfo
      }
    }
    case actionTypes.DIALOG_TOPUP: {
      return {
        ...state,
        dialog: action.dialog
      }
    }

    default: {
      return state;
    }
  }
};

export default topUpReducer;
