import PhoneIcon from '@material-ui/icons/Phone';
import MessageIcon from '@material-ui/icons/Message';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PanToolIcon from '@material-ui/icons/PanTool';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default [
  {
    title: 'Support',
    pages: [
      {
        title: 'Terms & Conditions',
        href: '/dashboard/tnc/1',
        icon: PanToolIcon
      },
      {
        title: 'FAQs',
        href: '/dashboard/faq',
        icon: MessageIcon
      },
      {
        title: 'Complaints & Feedback',
        href: '/dashboard/feedback',
        icon: FeedbackIcon
      },
      {
        title: 'Contact Us',
        href: '/dashboard/contact',
        icon: PhoneIcon
      },
      {
        title: 'Logout',
        href: '/logout',
        icon: ExitToAppIcon
      }
    ]
  }
];
