import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// GET CARD LIST
export const CARD_LIST_REQUEST = 'CARD_LIST_REQUEST';
export const CARD_LIST_SUCCESS = 'CARD_LIST_SUCCESS';
export const CARD_LIST_FAILURE = 'CARD_LIST_FAILURE';

// GET CHARGES TOPUP
export const GET_CHARGES_TOPUP_REQUEST = 'GET_CHARGES_TOPUP_REQUEST';
export const GET_CHARGES_TOPUP_SUCCESS = 'GET_CHARGES_TOPUP_SUCCESS';
export const GET_CHARGES_TOPUP_FAILURE = 'GET_CHARGES_TOPUP_FAILURE';

// TOPUP
export const TOPUP_REQUEST = 'TOPUP_REQUEST';
export const TOPUP_SUCCESS = 'TOPUP_SUCCESS';
export const TOPUP_FAILURE = 'TOPUP_FAILURE';

// GET TRANSACTION ENQUIRY
export const GET_TRANSACTION_ENQUIRY_REQUEST = 'GET_TRANSACTION_ENQUIRY_REQUEST';
export const GET_TRANSACTION_ENQUIRY_SUCCESS = 'GET_TRANSACTION_ENQUIRY_SUCCESS';
export const GET_TRANSACTION_ENQUIRY_FAILURE = 'GET_TRANSACTION_ENQUIRY_FAILURE';

// Otp Send
export const OTP_SEND_REQUEST = 'OTP_SEND_REQUEST';
export const OTP_SEND_SUCCESS = 'OTP_SEND_SUCCESS';
export const OTP_SEND_FAILURE = 'OTP_SEND_FAILURE';

// CHANGE AMOUNT TEXTFIELD
export const CHANGE_AMOUNT_TEXTFIELD = 'CHANGE_AMOUNT_TEXTFIELD';

// DIALOG TOPUP
export const DIALOG_TOPUP = 'DIALOG_TOPUP';

// Form Data Change
export const TOP_UP_FORM_DATA_CHANGE = 'TOP_UP_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_TOPUP = 'BACK_HOME_TOPUP';

// Error Message
export const ERROR_MEESAGE_TOPUP = 'ERROR_MEESAGE_TOPUP';

// Back Action
export const BACK_ERROR_TOPUP = 'BACK_ERROR_TOPUP';

export const backAction = (status) => dispatch => {
  let formdata = store.getState().topup.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_TOPUP,
    formdata: formdata
  })
}

export const dialogShow = (dialog) => dispatch => {
  dispatch({
    type: DIALOG_TOPUP,
    dialog: dialog
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_TOPUP,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: TOP_UP_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const SelectCardInfo = (cardId) => dispatch => {
  let formdata = store.getState().topup.formdata;
  formdata.cardId = cardId;
  formdata.selectCardInfo = _.find(store.getState().topup.cardList.cardList, {cardId: cardId});
  dispatch({
    type: TOP_UP_FORM_DATA_CHANGE,
    formdata: formdata,
  });
}

export const getCardList = (type) => dispatch => {
  let formdata = store.getState().topup.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: CARD_LIST_REQUEST
  });

  Instance
    .post('/citycash-bc/getCardListForOnlineRecharge/', {
      customerIdentifierType: formdata.customerIdentifierValue.toString().length === 10 ? 'phone_number' : 'card_id',
      customerIdentifierValue: formdata.customerIdentifierValue.toString(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      rechargeType: type  === 'tw' ? 'tw_topup' : 'sw_topup',
      amount: formdata.amount,
      requestId: requestId(),
      txnType: 'get_card_list_for_online_recharge',
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(response.data.cardList.length > 0){
            formdata.activeStep = formdata.activeStep + 1;
            if(type === 'sw'){
              let cardList = response.data.cardList.filter(card =>card.cardStatus === 'ISD');
              formdata.cardId = cardList.length === 1 ? cardList[0].cardId : '';
              if(formdata.cardId !== ''){
                formdata.selectCardInfo = _.find(response.data.cardList, {cardId: formdata.cardId});
              }
            }
          } else {
            dispatch({
              type: ERROR_MEESAGE_TOPUP,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: formdata.customerIdentifierValue.length === 10 ? 'Invalid mobile number' : 'Invalid card number'
              }
            });
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CARD_LIST_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_TOPUP,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CARD_LIST_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const getCharges = (type) => dispatch => {
  let formdata = store.getState().topup.formdata;
  let dialog = store.getState().topup.dialog;
  let user = store.getState().session.user;

  dispatch({
    type: GET_CHARGES_TOPUP_REQUEST
  });

  Instance
    .post('/citycash-bc/getOnlineRechargeTxnCharges/', {
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      amountType: 2,
      txnType: type === 'tw' ? 'tw_direct_load' : 'get_transaction_charges',
      amount: formdata.amount,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if(response.data.responseCode === 4554) {
          dialog.open = true;
          dialog.title = `Exceeds ${_.toUpper(type)} Topup Amount`;
          dialog.message = response.data.responseMessage;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: GET_CHARGES_TOPUP_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_TOPUP,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_CHARGES_TOPUP_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const topup = (type) => dispatch => {
  let formdata = store.getState().topup.formdata;
  let getCharges = store.getState().topup.getCharges;
  let user = store.getState().session.user;

  dispatch({
    type: TOPUP_REQUEST
  });

  let topupdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: type === 'tw' ? 'tw_topup' : 'sw_topup',
    paymentMode: formdata.paymentMode,
    amountType: 2,
    amount: getCharges.amount,
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.cardId
    },
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    loginId: user.loginId,
    LoadingFee: getCharges.ChargeList.LoadingFee,
    GST: getCharges.ChargeList.GST,
    totalChargeableAmount: getCharges.totalChargeableAmount,
    CardFee: getCharges.ChargeList.CardFee,
    BCCharges: getCharges.ChargeList.BCCharges,
    TotalCharge: getCharges.ChargeList.TotalCharge,
    EffectiveAmount: getCharges.EffectiveAmount,
    ePurseFees: getCharges.ChargeList.ePurseFees,
    discount: getCharges.ChargeList.discount,
    cashbackTw: getCharges.ChargeList.cashbackTw,
    cashbackSw: getCharges.ChargeList.cashbackSw
  };

  if(type === 'tw') {
    topupdata['BonusAmt'] = getCharges.ChargeList.BonusAmt;
  } else {
    topupdata['auth'] = {
      otp: formdata.otp,
      referenceId: store.getState().topup.otpInfo.referenceId
    }
  }

  let url = `/citycash-bc/${type === 'tw' ? 'TWTopUp' : 'SWTopUp'}/`;
  
  Instance
    .post(url, topupdata)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
          formdata.backhide = true;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Show_Charges' && type === 'tw'){
              allSteps.add('Show_Charges');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else if(step === 'Otp_Verification' && type === 'sw'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              if(step === 'Topup_Combo_Success') {
                console.log('>>>> Remove')
              } else {
                allSteps.add(step);
              }
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Topup_Success');
        } else if(response.data.responseCode === 3000) {
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: TOPUP_SUCCESS,
        formdata: formdata,
        topupdata: topupdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Show_Charges' && type === 'tw'){
            allSteps.add('Show_Charges');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else if(step === 'Otp_Verification' && type === 'sw'){
            allSteps.add('Otp_Verification');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            if(step === 'Topup_Combo_Success') {
              console.log('>>>> Remove')
            } else {
              allSteps.add(step);
            }
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Topup_Success');
        dispatch({
          type: TOPUP_SUCCESS,
          formdata: formdata,
          topupdata: topupdata,
          responseData: error,
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Show_Charges' && type === 'tw'){
              allSteps.add('Show_Charges');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else if(step === 'Otp_Verification' && type === 'sw'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              if(step === 'Topup_Combo_Success') {
                console.log('>>>> Remove')
              } else {
                allSteps.add(step);
              }
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Topup_Success');
          dispatch({
            type: TOPUP_SUCCESS,
            formdata: formdata,
            topupdata: topupdata,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: TOPUP_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiry = (type) => dispatch => {
  let formdata = store.getState().topup.formdata;
  let user = store.getState().session.user;
  let txnType = (type === 'tw' ? 'tw_txn_enquiry' : 'sw_txn_enquiry');
  let mobileNumber = (formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber);
  dispatch({
    type: GET_TRANSACTION_ENQUIRY_REQUEST
  });

  let topupEnquiry = {
    txnType: txnType,
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    custIdentifier: {
      mobileNumber: mobileNumber,
      custCardId: formdata.cardId
    },
    RequestData: store.getState().topup.topupdata
  };

  // if(type === 'tw') {
  //   topupEnquiry['RequestData']['BonusAmt'] = getCharges.ChargeList.BonusAmt;
  // }

  let url = `/citycash-bc/${type === 'tw' ? 'TWTopUpEnquiry' : 'SWTopUpEnquiry'}/`;
  Instance
    .post(url , topupEnquiry)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          dispatch({
            type: GET_TRANSACTION_ENQUIRY_SUCCESS,
            formdata: formdata,
            transactionEnquiry: response.data,
          });
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Transaction_Enquiry'){
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.steps = [...allSteps];
          formdata.backhide = true;
          formdata.activeStep += 1;
          // formdata.steps = _.without([...allSteps], 'Topup_Success');
          dispatch({
            type: GET_TRANSACTION_ENQUIRY_SUCCESS,
            formdata: formdata,
            transactionEnquiry: response.data,
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_TOPUP,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      // dispatch({
      //   type: GET_TRANSACTION_ENQUIRY_FAILURE,
      //   formdata: formdata,
      //   transactionEnquiry: error
      // });
    })
}

export const sendOtp = (resend) => dispatch => {
  let formdata = store.getState().topup.formdata;
  let cardType = formdata.selectCardInfo.cardType;
  let user = store.getState().session.user;

  dispatch({
    type: OTP_SEND_REQUEST,
  });

  Instance
    .post('/citycash-bc/generateOTP', {
      txnType: 'otp_generation',
      cardType: cardType === 'Over the counter card' ? 'OTC': 'PPC',
      messageId: 8,
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          // if its not resend otp
          if(resend === 0){
            formdata.activeStep = formdata.activeStep + 1;
          }

          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `OTP send the mobile number (${formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber})`
            }
          });
          dispatch({
            type: OTP_SEND_SUCCESS,
            formdata: formdata,
            otpInfo:  response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_TOPUP,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
      } else {
        dispatch({
          type: ERROR_MEESAGE_TOPUP,
          error: {
            errorStatus: true,
            errorType: 'error',
            errorMessage: response.statusText
          }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_TOPUP,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: OTP_SEND_FAILURE,
        otpInfo: error
      });
    })
}

export const changeAmountTextField = (amount) => dispatch => {
  dispatch({
    type: CHANGE_AMOUNT_TEXTFIELD,
    amount: amount
  });
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().topup.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_TOPUP,
    error: errorObject
  });
}
