import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';

import rootReducer from 'reducers';

// const loggerMiddleware = createLogger({ diff: true });
const loggerMiddleware = createLogger();
export const history = createHistory({
  basename: '', // The base URL of the app (see below)
  forceRefresh: false, // Set true to force full page refreshes
  keyLength: 6, // The length of location.key
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

const route = routerMiddleware(history);
const preloadedState = {};
const middlewares = [route, thunkMiddleware, loggerMiddleware];
const middlewareEnhancer = composeWithDevTools(
  applyMiddleware(...middlewares)
);

const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);

const configureStore = createStore(rootReducer(history), preloadedState, composedEnhancers);

export default configureStore;

// export default function configureStore(preloadedState = {}) {
//   const middlewares = [thunkMiddleware, loggerMiddleware];
//   const middlewareEnhancer = composeWithDevTools(
//     applyMiddleware(...middlewares)
//   );

//   const enhancers = [middlewareEnhancer];
//   const composedEnhancers = compose(...enhancers);

//   const store = createStore(rootReducer, preloadedState, composedEnhancers);

//   return store;
// }
