import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { writeStorage, deleteFromStorage } from '@rehooks/local-storage';
import { push } from 'connected-react-router';
// import { history } from './../store/configureStore';

// LOGIN USER
export const SESSION_LOGIN_REQUEST = 'SESSION_LOGIN_REQUEST';
export const SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS';
export const SESSION_LOGIN_FAILED = 'SESSION_LOGIN_FAILED';

export const USER_UPDATE = 'USER_UPDATE';

// LOGOUT USER
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

// Error Message
export const ERROR_MEESAGE_USER_LOGIN = 'ERROR_MEESAGE_USER_LOGIN';

export const userUpdate = (user) => dispatch => {
  dispatch({
    type: USER_UPDATE,
    user: user,
  });
}

export const login = (values) => dispatch => {

  dispatch({
    type: SESSION_LOGIN_REQUEST
  });

  Instance
    .post('/citycash-bc/merchant-login', {
      requestId: requestId(values.username),
      txnType: 'citycash_bc_login',
      phoneNumber: values.username,
      password: window.btoa(values.password)
    })
    .then(response => {
      let user = {};
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          user['fullname'] = response.data.fullname;
          user['phone_number'] = values.username;
          user['email'] = response.data.email === undefined ? '' : response.data.email;
          user['avatar'] = response.data.avatar === undefined ? '' : response.data.avatar;
          user['bio'] = response.data.bio === undefined ? 'Agent' : response.data.bio;
          user['role'] = response.data.role === undefined ? 'AGENT' : response.data.role;
          user['balance'] = response.data.balance === undefined ? 0 : response.data.balance;
          user['merchantType'] = response.data.merchantType;
          user['initiatorId'] = response.data.initiatorId === undefined ? 0 : response.data.initiatorId;
          user['initiatorType'] = response.data.initiatorType === undefined ? 0 : response.data.initiatorType;
          user['remin_card'] = response.data.remainingCard === undefined ? 0 : response.data.remainingCard;
          user['depotCode'] = response.data.depotCode === undefined ? 0 : response.data.depotCode;
          user['sessionId'] = response.data.sessionId === undefined ? 0 : response.data.sessionId;
          user['loginId'] = response.data.loginId === undefined ? 0 : response.data.loginId;
          user['divisionId'] = response.data.divisionId === undefined ? 0 : response.data.divisionId;
          user['terminalCode'] = response.data.terminal_code === undefined ? 0 : response.data.terminal_code;
          user['agentId'] = response.data.agentId === undefined ? 0 : response.data.agentId;
          user['hopType'] = response.data.hopType === undefined ? 0 : response.data.hopType;
          user['bankName'] = response.data.bankName;
          user['bankAccountNumber'] = response.data.bankAccountNumber;
          user['mobileNumber'] = response.data.mobileNumber;
          user['tokenInfo'] = response.data.tokenInfo;
          writeStorage('user', user);
          writeStorage('loggedIn', true);
          dispatch({
            type: SESSION_LOGIN_SUCCESS,
            formdata: values,
            user: user,
            loggedIn: response.data.responseCode === 200 ? true : false,
            responseData: response.data,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_USER_LOGIN,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseCode === 4092 ? 'Invalid login credentials' : response.data.responseMessage
            }
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_USER_LOGIN,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: SESSION_LOGIN_FAILED,
        responseData: error
      });
    })
}


export const logout = () => dispatch => {
  deleteFromStorage('user');
  deleteFromStorage('loggedIn');
  dispatch(push('/auth/login'));
  dispatch({
    type: SESSION_LOGOUT
  });
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().session.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_USER_LOGIN,
    error: errorObject
  });
}
