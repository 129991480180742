import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// GET CARD LIST
export const BLOCK_CARD_LIST_REQUEST = 'BLOCK_CARD_LIST_REQUEST';
export const BLOCK_CARD_LIST_SUCCESS = 'BLOCK_CARD_LIST_SUCCESS';
export const BLOCK_CARD_LIST_FAILURE = 'BLOCK_CARD_LIST_FAILURE';

// GET CHARGES TOPUP
export const GET_CHARGES_BLOCK_REQUEST = 'GET_CHARGES_BLOCK_REQUEST';
export const GET_CHARGES_BLOCK_SUCCESS = 'GET_CHARGES_BLOCK_SUCCESS';
export const GET_CHARGES_BLOCK_FAILURE = 'GET_CHARGES_BLOCK_FAILURE';

// TOPUP
export const BLOCK_REQUEST = 'BLOCK_REQUEST';
export const BLOCK_SUCCESS = 'BLOCK_SUCCESS';
export const BLOCK_FAILURE = 'BLOCK_FAILURE';

// GET TRANSACTION ENQUIRY
export const BLOCK_TRANSACTION_ENQUIRY_REQUEST = 'BLOCK_TRANSACTION_ENQUIRY_REQUEST';
export const BLOCK_TRANSACTION_ENQUIRY_SUCCESS = 'BLOCK_TRANSACTION_ENQUIRY_SUCCESS';
export const BLOCK_TRANSACTION_ENQUIRY_FAILURE = 'BLOCK_TRANSACTION_ENQUIRY_FAILURE';

// Otp Send
export const BLOCK_OTP_SEND_REQUEST = 'BLOCK_OTP_SEND_REQUEST';
export const BLOCK_OTP_SEND_SUCCESS = 'BLOCK_OTP_SEND_SUCCESS';
export const BLOCK_OTP_SEND_FAILURE = 'BLOCK_OTP_SEND_FAILURE';

// DIALOG TOPUP
export const DIALOG_BLOCK = 'DIALOG_BLOCK';

// Form Data Change
export const BLOCK_FORM_DATA_CHANGE = 'BLOCK_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_BLOCK = 'BACK_HOME_BLOCK';

// Error Message
export const ERROR_MEESAGE_BLOCK = 'ERROR_MEESAGE_BLOCK';

// Back Action
export const BACK_ERROR_BLOCK = 'BACK_ERROR_BLOCK';

export const backAction = (status) => dispatch => {
  let formdata = store.getState().block.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_BLOCK,
    formdata: formdata
  })
}

export const dialogShow = (dialog) => dispatch => {
  dispatch({
    type: DIALOG_BLOCK,
    dialog: dialog
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_BLOCK,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: BLOCK_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const SelectCardInfo = (cardId) => dispatch => {
  let formdata = store.getState().block.formdata;
  formdata.cardId = cardId;
  formdata.selectCardInfo = _.find(store.getState().block.cardList.cardList, {cardId: cardId});
  dispatch({
    type: BLOCK_FORM_DATA_CHANGE,
    formdata: formdata,
  });
}

export const getCardList = () => dispatch => {
  let formdata = store.getState().block.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: BLOCK_CARD_LIST_REQUEST
  });

  Instance
    .post('/citycash-bc/getCardListForOnlineRecharge/', {
      customerIdentifierType: formdata.customerIdentifierValue.toString().length === 10 ? 'phone_number' : 'card_id',
      customerIdentifierValue: formdata.customerIdentifierValue.toString(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      rechargeType: 'report_loss',
      requestId: requestId(),
      amount: '',
      txnType: 'get_card_list_for_online_recharge',
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(response.data.cardList.length > 0){
            formdata.activeStep = formdata.activeStep + 1;
            let cardList = response.data.cardList.filter(card =>card.cardStatus === 'ISD');
            formdata.cardId = cardList.length === 1 ? cardList[0].cardId : '';
            if(formdata.cardId !== ''){
              formdata.selectCardInfo = _.find(response.data.cardList, {cardId: formdata.cardId});
            }
          } else {
            dispatch({
              type: ERROR_MEESAGE_BLOCK,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: formdata.customerIdentifierValue.length === 10 ? 'Invalid mobile number' : 'Invalid card number'
              }
            });
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: BLOCK_CARD_LIST_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_BLOCK,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: BLOCK_CARD_LIST_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const getCharges = () => dispatch => {
  let formdata = store.getState().block.formdata;
  let dialog = store.getState().block.dialog;
  let user = store.getState().session.user;

  dispatch({
    type: GET_CHARGES_BLOCK_REQUEST
  });

  Instance
    .post('/citycash-bc/GetOnlineTransactionCharges/', {
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      txnType: 'get_block_charges',
      depoCode: '',
      divisionId: '',
      amount: 0,
      ePurseFees: '',
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if(response.data.responseCode === 4554) {
          dialog.open = true;
          dialog.title = 'Exceeds Block Amount';
          dialog.message = response.data.responseMessage;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: GET_CHARGES_BLOCK_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_BLOCK,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_CHARGES_BLOCK_FAILURE,
        formdata: formdata,
        responseData: error
      });
    })
}

export const block = () => dispatch => {
  let formdata = store.getState().block.formdata;
  let getCharges = store.getState().block.getCharges;
  let otpInfo = store.getState().block.otpInfo; 
  let user = store.getState().session.user;
  let cardType = formdata.selectCardInfo.cardType === 'Over the counter card' ? 'OTC': formdata.selectCardInfo.cardType === 'Physical Personalised Card' ? 'PPC' : 'U18';

  dispatch({
    type: BLOCK_REQUEST
  });

  let blockdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'report_loss',
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.cardId
    },
    auth: {
      otp: formdata.otp,
      referenceId: otpInfo.referenceId
    },
    inquiryRequestId: '',
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    loginId: user.loginId,
    ChargeList: getCharges.ChargeList
  };
  
  Instance
    .post('/citycash-bc/blockCard/', blockdata)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Block_Success');
        } else if([4190].includes(response.data.responseCode) && ['U18'].includes(cardType)) {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else if([3000].includes(response.data.responseCode) && ['OTC', 'PPC'].includes(cardType)) {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          formdata.activeStep += 1;
          formdata.backhide = true;
        }
      }
      dispatch({
        type: BLOCK_SUCCESS,
        formdata: formdata,
        blockdata: blockdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verification'){
            allSteps.add('Otp_Verification');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            allSteps.add(step);
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Block_Success');
        dispatch({
          type: BLOCK_SUCCESS,
          formdata: formdata,
          blockdata: blockdata,
          responseData: error,
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Block_Success');
          dispatch({
            type: BLOCK_SUCCESS,
            formdata: formdata,
            blockdata: blockdata,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: BLOCK_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiry = () => dispatch => {
  let formdata = store.getState().block.formdata;
  let getCharges = store.getState().block.getCharges;
  let otpInfo = store.getState().block.otpInfo; 
  let user = store.getState().session.user;
  let request_Id = store.getState().block.blockdata.requestId;

  dispatch({
    type: BLOCK_TRANSACTION_ENQUIRY_REQUEST
  });

  let blockdata = {
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    txnType: 'report_loss',
    custIdentifier: {
      mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
      custCardId: formdata.cardId
    },
    auth: {
      otp: formdata.otp,
      referenceId: otpInfo.referenceId
    },
    inquiryRequestId: request_Id,
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    sessionId: user.sessionId,
    loginId: user.loginId,
    ChargeList: getCharges.ChargeList
  };
  
  Instance
    .post('/citycash-bc/blockCard/', blockdata)
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        // if(response.data.responseCode === 200){
        //   formdata.activeStep += 1;
        //   formdata.backhide = true;
        //   dispatch({
        //     type: BLOCK_TRANSACTION_ENQUIRY_SUCCESS,
        //     formdata: formdata,
        //     blockdata: blockdata,
        //     responseData: response.data,
        //   });
        // } else 
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999){
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else if([200].includes(response.data.responseCode)) {
          if(response.data.ResponseData.responseCode === 4999){
            dispatch({
              type: ERROR_MEESAGE_BLOCK,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: response.data.ResponseData.responseMessage
              }
            });
          } else {
            formdata.activeStep += 1;
            formdata.backhide = true;
            dispatch({
              type: BLOCK_TRANSACTION_ENQUIRY_SUCCESS,
              formdata: formdata,
              responseData: response.data,
            });
          }
        } else {
          formdata.activeStep += 1;
          formdata.backhide = true;
          dispatch({
            type: BLOCK_TRANSACTION_ENQUIRY_SUCCESS,
            formdata: formdata,
            responseData: response.data,
          });
        }
      }
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verification'){
            allSteps.add('Otp_Verification');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            allSteps.add(step);
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Topup_Success');
        dispatch({
          type: BLOCK_TRANSACTION_ENQUIRY_SUCCESS,
          formdata: formdata,
          blockdata: blockdata,
          responseData: error,
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verification'){
              allSteps.add('Otp_Verification');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Topup_Success');
          dispatch({
            type: BLOCK_TRANSACTION_ENQUIRY_SUCCESS,
            formdata: formdata,
            blockdata: blockdata,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: BLOCK_TRANSACTION_ENQUIRY_FAILURE,
            formdata: formdata,
            responseData: error
          });
        }
      }
    })
}

export const sendOtp = (resend) => dispatch => {
  let formdata = store.getState().block.formdata;
  let cardType = formdata.selectCardInfo.cardType;
  let user = store.getState().session.user;

  dispatch({
    type: BLOCK_OTP_SEND_REQUEST,
  });

  Instance
    .post('/citycash-bc/generateOTP', {
      txnType: 'otp_generation',
      cardType: cardType === 'Over the counter card' ? 'OTC': cardType === 'Physical Personalised Card' ? 'PPC' : 'U18',
      messageId: 7,
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      custIdentifier: {
        mobileNumber: formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          // if its not resend otp
          if(resend === 0){
            formdata.activeStep = formdata.activeStep + 1;
          }

          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `OTP send the mobile number (${formdata.customerIdentifierValue.length === 10 ? formdata.customerIdentifierValue : formdata.selectCardInfo.phoneNumber})`
            }
          });
          dispatch({
            type: BLOCK_OTP_SEND_SUCCESS,
            formdata: formdata,
            otpInfo:  response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_BLOCK,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
      } else {
        dispatch({
          type: ERROR_MEESAGE_BLOCK,
          error: {
            errorStatus: true,
            errorType: 'error',
            errorMessage: response.statusText
          }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_BLOCK,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: BLOCK_OTP_SEND_FAILURE,
        otpInfo: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().block.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_BLOCK,
    error: errorObject
  });
}
