// // import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from '../store';

import { logout } from 'actions';

// Form Data Change
export const SUPPORT_FORM_DATA_CHANGE = 'SUPPORT_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_SUPPORT = 'BACK_HOME_SUPPORT';

// Error Message
export const ERROR_MEESAGE_SUPPORT = 'ERROR_MEESAGE_SUPPORT';

export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_SUPPORT,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: SUPPORT_FORM_DATA_CHANGE,
    formdata: values,
  });
}


export const sendFeedback = () => dispatch => {
  let user = store.getState().session.user;
  let formdata = store.getState().support.formdata;

  console.log(formdata.attachment)
  // return false;

  dispatch({
    type: SEND_FEEDBACK_REQUEST
  });
  
  Instance
    .post('/merchant/feedback', {
      requestId: requestId(),
      token: user.tokenInfo.token,
      feedback: formdata.msg,
      attachment: formdata.attachment
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep = formdata.activeStep + 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_SUPPORT,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: SEND_FEEDBACK_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_SUPPORT,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: SEND_FEEDBACK_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().support.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_SUPPORT,
    error: errorObject
  });
}
