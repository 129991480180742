import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.cardAcceptance)

const cardAcceptanceReducer = (state = Data.cardAcceptance, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_CARD_ACCEPTANCE: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.RESET_TRANSACTION_DATA: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.CARD_ACCEPTANCE_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata
      }

    case actionTypes.ERROR_MEESAGE_CARD_ACCEPTANCE: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case actionTypes.CARD_ACCEPTANCE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CARD_ACCEPTANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        acceptData: action.responseData
      };
    }

    case actionTypes.CARD_ACCEPTANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        acceptData: action.responseData
      };
    }

    case actionTypes.ACCEPT_CARD_ORDER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.ACCEPT_CARD_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        acceptOrder: action.responseData
      };
    }

    case actionTypes.ACCEPT_CARD_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        acceptOrder: action.responseData
      };
    }

    default: {
      return state;
    }
  }
};

export default cardAcceptanceReducer;
