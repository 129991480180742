import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { push } from 'connected-react-router';
import useRouter from 'utils/useRouter';

import {
  Redirect
} from 'react-router-dom';

const guestRoutes = [
  '/auth/login',
  '/auth/forgot-password'
];

const AuthGuard = props => {
  const { children } = props;
  // const dispatch = useDispatch();

  const session = useSelector(state => state.session);
  const router = useRouter();
  // console.log(router);
  // useEffect(() => {
  //   console.log('>>>>>>>>>>')
  // console.log("cust login", session.loggedIn)
  // console.log("guest", guestRoutes.includes(router.location.pathname));

  if(guestRoutes.includes(router.location.pathname) && session.loggedIn === true){

    if(['AM+UPI'].includes(session.user.merchantType)) {
      // prod
      window.location.assign('/merchant-dashboard/index.html')
      // staging
      // window.location.assign('/merchant-dashboard')
    } else if(['IM+AM+UPI', 'IM+UPI'].includes(session.user.merchantType)) { 
      return <Redirect
        to={{
          pathname: '/',
        }}
      />;
    }
  }

  if (!guestRoutes.includes(router.location.pathname) && (!session.loggedIn || !session.user)) {
    return <Redirect
      to={{
        pathname: '/auth/login',
      }}
    />;
  }
  
  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

AuthGuard.defaultProps = {
  roles: []
};

export default AuthGuard;
