import * as actionTypes from 'actions';
import * as Data from 'data';

const UtilityReducer = (state = Data.utility, action) => {
  switch (action.type) {
    case actionTypes.NETWORK_STATUS:
      return {
        ...state,
        networkStatus: action.status,
      }

    case actionTypes.ONE_TIME:
      return {
        ...state,
        oneTime: action.oneTime,
      }

    default: {
      return state;
    }
  }
};

export default UtilityReducer;
