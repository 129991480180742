import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.calculator)

const calculatorReducer = (state = Data.calculator, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_CALCULATOR: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.CALCULATOR_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.ERROR_MEESAGE_CALCULATOR: {
      return {
        ...state,
        error: action.error
      }
    }

    default: {
      return state;
    }
  }
};

export default calculatorReducer;
