import moment from 'moment';

export const BASE_REDUCER = {
  error: {
    errorStatus: false,
    errorType: '',
    errorMessage: ''
  },
  loading: false
};

export const agentLoad = {
  formdata: {
    activeStep: 0,
    amount: '',
    paymentMode: '',
    otherDetails: '',
    steps: ['Load_Amount_Form', 'Load_Amount_Response'],
    backhide: false,
  },
  ...BASE_REDUCER
};

export const dashboard = {
  ...BASE_REDUCER
};

export const busPass = {
  formdata: {
    activeStep: 0,
    customerIdentifierValue: '',
    cardId: '',
    amount: 0,
    passValidity: '',
    busRoute: '',
    passStartDate: new Date(),
    steps: ['Customer_Details', 'Get_Card_List', 'Pass_Details', 'Choose_Route_Bus_Pass', 'Charges_List', 'Bus_Pass_Success'],
    selectCardInfo: null,
    backhide: false,
    paymentMode: ''
  },
  passRenewalTransEnquiry: {
    responseCode: 0
  },
  ...BASE_REDUCER
};

export const busPass1 = {
  formdata: {
    activeStep: 5,
    customerIdentifierValue: '8340051547',
    cardId: '01162578169585280',
    amount: 0,
    passValidity: '1 Month',
    busRoute: 'ALL',
    passStartDate: '2020-12-03T08:09:29.140Z',
    steps: [
      'Customer_Details',
      'Get_Card_List',
      'Pass_Details',
      'Choose_Route_Bus_Pass',
      'Charges_List',
      'Transaction_Enquiry',
      'Enquiry_Success'
    ],
    selectCardInfo: {
      trimaxId: '0430000000000854',
      cardId: '01162578169585280',
      cardStatus: 'ISD',
      customerName: 'TARXXXXXXXXJOL',
      phoneNumber: '83XXXXXX47',
      cardType: 'Under18 card'
    },
    backhide: false,
    paymentMode: 'cash'
  },
  passRenewalTransEnquiry: {
    responseCode: 0
  },
  error: {
    errorStatus: true,
    errorType: 'error',
    errorMessage: 'Cannot read property \'responseCode\' of undefined'
  },
  loading: false,
  cardList: {
    responseCode: 200,
    responseMessage: 'Success',
    rechargeType: 'bp_bc_debit_cash',
    amount: 0,
    cardList: [
      {
        trimaxId: '0430000000000854',
        cardId: '01162578169585280',
        cardStatus: 'ISD',
        customerName: 'TARXXXXXXXXJOL',
        phoneNumber: '83XXXXXX47',
        cardType: 'Under18 card'
      },
      {
        trimaxId: '9990000000013575',
        cardId: '01168110087462528',
        cardStatus: 'ISD',
        customerName: 'KumXXXXXXXXthi',
        phoneNumber: '83XXXXXX47',
        cardType: 'Over the counter card'
      },
      {
        trimaxId: '0430000000000859',
        cardId: '02345672345678923',
        cardStatus: 'ISA',
        customerName: 'TARXXXXXXXXESH',
        phoneNumber: '83XXXXXX47',
        cardType: 'Under18 card'
      },
      {
        trimaxId: '0430000000000853',
        cardId: '01323456789123457',
        cardStatus: 'ISD',
        customerName: 'RAVXXXXXXXXMAR',
        phoneNumber: '83XXXXXX47',
        cardType: 'Under18 card'
      }
    ]
  },
  busPassStatus: {
    responseCode: 200,
    responseMessage: 'Bus pass can be renewed',
    busPassType: 'DAY ORDINARY',
    dualRoute: 'false',
    newActivationDate: '2026-05-16',
    passActivationDate: '2026-04-16',
    passExpiryDate: '2026-05-15',
    requestId: '4e193cfe-99fe-4c27-9482-28ede05404af',
    pass_validity_data: [
      '1 Month',
      '3 Month'
    ],
    bus_route_type: [
      'ALL',
      'CITY',
      'MOFUSSIL'
    ],
    routes: [
      {
        routeNo: '450',
        fromStopCode: 'MCT',
        tillStopCode: 'THNCBS'
      }
    ]
  },
  fare: {
    responseCode: 200,
    responseMessage: 'Success',
    data: [
      {
        pass_amount: '600.00',
        from_stop_code: 'MCT',
        till_stop_code: 'THNCBS',
        route_no: '450'
      }
    ],
    totalPassAmount: 600,
    ChargeList: {
      LoadingFee: 0,
      CardFee: 0,
      GST: 1.08,
      BCCharges: 12,
      ePurseFees: 0,
      passCharges: 6,
      TotalCharge: 19.08,
      discount: 0,
      cashbackTw: 0,
      cashbackSw: 0,
      PN: 0
    },
    totalChargeableAmount: 619.08
  },
  busPass: {
    requestId: '54f373d7-47f4-46d9-7ea2-393369a4d98d',
    token: '8d5307c9983263534378972991943499079',
    agentId: '00634',
    depotCode: 'PPR',
    custIdentifier: {
      trimax_card_id: '0430000000000854'
    },
    txnType: 'bp_bc_debit_cash',
    terminal_code: 'FNMCT00002004',
    ChargeList: {
      LoadingFee: 0,
      CardFee: 0,
      GST: 1.08,
      BCCharges: 12,
      ePurseFees: 0,
      passCharges: 6,
      TotalCharge: 19.08,
      discount: 0,
      cashbackTw: 0,
      cashbackSw: 0
    },
    pass_amount_1: '600.00',
    pass_validity: '1 Month',
    bus_route_type: 'ALL',
    pass_activation_date: '2026-05-16',
    initiatorType: 'ImpsUpi',
    initiatorId: 'FNPPR00001001',
    passExpiry: '2026-06-16'
  },
  passRenewal: {}
}

export const calculator = {
  formdata: {
    activeStep: 0,
    transType: '',
    amount: undefined,
    amount2: undefined,
    steps: ['Select Transaction Type', 'Transaction Details'],
    backhide: false,
  },
  ...BASE_REDUCER
};

export const support = {
  formdata: {
    activeStep: 0,
    steps: ['Support', 'Support Response'],
    msg: '',
    attachment: undefined,
    backhide: false,
  },
  ...BASE_REDUCER
};

export const forgotPassword = {
  formdata: {
    activeStep: 0,
    username: '',
    otp: '',
    conPassword: '',
    password: '',
    steps: ['Agent Search', 'Otp Verify', 'Password Change'],
    backhide: false
  },
  ...BASE_REDUCER
};

export const otcCardOrder = {
  formdata: {
    activeStep: 0,
    totalCards: undefined,
    steps: ['Number_Of_Card', 'Charges_Form', 'Success'],
    backhide: false,
  },
  ...BASE_REDUCER
};

export const otcRegistration = {
  formdata: {
    activeStep: 0,
    permanentAddress: false,
    steps: ['Customer_Search', 'Customer_Info', 'Customer_Address', 'Customer_Permanent_Addresss', 'Customer_Extra_Info', 'Payment_Info', 'Otp_Verify', 'UPIID_Linking', 'Receipt'],
    mobileNumber: '',
    cardId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    email: '',
    proof_type: 1,
    proof_reference_number: '',
    dob: new Date(moment().subtract(18, 'years').format('YYYY-MM-DD')),
    address: '',
    pinCode: '',
    city: '',
    type_of_address: '',
    locality: '',
    distance_from_the_depot: '',
    earning_member_in_family: '',
    marital_status: '',
    education: '',
    occupation: '',
    monthly_income: '',
    paymentMode: '',
    otp: '',
    upiStatus: '',
    transEnquiry: false,
    upiId: '',
    backhide: false,
  },
  uniqueIdForm: false,
  customerSearch: {},
  charges: {},
  authInfo: {},
  walletCreation: {},
  walletCreationTransactionInquiry: {},
  walletCreationReposting: {},
  timer: null,
  ...BASE_REDUCER
}

export const packageOTCRegistration = {
  formdata: {
    activeStep: 0,
    permanentAddress: false,
    steps: [
      'Get_Package_List',
      'Customer_Search',
      'Customer_Info',
      'Customer_Address',
      'Customer_Permanent_Addresss',
      'Customer_Extra_Info',
      'Payment_Info',
      'Otp_Verify',
    ],
    packageType: '',
    amount: '',
    mobileNumber: '',
    cardId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    email: '',
    proof_type: 1,
    proof_reference_number: '',
    dob: new Date(moment().subtract(18, 'years').format('YYYY-MM-DD')),
    address: '',
    pinCode: '',
    city: '',
    type_of_address: '',
    locality: '',
    distance_from_the_depot: '',
    earning_member_in_family: '',
    marital_status: '',
    education: '',
    occupation: '',
    monthly_income: '',
    paymentMode: '',
    topOtp: '',
    otp: '',
    upiStatus: '',
    transEnquiry: false,
    upiId: '',
    backhide: false,
    couponStatus: true,
    couponCode: ''
  },
  customerSearch: {},
  uniqueIdForm: false,
  charges: {},
  authInfo: {},
  walletCreation: {},
  walletCreationTransactionInquiry: {},
  walletCreationReposting: {},
  timer: null,
  topupdata: {},
  topupSuccess: {},
  ...BASE_REDUCER
}

export const packageOTCRegistration1 = {
  formdata: {
    activeStep: 8,
    permanentAddress: false,
    steps: [
      'Get_Package_List',
      'Customer_Search',
      'Customer_Info',
      'Customer_Address',
      'Customer_Permanent_Addresss',
      'Customer_Extra_Info',
      'Payment_Info',
      'Otp_Verify',
      'Sw_Topup_OTP_Verify',
      'Success'
    ],
    packageType: 'Combo',
    amount: 300,
    mobileNumber: '8655909568',
    cardId: '9990000000013365',
    firstName: 'Appu',
    middleName: '',
    lastName: 'Yadav',
    gender: 'M',
    email: '',
    proof_type: 1,
    proof_reference_number: 'Test12345',
    dob: '2000-10-21T00:00:00.000Z',
    address: 'Patna',
    pinCode: '801305',
    city: 'Patna',
    type_of_address: '',
    locality: '',
    distance_from_the_depot: '',
    earning_member_in_family: '',
    marital_status: '',
    education: '',
    occupation: '',
    monthly_income: '',
    paymentMode: 'cash',
    topOtp: '',
    otp: '1412',
    upiStatus: '',
    transEnquiry: false,
    upiId: '',
    backhide: true,
    couponStatus: false,
    couponCode: ''
  },
  loading: false,
  error: {
    errorStatus: false,
    errorType: 'info',
    errorMessage: 'OTP send the mobile number (8655909568)'
  },
  customerSearch: {
    responseData: {
      responseCode: 200,
      responseMessage: 'Success',
      requestId: '564fdb4d-7755-4d19-6f70-ce5884075220'
    }
  },
  uniqueIdForm: false,
  charges: {
    responseData: {
      responseCode: 200,
      responseMessage: 'Success',
      ChargeList: {
        CardFee: 50,
        BCChargesReg: 20,
        LoadingFeeTw: 1.01,
        GSTTw: 0.18,
        BCChargesTw: 0,
        BonusAmt: 5,
        cashbackTw: 0,
        LoadingFeeSw: 1.23,
        GSTSw: 0.22,
        BCChargesSw: 12,
        cashbackSw: 37.66,
        TotalCharge: 77.64,
        discount: 7,
        totalChargeableAmount: 300
      },
      EffectiveAmountTw: 105,
      EffectiveAmountSw: 160
    }
  },
  authInfo: {
    responseData: {
      responseCode: 4648,
      responseMessage: '4648: Registration and TW topup are done. Generated OTP for SW',
      referenceId: '486662',
      requestId: '7efb5a16-4326-4c59-9de1-0f35310d141a'
    }
  },
  walletCreation: {
    responseData: {
      responseCode: 4648,
      responseMessage: '4648: Registration and TW topup are done. Generated OTP for SW',
      referenceId: '486662',
      requestId: '7efb5a16-4326-4c59-9de1-0f35310d141a'
    }
  },
  walletCreationTransactionInquiry: {},
  walletCreationReposting: {},
  timer: null,
  topupdata: {},
  topupSuccess: {},
  packageList: {
    responseCode: 200,
    responseMessage: 'Success',
    requestId: '68f3ef93-0160-47f9-2626-965af8f2581b',
    package_data: [
      {
        package_name: 'Combo',
        amt: [
          300,
          500,
          750,
          1000
        ]
      },
      {
        package_name: 'SW',
        amt: [
          100,
          200,
          300,
          500,
          750,
          1000
        ]
      },
      {
        package_name: 'TW',
        amt: [
          100,
          200,
          300,
          500,
          750,
          1000
        ]
      }
    ]
  },
  TID: {
    responseCode: 200,
    responseMessage: 'Success',
    data: [
      '9990000000013037',
      '9990000000013141',
      '9990000000013046',
      '9990000000013005',
      '9990000000013011',
      '9990000000013023',
      '9990000000013022',
      '9990000000013019',
      '9990000000013020',
      '9990000000013018',
      '9990000000013021',
      '9990000000013017',
      '9990000000013139',
      '9990000000013138',
      '9990000000013137',
      '9990000000013136',
      '9990000000013135',
      '9990000000013134',
      '9990000000013121',
      '9990000000013151',
      '9990000000013152',
      '9990000000013351',
      '9990000000013355',
      '9990000000013356',
      '9990000000013359',
      '9990000000013360',
      '9990000000013361',
      '9990000000013362',
      '9990000000013363',
      '9990000000013364',
      '9990000000013365',
      '9990000000013366',
      '9990000000013367',
      '9990000000013349',
      '9990000000013350',
      '9990000000013352',
      '9990000000013353',
      '9990000000013354',
      '9990000000013357',
      '9990000000013358',
      '9990000000013490',
      '9990000000013491',
      '9990000000013492',
      '9990000000013493',
      '9990000000013494',
      '9990000000013519',
      '9990000000013522',
      '9990000000013523',
      '9990000000013521',
      '9990000000013520'
    ]
  },
  requestData: {
    auth: {
      otp: '1412',
      referenceId: '486661'
    },
    loginId: '1024',
    token: '8c7a53433316b3932308548560503556371',
    agentId: '00130',
    txnType: 'wallet_creation',
    CustInfo: {
      city: 'Patna',
      email: '',
      state: 'MAHARASHTRA',
      title: 'Mr',
      gender: 'M',
      address: 'Patna',
      address1: '',
      country: 'IN',
      pincode: '801305',
      commLang: '3',
      lastName: 'Yadav',
      passCode: 'OTC',
      uniqueID: 'Test12345',
      firstName: 'Appu',
      ChargeList: {
        AF: 0,
        BF: 0,
        PN: 0,
        EPF: 0,
        SCF: 0,
        CardFee: 50,
        BonusAmt: 5,
        BCCharges: 20,
        LoadingFee: 0,
        TotalCharge: 77.64
      },
      ExtraDetails: {
        locality: '',
        education: '',
        occupation: '',
        addressType: '',
        totalMembers: '',
        depotDistance: '',
        monthlyIncome: '',
        maritalStatus: ''
      },
      passExpiry: '365 Days',
      dateOfBirth: '2000-10-21',
      serviceType: '',
      mobileNumber: '8655909568',
      routeFirstTo: '',
      uniqueIDType: '1',
      isPassCreated: '0',
      routeSecondTo: '',
      routeFirstFrom: '',
      routeSecondFrom: ''
    },
    cardType: 'OTC',
    depoCode: 'SLP',
    packageType: 'Combo',
    packageAmount: 300,
    trimaxId: '9990000000013365',
    requestId: '7efb5a16-4326-4c59-9de1-0f35310d141a',
    sessionId: '382832',
    instaPrint: '',
    initiatorId: '001300000000000',
    initiatorType: 'ImpsUpiAm',
    custIdentifier: {
      custCardId: '9990000000013365',
      mobileNumber: '8655909568'
    },
    couponCode: ''
  }
}

export const packageOTCRegistration2 = {
  formdata: {
    activeStep: 10,
    permanentAddress: true,
    steps: [
      'Get_Package_List',
      'Customer_Search',
      'Customer_Info',
      'Customer_Address',
      'Customer_Extra_Info',
      'Payment_Info',
      'Otp_Verify',
      'Transaction_Enquiry',
      'Sw_Topup_OTP_Verify',
      'sw_eqniury',
      'SW_Topup_Enquiry_Success',
      'Success'
    ],
    packageType: 'SW',
    amount: 100,
    mobileNumber: '8757938901',
    cardId: '9990000000013136',
    firstName: 'Appu',
    middleName: '',
    lastName: 'Yadav',
    gender: 'F',
    email: '',
    proof_type: 1,
    proof_reference_number: 'Test3456',
    dob: '2002-10-22T00:00:00.000Z',
    address: 'Mumbai',
    pinCode: '234324',
    city: 'Mumbai',
    type_of_address: '',
    locality: '',
    distance_from_the_depot: '',
    earning_member_in_family: '',
    marital_status: '',
    education: '',
    occupation: '',
    monthly_income: '',
    paymentMode: 'cash',
    topOtp: '8499',
    otp: '5641',
    upiStatus: '',
    transEnquiry: false,
    upiId: '',
    backhide: true,
    couponStatus: false,
    couponCode: ''
  },
  loading: false,
  error: {
    errorStatus: true,
    errorType: 'error',
    errorMessage: 'Cannot read property \'responseCode\' of undefined'
  },
  customerSearch: {
    responseData: {
      responseCode: 200,
      responseMessage: 'Success',
      requestId: '869dfbf1-7b9f-4eae-0455-0789879cce1d'
    }
  },
  uniqueIdForm: false,
  charges: {
    responseData: {
      responseCode: 200,
      responseMessage: 'Success',
      ChargeList: {
        CardFee: 50,
        BCChargesReg: 20,
        LoadingFeeTw: 0,
        GSTTw: 0,
        BCChargesTw: 0,
        BonusAmt: 0,
        cashbackTw: 0,
        LoadingFeeSw: 0.25,
        GSTSw: 0.04,
        BCChargesSw: 12,
        cashbackSw: 15.3,
        TotalCharge: 75.28,
        discount: 7,
        totalChargeableAmount: 100
      },
      EffectiveAmountTw: 0,
      EffectiveAmountSw: 40
    }
  },
  authInfo: {
    responseCode: 4648,
    responseMessage: '4648: Registration and TW topup are done. Generated OTP for SW',
    referenceId: '486748',
    requestId: '30dd94c0-ac91-474e-164c-886a714d480e'
  },
  walletCreation: {
    responseData: {
      responseCode: 4999,
      responseMessage: '4999: Connection Timed Out. Please perform Transaction Enquiry to confirm status.',
      requestId: '30dd94c0-ac91-474e-164c-886a714d480e'
    }
  },
  walletCreationTransactionInquiry: {
    responseCode: 200,
    responseMessage: 'Success',
    ResponseData: {
      responseCode: 4648,
      responseMessage: '4648: Registration and TW topup are done. Generated OTP for SW',
      referenceId: '486748',
      requestId: '30dd94c0-ac91-474e-164c-886a714d480e'
    }
  },
  walletCreationReposting: {},
  timer: null,
  topupdata: {
    txnType: 'sw_topup',
    paymentMode: 'cash',
    loadType: 'REG',
    custIdentifier: {
      mobileNumber: '8757938901',
      custCardId: '9990000000013136'
    },
    auth: {
      otp: '8499',
      referenceId: '486748'
    },
    amount: 100,
    amountType: 2,
    requestId: 'e153f3d3-55eb-44e2-275b-9a43b119f935',
    initiatorId: '001300000000000',
    initiatorType: 'ImpsUpiAm',
    loginId: '1024',
    sessionId: '382832',
    depoCode: 'SLP',
    agentId: '00130',
    divisionId: '32',
    LoadingFeeTw: 0,
    LoadingFeeSw: 0.25,
    CardFee: 50,
    GSTTw: 0,
    GSTSw: 0.04,
    BCChargesTw: 0,
    BCChargesSw: 12,
    passCharges: 0,
    TotalCharge: 75.28,
    BonusAmt: 0,
    discount: 7,
    cashbackTw: 0,
    cashbackSw: 15.3,
    EffectiveAmountSw: 40,
    EffectiveAmountTw: 0,
    packageType: 'SW',
    packageAmount: 100,
    token: '8c7a7efa8528c3932305919083268488902'
  },
  topupSuccess: {
    responseCode: 4999,
    responseMessage: '4999: Connection Timed Out. Please perform Transaction Enquiry to confirm status.',
    requestId: 'e153f3d3-55eb-44e2-275b-9a43b119f935',
    twBalance: 0,
    swBalance: 0,
    swClaimBalance: 0,
    twClaimBalance: 0
  },
  packageList: {
    responseCode: 200,
    responseMessage: 'Success',
    requestId: '1308a279-40e0-465d-f275-05bc8495cdd4',
    package_data: [
      {
        package_name: 'Combo',
        amt: [
          300,
          500,
          750,
          1000
        ]
      },
      {
        package_name: 'SW',
        amt: [
          100,
          200,
          300,
          500,
          750,
          1000
        ]
      },
      {
        package_name: 'TW',
        amt: [
          100,
          200,
          300,
          500,
          750,
          1000
        ]
      }
    ]
  },
  TID: {
    responseCode: 200,
    responseMessage: 'Success',
    data: [
      '9990000000013037',
      '9990000000013141',
      '9990000000013046',
      '9990000000013005',
      '9990000000013011',
      '9990000000013023',
      '9990000000013022',
      '9990000000013019',
      '9990000000013020',
      '9990000000013018',
      '9990000000013021',
      '9990000000013017',
      '9990000000013139',
      '9990000000013138',
      '9990000000013137',
      '9990000000013136',
      '9990000000013135',
      '9990000000013134',
      '9990000000013121',
      '9990000000013151',
      '9990000000013152',
      '9990000000013351',
      '9990000000013355',
      '9990000000013356',
      '9990000000013359',
      '9990000000013360',
      '9990000000013361',
      '9990000000013362',
      '9990000000013363',
      '9990000000013364',
      '9990000000013366',
      '9990000000013367',
      '9990000000013349',
      '9990000000013350',
      '9990000000013352',
      '9990000000013353',
      '9990000000013354',
      '9990000000013357',
      '9990000000013358',
      '9990000000013490',
      '9990000000013491',
      '9990000000013492',
      '9990000000013493',
      '9990000000013494',
      '9990000000013519',
      '9990000000013522',
      '9990000000013523',
      '9990000000013521',
      '9990000000013520'
    ]
  },
  requestData: {
    auth: {
      otp: '5641',
      referenceId: '486747'
    },
    loginId: '1024',
    token: '8c7a7efa8528c3932305919083268488902',
    agentId: '00130',
    txnType: 'wallet_creation',
    CustInfo: {
      city: 'Mumbai',
      email: '',
      state: 'MAHARASHTRA',
      title: 'Mr',
      gender: 'F',
      address: 'Mumbai',
      address1: '',
      country: 'IN',
      pincode: '234324',
      commLang: '3',
      lastName: 'Yadav',
      passCode: 'OTC',
      uniqueID: 'Test3456',
      firstName: 'Appu',
      ChargeList: {
        AF: 0,
        BF: 0,
        PN: 0,
        EPF: 0,
        SCF: 0,
        CardFee: 50,
        BonusAmt: 0,
        BCCharges: 20,
        LoadingFee: 0,
        TotalCharge: 75.28
      },
      ExtraDetails: {
        locality: '',
        education: '',
        occupation: '',
        addressType: '',
        totalMembers: '',
        depotDistance: '',
        monthlyIncome: '',
        maritalStatus: ''
      },
      passExpiry: '365 Days',
      dateOfBirth: '2002-10-22',
      serviceType: '',
      mobileNumber: '8757938901',
      routeFirstTo: '',
      uniqueIDType: '1',
      isPassCreated: '0',
      routeSecondTo: '',
      routeFirstFrom: '',
      routeSecondFrom: ''
    },
    cardType: 'OTC',
    depoCode: 'SLP',
    packageType: 'SW',
    packageAmount: 100,
    trimaxId: '9990000000013136',
    requestId: '30dd94c0-ac91-474e-164c-886a714d480e',
    sessionId: '382832',
    instaPrint: '',
    initiatorId: '001300000000000',
    initiatorType: 'ImpsUpiAm',
    custIdentifier: {
      custCardId: '9990000000013136',
      mobileNumber: '8757938901'
    },
    couponCode: ''
  }
}

export const packageOTCRegistration3 = {
  formdata: {
    activeStep: 8,
    permanentAddress: false,
    steps: [
      'Get_Package_List',
      'Customer_Search',
      'Customer_Info',
      'Customer_Address',
      'Customer_Permanent_Addresss',
      'Customer_Extra_Info',
      'Payment_Info',
      'Otp_Verify',
      'Sw_Topup_OTP_Verify',
      'Success'
    ],
    packageType: 'SW',
    amount: 200,
    mobileNumber: '7208092893',
    cardId: '9990000000013521',
    firstName: 'Veena',
    middleName: 'aa',
    lastName: 'Yadav',
    gender: 'F',
    email: '',
    proof_type: '2',
    proof_reference_number: '43224',
    dob: '2002-12-22T00:00:00.000Z',
    address: 'Patna',
    pinCode: '235235',
    city: 'dfgdf',
    type_of_address: '',
    locality: '',
    distance_from_the_depot: '',
    earning_member_in_family: '',
    marital_status: '',
    education: '',
    occupation: '',
    monthly_income: '',
    paymentMode: 'cash',
    topOtp: '',
    otp: '5305',
    upiStatus: '',
    transEnquiry: false,
    upiId: '',
    backhide: true,
    couponStatus: false,
    couponCode: ''
  },
  loading: false,
  error: {
    errorStatus: false,
    errorType: 'info',
    errorMessage: 'OTP send the mobile number (7208092893)'
  },
  customerSearch: {
    responseData: {
      responseCode: 200,
      responseMessage: 'Success',
      requestId: '63163e53-9c37-4c4a-4b2b-f69d74042d3a'
    }
  },
  uniqueIdForm: false,
  charges: {
    responseData: {
      responseCode: 200,
      responseMessage: 'Success',
      ChargeList: {
        CardFee: 50,
        BCChargesReg: 20,
        LoadingFeeTw: 0,
        GSTTw: 0,
        BCChargesTw: 0,
        BonusAmt: 0,
        cashbackTw: 0,
        LoadingFeeSw: 1.25,
        GSTSw: 0.22,
        BCChargesSw: 12,
        cashbackSw: 126.48,
        TotalCharge: 76.47,
        discount: 7,
        totalChargeableAmount: 200
      },
      EffectiveAmountTw: 0,
      EffectiveAmountSw: 250
    }
  },
  authInfo: {
    responseData: {
      responseCode: 4648,
      responseMessage: '4648: Registration and TW topup are done. Generated OTP for SW',
      referenceId: '496582',
      requestId: '7d48e9c4-08bc-4769-537c-a0160689fe8c'
    }
  },
  walletCreation: {
    responseData: {
      responseCode: 4648,
      responseMessage: '4648: Registration and TW topup are done. Generated OTP for SW',
      referenceId: '496582',
      requestId: '7d48e9c4-08bc-4769-537c-a0160689fe8c'
    }
  },
  walletCreationTransactionInquiry: {},
  walletCreationReposting: {},
  timer: null,
  topupdata: {},
  topupSuccess: {},
  packageList: {
    responseCode: 200,
    responseMessage: 'Success',
    requestId: '4365ee22-3603-45ac-a956-3dd005ac72d0',
    package_data: [
      {
        package_name: 'Combo',
        amt: [
          300,
          301,
          500,
          501,
          750,
          1000,
          1001
        ]
      },
      {
        package_name: 'SW',
        amt: [
          100,
          200,
          300,
          400,
          500,
          1000
        ]
      },
      {
        package_name: 'TW',
        amt: [
          100,
          101,
          200,
          201,
          202,
          203,
          204,
          300,
          301,
          302,
          303,
          304,
          500,
          501,
          502,
          503,
          504,
          750,
          1000,
          1001,
          1002,
          1003,
          1004
        ]
      }
    ]
  },
  TID: {
    responseCode: 200,
    responseMessage: 'Success',
    data: [
      '9990000000013037',
      '9990000000013141',
      '9990000000013046',
      '9990000000013005',
      '9990000000013011',
      '9990000000013023',
      '9990000000013022',
      '9990000000013019',
      '9990000000013020',
      '9990000000013018',
      '9990000000013021',
      '9990000000013017',
      '9990000000013139',
      '9990000000013138',
      '9990000000013137',
      '9990000000013135',
      '9990000000013121',
      '9990000000013151',
      '9990000000013152',
      '9990000000013351',
      '9990000000013355',
      '9990000000013356',
      '9990000000013359',
      '9990000000013360',
      '9990000000013361',
      '9990000000013362',
      '9990000000013363',
      '9990000000013364',
      '9990000000013366',
      '9990000000013367',
      '9990000000013349',
      '9990000000013350',
      '9990000000013352',
      '9990000000013353',
      '9990000000013354',
      '9990000000013357',
      '9990000000013358',
      '9990000000013490',
      '9990000000013491',
      '9990000000013492',
      '9990000000013493',
      '9990000000013494',
      '9990000000013519',
      '9990000000013522',
      '9990000000013523',
      '9990000000013521',
      '9990000000013520'
    ]
  },
  requestData: {
    auth: {
      otp: '5305',
      referenceId: '496580'
    },
    loginId: '1024',
    token: '8db570b3011273932306438414523911406',
    agentId: '00130',
    txnType: 'wallet_creation',
    CustInfo: {
      city: 'dfgdf',
      email: '',
      state: 'MAHARASHTRA',
      title: 'Mr',
      gender: 'F',
      address: 'Patna',
      address1: '',
      country: 'IN',
      pincode: '235235',
      commLang: '3',
      lastName: 'Yadav',
      passCode: 'OTC',
      uniqueID: '43224',
      firstName: 'Veena',
      ChargeList: {
        AF: 0,
        BF: 0,
        PN: 0,
        EPF: 0,
        SCF: 0,
        CardFee: 50,
        BonusAmt: 0,
        BCCharges: 20,
        LoadingFee: 0,
        TotalCharge: 76.47
      },
      ExtraDetails: {
        locality: '',
        education: '',
        occupation: '',
        addressType: '',
        totalMembers: '',
        depotDistance: '',
        monthlyIncome: '',
        maritalStatus: ''
      },
      passExpiry: '365 Days',
      dateOfBirth: '2002-12-22',
      serviceType: '',
      mobileNumber: '7208092893',
      routeFirstTo: '',
      uniqueIDType: '2',
      isPassCreated: '0',
      routeSecondTo: '',
      routeFirstFrom: '',
      routeSecondFrom: ''
    },
    cardType: 'OTC',
    depoCode: 'SLP',
    packageType: 'SW',
    packageAmount: 200,
    trimaxId: '9990000000013521',
    requestId: '7d48e9c4-08bc-4769-537c-a0160689fe8c',
    sessionId: '382832',
    instaPrint: '',
    initiatorId: '001300000000000',
    initiatorType: 'ImpsUpiAm',
    custIdentifier: {
      custCardId: '9990000000013521',
      mobileNumber: '7208092893'
    },
    couponCode: ''
  }
}

export const session = {
  loggedIn: localStorage.getItem('loggedIn') === undefined ? false : localStorage.getItem('loggedIn') === 'true' ? true : false,
  formdata: {
    username: '', //9833022920
    password: '' //12345
  },
  user: localStorage.getItem('user') === undefined ? {} : JSON.parse(localStorage.getItem('user')),
  ...BASE_REDUCER
};

export const topup = {
  formdata: {
    activeStep: 0,
    customerIdentifierValue: '',
    amount: '',
    cardId: '',
    otp: '',
    paymentMode: '',
    steps: ['Customer_Details', 'Get_Card_List', 'Show_Charges', 'Otp_Verification', 'Topup_Success'],
    selectCardInfo: null,
    backhide: false,
  },  
  transactionEnquiry: {
    responseCode: 0
  },
  dialog: {
    open:false,
    title: '',
    message: ''
  },
  ...BASE_REDUCER
};

export const transactions = {
  formdata: {
    activeStep: 0,
    txnStatusSummary: 'all',
    txnStatusHistory: 'all',
    txnType: 0,
    pages: 15,
    offset: 0,
    transactionShow: {},
    startDateTxnHistory: new Date(moment().format('YYYY-MM-DD')),
    startDateTxnSummary: new Date(moment().format('YYYY-MM-DD')),
    steps: ['All Transaction', 'Transaction Details'],
    title: 'Transaction History',
  },
  mer_summury: {
    txnSummary: []
  },
  ...BASE_REDUCER
};

export const cardAcceptance = {
  formdata: {
    activeStep: 0,
    steps: ['All Transaction'],
    title: 'Card Acceptance',
  },
  ...BASE_REDUCER
};

export const upiLink = {
  formdata: {
    activeStep: 0,
    customerIdentifierValue: '',
    cardId: '',
    upi_vpa: '',
    amount: 0,
    steps: ['Customer Search', 'Get Card List', 'Collect Request', 'Upi Linking Enquiry'],
    selectCardInfo: null,
    backhide: false,
  },
  timer: null,
  ...BASE_REDUCER
};

export const combo = {
  formdata: {
    activeStep: 0,
    packageType: '',
    amount: '',
    customerIdentifierValue: '',
    steps: ['Get_Package_List', 'Validate_Form', 'Card_Select', 'Charges_Info_Form', 'Otp_Verification', 'Topup_Combo_Success'],
    title: 'Combo Topup',
    paymentMode: '',
    backhide: false,
  },
  ...BASE_REDUCER,
  dialog: {
    open:false,
    title: '',
    message: ''
  }
};

export const block = {
  formdata: {
    activeStep: 0,
    customerIdentifierValue: '',
    cardId: '',
    otp: '',
    paymentMode: '',
    steps: ['Customer_Details', 'Get_Card_List', 'Show_Charges', 'Otp_Verification', 'Block_Success'],
    selectCardInfo: null,
    backhide: false,
  },  
  error: {
    errorStatus: false,
    errorType: '',
    errorMessage: ''
  },
  transactionEnquiry: {
    responseCode: 0
  },
  loading: false,
  dialog: {
    open:false,
    title: '',
    message: ''
  }
};

export const surrender = {
  formdata: {
    activeStep: 0,
    customerIdentifierValue: '',
    cardId: '',
    otp: '',
    paymentMode: '',
    steps: ['Customer_Details', 'Get_Card_List', 'Show_Charges', 'Otp_Verification', 'Surrender_Success'],
    selectCardInfo: null,
    backhide: false,
  },  
  error: {
    errorStatus: false,
    errorType: '',
    errorMessage: ''
  },
  transactionEnquiry: {
    responseCode: 0
  },
  loading: false,
  dialog: {
    open:false,
    title: '',
    message: ''
  }
};

export const surrender1 = {
  formdata: {
    activeStep: 3,
    customerIdentifierValue: '9304773304',
    cardId: '01261219469542528',
    otp: '4354',
    paymentMode: 'cash',
    steps: [
      'Customer_Details',
      'Get_Card_List',
      'Show_Charges',
      'Otp_Verification',
      'Transaction_Enquiry',
      'Enquiry_Success'
    ],
    selectCardInfo: {
      trimaxId: '9990000000014081',
      cardId: '01261219469542528',
      cardStatus: 'CWSAP',
      customerName: 'yuwXXXXXXXXuew',
      phoneNumber: '93XXXXXX04',
      cardType: 'Over the counter card',
      expiry: '09-12-2023 11:37:55',
      balance: 0
    },
    backhide: true
  },
  error: {
    errorStatus: false,
    errorType: 'info',
    errorMessage: 'OTP send the mobile number (9304773304)'
  },
  transactionEnquiry: {
    requestId: '161303473876000130v22',
    responseCode: 200,
    responseMessage: 'Success',
    ResponseData: {
      responseCode: 4999,
      responseMessage: '4999: Connection Timed Out. Please perform Transaction Enquiry to confirm status.',
      requestId: '161303473424200130v22',
      txnTime: '2021-02-11 02:42 PM'
    }
  },
  loading: false,
  dialog: {
    open: false,
    title: '',
    message: ''
  },
  cardList: {
    responseCode: 200,
    responseMessage: 'Success',
    rechargeType: 'surrender',
    amount: 0,
    cardList: [
      {
        trimaxId: '9990000000014081',
        cardId: '01261219469542528',
        cardStatus: 'CWSAP',
        customerName: 'yuwXXXXXXXXuew',
        phoneNumber: '93XXXXXX04',
        cardType: 'Over the counter card',
        expiry: '09-12-2023 11:37:55',
        balance: 0
      }
    ]
  },
  getCharges: {
    ChargeList: {
      LoadingFee: 0,
      CardFee: 0,
      GST: 0,
      BCCharges: 24,
      ePurseFees: 0,
      passCharges: 0,
      TotalCharge: 24
    },
    EffectiveAmount: 0,
    requestId: '161303471324300130v22',
    responseMessage: 'Success',
    responseCode: 200
  },
  otpInfo: {
    responseCode: 200,
    responseMessage: 'Success',
    requestId: '161303471893600130v22',
    referenceId: '506777'
  },
  surrenderdata: {
    requestId: '161303473424200130v22',
    initiatorId: '001300000000000',
    initiatorType: 'ImpsUpiAm',
    txnType: 'surrender',
    paymentMode: 'cash',
    custIdentifier: {
      mobileNumber: '9304773304',
      custCardId: '01261219469542528'
    },
    auth: {
      otp: '4354',
      referenceId: '506777'
    },
    depoCode: 'SLP',
    token: '8ebb8e45011ed3932308731157756621106',
    agentId: '00130',
    divisionId: '32',
    sessionId: '382832',
    loginId: '1024',
    hopType: '',
    ChargeList: {
      LoadingFee: 0,
      CardFee: 0,
      GST: 0,
      BCCharges: 24,
      ePurseFees: 0,
      passCharges: 0,
      TotalCharge: 24
    },
    cardType: 'OTC'
  },
  surrender: {
    requestId: '161303473876000130v22',
    responseCode: 200,
    responseMessage: 'Success',
    ResponseData: {
      responseCode: 4999,
      responseMessage: '4999: Connection Timed Out. Please perform Transaction Enquiry to confirm status.',
      requestId: '161303473424200130v22',
      txnTime: '2021-02-11 02:42 PM'
    }
  }
}

export const utility = {
  networkStatus: navigator.onLine,
  oneTime: 0,
};
