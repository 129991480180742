import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import moment from 'moment';

import { logout } from 'actions';

// Form Data Change
export const OTC_REGISTRATION_FORM_DATA_CHANGE = 'OTC_REGISTRATION_FORM_DATA_CHANGE';

// Customer search
export const CUSTOMER_SEARCH_REQUEST = 'CUSTOMER_SEARCH_REQUEST';
export const CUSTOMER_SEARCH_SUCCESS = 'CUSTOMER_SEARCH_SUCCESS';
export const CUSTOMER_SEARCH_FAILURE = 'CUSTOMER_SEARCH_FAILURE';

// Charges
export const OTC_CARD_CHARGES_REQUEST = 'OTC_CARD_CHARGES_REQUEST';
export const OTC_CARD_CHARGES_SUCCESS = 'OTC_CARD_CHARGES_SUCCESS';
export const OTC_CARD_CHARGES_FAILURE = 'OTC_CARD_CHARGES_FAILURE';

// Otp Send
export const OTP_SEND_REQUEST = 'OTP_SEND_REQUEST';
export const OTP_SEND_SUCCESS = 'OTP_SEND_SUCCESS';
export const OTP_SEND_FAILURE = 'OTP_SEND_FAILURE';


// GET PACKAGE LIST
export const GET_PACKAGE_LIST_REQUEST = 'GET_PACKAGE_LIST_REQUEST';
export const GET_PACKAGE_LIST_SUCCESS = 'GET_PACKAGE_LIST_SUCCESS';
export const GET_PACKAGE_LIST_FAILURE = 'GET_PACKAGE_LIST_FAILURE';

// Wallet Created
export const WALLET_CREATE_REQUEST = 'WALLET_CREATE_REQUEST';
export const WALLET_CREATE_SUCCESS = 'WALLET_CREATE_SUCCESS';
export const WALLET_CREATE_FAILURE = 'WALLET_CREATE_FAILURE';

// Wallet Created Transaction Enquiry
export const WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST = 'WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST';
export const WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS = 'WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS';
export const WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE = 'WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE';

// Wallet Created Reposting
export const WALLET_CREATE_REPOSTING_REQUEST = 'WALLET_CREATE_REPOSTING_REQUEST';
export const WALLET_CREATE_REPOSTING_SUCCESS = 'WALLET_CREATE_REPOSTING_SUCCESS';
export const WALLET_CREATE_REPOSTING_FAILURE = 'WALLET_CREATE_REPOSTING_FAILURE';

// VPA LINKING
export const VPA_LINKING_REQUEST = 'VPA_LINKING_REQUEST';
export const VPA_LINKING_SUCCESS = 'VPA_LINKING_SUCCESS';
export const VPA_LINKING_FAILURE = 'VPA_LINKING_FAILURE';

// GET TID
export const GET_TID_REQUEST = 'GET_TID_REQUEST';
export const GET_TID_SUCCESS = 'GET_TID_SUCCESS';
export const GET_TID_FAILURE = 'GET_TID_FAILURE';

// SET VPA ENQUIRY
export const VPA_LINKING_ENQUIRY_REQUEST = 'VPA_LINKING_ENQUIRY_REQUEST';
export const VPA_LINKING_ENQUIRY_SUCCESS = 'VPA_LINKING_ENQUIRY_SUCCESS';
export const VPA_LINKING_ENQUIRY_FAILURE = 'VPA_LINKING_ENQUIRY_FAILURE';

// Back Navigation
export const BACK_HOME_OTC_CARD_REGISTRATION = 'BACK_HOME_OTC_CARD_REGISTRATION';

// Change Unique ID
export const CHANGE_UNIQUE_FORM = 'CHANGE_UNIQUE_FORM';
export const CHANGE_UNIQUE_ID = 'CHANGE_UNIQUE_ID';

// Error Message
export const ERROR_MEESAGE_OTC_REG = 'ERROR_MEESAGE_OTC_REG';

// TIMER
export const TIMER_OTC_REG = 'TIMER_OTC_REG';

// Back Action
export const BACK_ERROR_OTC_REG = 'BACK_ERROR_OTC_REG';

export const backAction = (status) => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_OTC_REG,
    formdata: formdata
  })
}

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_OTC_CARD_REGISTRATION,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: OTC_REGISTRATION_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const customerSearch = () => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  console.log('>>>>>>', formdata);
  let user = store.getState().session.user;
  
  dispatch({
    type: CUSTOMER_SEARCH_REQUEST,
  });

  Instance
    .post('/citycash-bc/OTCCustomerSearch/', {
      requestId: requestId(),
      txnType: 'otc_registration_customer_search',
      cardId: formdata.cardId,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      depotCode: user.depotCode,
      CustInfo: {
        mobileNumber: formdata.mobileNumber.toString(),
        dateOfBirth: moment(formdata.dob).format('YYYY-MM-DD')
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CUSTOMER_SEARCH_SUCCESS,
        formdata: formdata,
        customersearch: {
          responseData: response.data,
        }
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CUSTOMER_SEARCH_FAILURE,
        customersearch: {
          responseData: error
        }
      });
    })
}

export const getCharges = () => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: OTC_CARD_CHARGES_REQUEST,
  });
 
  Instance
    .post('/citycash-bc/OTCRegCharges/', {
      txnType: 'get_otc_reg_charges',
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      loginId: user.loginId,
      sessionId: user.sessionId,
      amount: 100,
      depoCode: user.depotCode,
      agentId: user.agentId,
      divisionId: user.divisionId,
      CustInfo: {
        mobileNumber: formdata.mobileNumber.toString(),
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: OTC_CARD_CHARGES_SUCCESS,
        formdata: formdata,
        charges: {
          responseData: response.data
        }
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: OTC_CARD_CHARGES_FAILURE,
        charges: {
          responseData: error
        }
      });
    })
}

export const sendOtp = (resend) => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: OTP_SEND_REQUEST,
  });

  // formdata.backhide = true;
  // formdata.activeStep += 1;
  // dispatch({
  //   type: OTP_SEND_SUCCESS,
  //   formdata: formdata,
  //   authInfo: {
  //     responseData: {}
  //   }
  // });

  Instance
    .post('/citycash-bc/OTCOtpGenerate/', {
      txnType: 'otc_registration_generate_otp',
      cardType: 'OTC',
      messageId: 4,
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      custIdentifier: {
        mobileNumber: formdata.mobileNumber.toString(),
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.otp = '';
          if(resend === 0){
            formdata.activeStep += 1;
          }
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `OTP send the mobile number (${formdata.mobileNumber})`
            }
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      } else {
        dispatch({
          type: ERROR_MEESAGE_OTC_REG,
          error: {
            errorStatus: true,
            errorType: 'info',
            errorMessage: response.data.responseMessage
          }
        });
      }
      dispatch({
        type: OTP_SEND_SUCCESS,
        formdata: formdata,
        authInfo: {
          responseData: response.data
        }
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: OTP_SEND_FAILURE,
        authInfo: {
          responseData: error
        }
      });
    })
}

export const walletCreation = () => dispatch => {
  let data = store.getState().otcRegistration;
  let formdata = data.formdata;
  let referenceId = data.authInfo.responseData.referenceId;
  let chargeList = data.charges.responseData.ChargeList;
  let user = store.getState().session.user;
  dispatch({
    type: WALLET_CREATE_REQUEST,
    walletCreation: {
      responseData: {
        responseCode: 4999
      }
    },
  });

  // let allSteps = new Set();
  // formdata.steps.forEach(step => {
  //   if(step === 'Otp_Verify'){
  //     allSteps.add('Otp_Verify');
  //     if((store.getState().otcRegistration.walletCreation.responseData.responseCode === 4241 || store.getState().otcRegistration.walletCreation.responseData.responseCode === 4999)){
  //       allSteps.add(store.getState().otcRegistration.walletCreation.responseData.responseCode === 4999 ? 'Transaction_Enquiry' : 'Re_Posting');
  //     }
  //   } else {
  //     allSteps.add(step);
  //   }
  // });

  // formdata.backhide = true;
  // formdata.activeStep += 1;
  // formdata.steps = [...allSteps];

  // dispatch({
  //   type: WALLET_CREATE_SUCCESS,
  //   formdata: formdata,
  //   walletCreation: {
  //     responseData: {
  //       responseCode: 4241
  //     }
  //   },
  // });

  const requestData = {
    auth: {
      otp: formdata.otp,
      referenceId: referenceId
    },
    loginId: user.loginId,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    txnType: 'wallet_creation',
    CustInfo: {
      city: formdata.city,
      email: formdata.email,
      state: 'MAHARASHTRA',
      title: 'Mr',
      gender: formdata.gender,
      address: formdata.address.replace(/\n|\r/g, ' ').slice(0, 40),
      address1: formdata.address.replace(/\n|\r/g, ' ').slice(40, 80),
      country: 'IN',
      pincode: formdata.pinCode,
      commLang: '3',
      lastName: formdata.lastName,
      passCode: 'OTC',
      uniqueID: formdata.proof_reference_number,
      firstName: formdata.firstName,
      ChargeList: {
        AF: 0,
        BF: 0,
        PN: 0,
        EPF: 0,
        GST: chargeList.GST,
        SCF: 0,
        CardFee: chargeList.CardFee,
        BonusAmt: 0,
        BCCharges: chargeList.BCCharges,
        LoadingFee: 0,
        TotalCharge: chargeList.TotalCharge,
        passCharges: chargeList.passCharges
      },
      ExtraDetails: {
        locality: formdata.locality,
        education: formdata.education,
        occupation: formdata.occupation,
        addressType: formdata.type_of_address,
        totalMembers: formdata.earning_member_in_family,
        depotDistance: formdata.distance_from_the_depot,
        monthlyIncome: formdata.monthly_income,
        maritalStatus: formdata.marital_status
      },
      ePurseFees: chargeList.ePurseFees,
      passAmount: chargeList.passCharges,
      passExpiry: '365 Days',
      dateOfBirth: moment(formdata.dob).format('YYYY-MM-DD'),
      serviceType: '',
      mobileNumber: formdata.mobileNumber.toString(),
      routeFirstTo: '',
      uniqueIDType: formdata.proof_type.toString(),
      isPassCreated: '0',
      routeSecondTo: '',
      routeFirstFrom: '',
      routeSecondFrom: ''
    },
    cardType: 'OTC',
    depoCode: user.depotCode,
    trimaxId: formdata.cardId,
    requestId: requestId(),
    sessionId: user.sessionId,
    instaPrint: '',
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    custIdentifier: {
      custCardId: formdata.cardId,
      mobileNumber: formdata.mobileNumber.toString()
    }
  };
  
  Instance
    .post('/citycash-bc/OTCRegistration/', requestData)
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.backhide = true;
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4052) {
          dispatch({
            type: CHANGE_UNIQUE_FORM,
            uniqueIdForm: true
          });
        } else if(response.data.responseCode === 3003) {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add( 'Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });

          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else if(response.data.responseCode === 4241) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add( 'Transaction_Enquiry');
              allSteps.add('Reposting_Success');
            } else {
              allSteps.add(step);
            }
          });

          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: WALLET_CREATE_SUCCESS,
        formdata: formdata,
        walletCreation: {
          responseData: response.data,
        },
        requestData: requestData
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verify'){
            allSteps.add('Otp_Verify');
            allSteps.add( 'Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            allSteps.add(step);
          }
        });

        // formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = [...allSteps];
        dispatch({
          type: WALLET_CREATE_SUCCESS,
          formdata: formdata,
          walletCreation: {
            responseData: error,
          },
          requestData: requestData
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add( 'Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });

          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
          dispatch({
            type: WALLET_CREATE_SUCCESS,
            formdata: formdata,
            walletCreation: {
              responseData: error,
            },
            requestData: requestData
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: WALLET_CREATE_FAILURE,
            walletCreation: {
              responseData: error
            }
          });
        }
      }
    })
}

export const changeUnique = (values) => dispatch => {
  dispatch({
    type: CHANGE_UNIQUE_ID,
    formdata: values,
  });
}

export const changeUniqueForm = (values) => dispatch => {
  dispatch({
    type: CHANGE_UNIQUE_FORM,
    uniqueIdForm: values
  });
}

export const walletCreationTransactionInquiry = () => dispatch => {
  let data = store.getState().otcRegistration;
  let formdata = data.formdata;
  let referenceId = data.authInfo.responseData.referenceId;
  let chargeList = data.charges.responseData.ChargeList;
  let user = store.getState().session.user;
  dispatch({
    type: WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST,
  });

  // dispatch({
  //   type: WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS,
  //   formdata: formdata,
  //   walletCreationTransactionInquiry: {
  //     responseData: {
  //       responseCode: 200
  //     }
  //   },
  // });

  Instance
    .post('/citycash-bc/OTCREGTransactionEnquiry/', {
      txnType: 'reg_txn_enquiry',
      requestId: requestId(),
      referenceId: data.walletCreation.responseData.requestId,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      depoCode: user.depotCode,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      divisionId: user.divisionId,
      RequestData: {
        auth: {
          otp: formdata.otp,
          referenceId: referenceId
        },
        token: user.tokenInfo.token,
        agentId: user.agentId,
        loginId: user.loginId,
        txnType: 'wallet_creation',
        CustInfo: {
          city: formdata.city,
          email: formdata.email,
          state: 'MAHARASHTRA',
          title: 'Mr',
          gender: formdata.gender,
          address: formdata.address.replace(/\n|\r/g, ' ').slice(0, 40),
          address1: formdata.address.replace(/\n|\r/g, ' ').slice(40, 80),
          country: 'IN',
          pincode: formdata.pinCode,
          commLang: '3',
          lastName: formdata.lastName,
          passCode: 'OTC',
          uniqueID: formdata.proof_reference_number,
          firstName: formdata.firstName,
          ChargeList: {
            AF: 0,
            BF: 0,
            PN: 0,
            EPF: 0,
            GST: chargeList.GST,
            SCF: 0,
            CardFee: chargeList.CardFee,
            BonusAmt: 0,
            BCCharges: chargeList.BCCharges,
            LoadingFee: 0,
            TotalCharge: chargeList.TotalCharge,
            passCharges: chargeList.passCharges
          },
          ExtraDetails: {
            locality: formdata.locality,
            education: formdata.education,
            occupation: formdata.occupation,
            addressType: formdata.type_of_address,
            totalMembers: formdata.earning_member_in_family,
            depotDistance: formdata.distance_from_the_depot,
            monthlyIncome: formdata.monthly_income,
            maritalStatus: formdata.marital_status
          },
          ePurseFees: chargeList.ePurseFees,
          passAmount: chargeList.passCharges,
          passExpiry: '365 Days',
          dateOfBirth: moment(formdata.dob).format('YYYY-MM-DD'),
          serviceType: '',
          mobileNumber: formdata.mobileNumber,
          routeFirstTo: '',
          uniqueIDType: formdata.proof_type.toString(),
          isPassCreated: '0',
          routeSecondTo: '',
          routeFirstFrom: '',
          routeSecondFrom: ''
        },
        cardType: 'OTC',
        depoCode: user.depotCode,
        trimaxId: formdata.cardId,
        requestId: data.requestData.requestId,
        sessionId: user.sessionId,
        instaPrint: 0,
        initiatorId: user.initiatorId,
        initiatorType: user.initiatorType,
        custIdentifier: {
          custCardId: formdata.cardId,
          mobileNumber: formdata.mobileNumber.toString()
        }
      }
    })
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else if(response.data.ResponseData.responseCode === 3003) {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.ResponseData.responseMessage
            }
          });
        } else {
          formdata.backhide = true;
          formdata.activeStep += 1;
        }
      }
      dispatch({
        type: WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS,
        formdata: formdata,
        walletCreationTransactionInquiry: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE,
        walletCreationTransactionInquiry: error
      });
    })
}

export const walletCreationReposting = () => dispatch => {
  let data = store.getState().otcRegistration;
  let formdata = data.formdata;
  let referenceId = data.authInfo.responseData.referenceId;
  let chargeList = data.charges.responseData.ChargeList;
  let user = store.getState().session.user;
  
  dispatch({
    type: WALLET_CREATE_REPOSTING_REQUEST,
  });

  // dispatch({
  //   type: WALLET_CREATE_REPOSTING_SUCCESS,
  //   formdata: formdata,
  //   walletCreationReposting: {
  //     responseData: {
  //       responseCode: 200
  //     }
  //   },
  // });
  Instance
    .post('/citycash-bc/OTCregTxnReposting/', {
      txnType: 'registration_reposting',
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      RequestData: {
        auth: {
          otp: formdata.otp,
          referenceId: referenceId
        },
        token: user.tokenInfo.token,
        agentId: user.agentId,
        loginId: user.loginId,
        txnType: 'wallet_creation',
        CustInfo: {
          city: formdata.city,
          email: formdata.email,
          state: 'MAHARASHTRA',
          title: 'Mr',
          gender: formdata.gender,
          address: formdata.address.replace(/\n|\r/g, ' ').slice(0, 40),
          address1: formdata.address.replace(/\n|\r/g, ' ').slice(40, 80),
          country: 'IN',
          pincode: formdata.pinCode,
          commLang: '3',
          lastName: formdata.lastName,
          passCode: 'OTC',
          uniqueID: formdata.proof_reference_number,
          firstName: formdata.firstName,
          ChargeList: {
            AF: 0,
            BF: 0,
            PN: 0,
            EPF: 0,
            GST: chargeList.GST,
            SCF: 0,
            CardFee: chargeList.CardFee,
            BonusAmt: 0,
            BCCharges: chargeList.BCCharges,
            LoadingFee: 0,
            TotalCharge: chargeList.TotalCharge,
            passCharges: chargeList.passCharges
          },
          ExtraDetails: {
            locality: formdata.locality,
            education: formdata.education,
            occupation: formdata.occupation,
            addressType: formdata.type_of_address,
            totalMembers: formdata.earning_member_in_family,
            depotDistance: formdata.distance_from_the_depot,
            monthlyIncome: formdata.marital_status,
            maritalStatus: formdata.monthly_income
          },
          ePurseFees: chargeList.ePurseFees,
          passAmount: chargeList.passCharges,
          passExpiry: '365 Days',
          dateOfBirth: formdata.dob,
          serviceType: '',
          mobileNumber: formdata.mobileNumber.toString(),
          routeFirstTo: '',
          uniqueIDType: formdata.proof_type.toString(),
          isPassCreated: '0',
          routeSecondTo: '',
          routeFirstFrom: '',
          routeSecondFrom: ''
        },
        cardType: 'OTC',
        depoCode: user.depotCode,
        trimaxId: formdata.cardId,
        requestId: data.requestData.requestId,
        sessionId: user.sessionId,
        instaPrint: '',
        initiatorId: user.initiatorId,
        initiatorType: user.initiatorType,
        custIdentifier: {
          custCardId: formdata.cardId,
          mobileNumber: formdata.mobileNumber.toString()
        }
      }
    })
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        }
        formdata.backhide = true;
        formdata.activeStep += 1;
      }
      dispatch({
        type: WALLET_CREATE_REPOSTING_SUCCESS,
        formdata: formdata,
        walletCreationReposting: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      console.log(error)
      dispatch({
        type: WALLET_CREATE_REPOSTING_FAILURE,
        walletCreationReposting: error
      });
    })
}

export const setVPA = (transactionEnquiry) => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: transactionEnquiry ? VPA_LINKING_ENQUIRY_REQUEST : VPA_LINKING_REQUEST
  });

  Instance
    .post('/citycash-bc/setVPA', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      requestId: requestId(),
      txnType: 'SET_VPA',
      custIdentifier: {
        custCardId: formdata.cardId
      },
      amount: 1,
      hopType: user.hopType,
      upi_vpa: formdata.upiId,
      InquiryRequestId: transactionEnquiry ? store.getState().otcRegistration.setvpa.requestId : ''
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(!transactionEnquiry){
            formdata.activeStep += 1;
            formdata.transEnquiry = true;
          } else if([4095, 4079].includes(response.data.responseCode)) {
            dispatch(logout());
          } else {
            if(response.data.ResponseData.responseCode !== 4640){
              clearInterval(store.getState().otcRegistration.timer);
            }
          }
        } else {
          dispatch({
            type: ERROR_MEESAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: transactionEnquiry ? VPA_LINKING_ENQUIRY_SUCCESS : VPA_LINKING_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: transactionEnquiry ? VPA_LINKING_ENQUIRY_FAILURE : VPA_LINKING_FAILURE,
        responseData: error
      });
    })
}

export const getTID = () => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: GET_TID_REQUEST
  });

  Instance
    .post('/citycash-bc/getAvailableCards/', {
      token: user.tokenInfo.token,
      agentId: user.agentId,
      requestId: requestId(),
      txnType: 'get_available_cards',
    })
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          // dispatch({
          //   type: ERROR_MEESAGE_OTC_REG,
          //   error: {
          //     errorStatus: true,
          //     errorType: 'info',
          //     errorMessage: response.data.responseMessage
          //   }
          // });
        }
      }
      dispatch({
        type: GET_TID_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      // dispatch({
      //   type: ERROR_MEESAGE_OTC_REG,
      //   error: {
      //     errorStatus: true,
      //     errorType: 'error',
      //     errorMessage: error.message
      //   }
      // });
      dispatch({
        type: GET_TID_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().otcRegistration.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_OTC_REG,
    error: errorObject
  });
}

export const timerSet = (timer) => dispatch => {
  dispatch({
    type: TIMER_OTC_REG,
    timer: timer
  });
}
