import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.otcCardOrder)

const otcCardOrderReducer = (state = Data.otcCardOrder, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_CARD_ORDER: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_CARD_ORDER: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.CARD_ORDER_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.GET_CARD_ORDER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_CARD_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        noOfCard: action.responseData
      };
    }

    case actionTypes.GET_CARD_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        noOfCard: action.responseData
      };
    }

    case actionTypes.CONFIRM_CARD_ORDER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CONFIRM_CARD_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        confirmResponse: action.responseData,
        enquiry: action.enquiry
      };
    }

    case actionTypes.CONFIRM_CARD_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        confirmResponse: action.responseData
      };
    }

    case actionTypes.CARD_ORDER_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case actionTypes.CARD_ORDER_ENQUIRY_SUCCESS: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        transactionEnquiry: action.transactionEnquiry
      }
    }

    case actionTypes.CARD_ORDER_ENQUIRY_FAILURE: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        transactionEnquiry: action.transactionEnquiry
      }
    }

    case actionTypes.ERROR_MEESAGE_OTC_CARD_ORDER: {
      return {
        ...state,
        error: action.error
      }
    }

    default: {
      return state;
    }
  }
};

export default otcCardOrderReducer;
