import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.dashboard)

const addBalanceReducer = (state = Data.dashboard, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_DASHBOARD: {
      return _.cloneDeep(copyInitialValue)

    }

    case actionTypes.ERROR_MEESAGE_DASHBOARD: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.GET_AGENT_BALANCE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case actionTypes.GET_AGENT_BALANCE_SUCCESS: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        addBalance: action.responseData
      }
    }

    case actionTypes.GET_AGENT_BALANCE_FAILURE: {
      return {
        ...state,
        formdata: action.formdata,
        loading: false,
        addBalance: action.responseData
      }
    }

    default: {
      return state;
    }
  }
};

export default addBalanceReducer;
