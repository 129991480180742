import _ from 'lodash';
import moment from 'moment';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';

import { logout } from 'actions';

// GET CARD LIST
export const CARD_LIST_BUS_PASS_REQUEST = 'CARD_LIST_BUS_PASS_REQUEST';
export const CARD_LIST_BUS_PASS_SUCCESS = 'CARD_LIST_BUS_PASS_SUCCESS';
export const CARD_LIST_BUS_PASS_FAILURE = 'CARD_LIST_BUS_PASS_FAILURE';

// BUS PASS STATUS
export const BUS_PASS_STATUS_REQUEST = 'BUS_PASS_STATUS_REQUEST';
export const BUS_PASS_STATUS_SUCCESS = 'BUS_PASS_STATUS_SUCCESS';
export const BUS_PASS_STATUS_FAILURE = 'BUS_PASS_STATUS_FAILURE';

// CALCULATE FARE
export const CALCULATE_FARE_REQUEST = 'CALCULATE_FARE_REQUEST';
export const CALCULATE_FARE_SUCCESS = 'CALCULATE_FARE_SUCCESS';
export const CALCULATE_FARE_FAILURE = 'CALCULATE_FARE_FAILURE';

// BUS PASS RENEWAL
export const BUS_PASS_RENEWAL_REQUEST = 'BUS_PASS_RENEWAL_REQUEST';
export const BUS_PASS_RENEWAL_SUCCESS = 'BUS_PASS_RENEWAL_SUCCESS';
export const BUS_PASS_RENEWAL_FAILURE = 'BUS_PASS_RENEWAL_FAILURE';

// Back Action
export const BACK_ERROR_BUS_PASS_RENEWAL = 'BACK_ERROR_BUS_PASS_RENEWAL';

// BUS PASS TRANSACTION ENQUIRY
export const BUS_PASS_TRANSACTION_ENQUIRY_REQUEST = 'BUS_PASS_TRANSACTION_ENQUIRY_REQUEST';
export const BUS_PASS_TRANSACTION_ENQUIRY_SUCCESS = 'BUS_PASS_TRANSACTION_ENQUIRY_SUCCESS';
export const BUS_PASS_TRANSACTION_ENQUIRY_FAILURE = 'BUS_PASS_TRANSACTION_ENQUIRY_FAILURE';

// Form Data Change
export const BUS_PASS_FORM_DATA_CHANGE = 'BUS_PASS_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_BUS_PASS = 'BACK_HOME_BUS_PASS';

// Error Message
export const ERROR_MEESAGE_BUS_PASS = 'ERROR_MEESAGE_BUS_PASS';

// RESET CHARGES
export const RESET_CHARGES = 'RESET_CHARGES';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_BUS_PASS,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: BUS_PASS_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const SelectCardInfo = (cardId) => dispatch => {
  let formdata = store.getState().busPass.formdata;
  formdata.cardId = cardId;
  formdata.selectCardInfo = _.find(store.getState().busPass.cardList.cardList, {cardId: cardId});
  dispatch({
    type: BUS_PASS_FORM_DATA_CHANGE,
    formdata: formdata,
  });
}

export const backAction = (status) => dispatch => {
  let formdata = store.getState().busPass.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_BUS_PASS_RENEWAL,
    formdata: formdata
  })
}

export const getCardList = () => dispatch => {
  let formdata = store.getState().busPass.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: CARD_LIST_BUS_PASS_REQUEST
  });
  Instance
    .post('/citycash-bc/getCardListForOnlineRecharge/', {
      customerIdentifierType: formdata.customerIdentifierValue.toString().length === 10 ? 'phone_number' : 'card_id',
      customerIdentifierValue: formdata.customerIdentifierValue,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      rechargeType: 'bp_bc_debit_cash',
      amount: formdata.amount,
      requestId: requestId(),
      txnType: 'get_card_list_for_online_recharge',
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(response.data.cardList.length > 0){
            formdata.activeStep = formdata.activeStep + 1;
          } else {
            dispatch({
              type: ERROR_MEESAGE_BUS_PASS,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: formdata.customerIdentifierValue.length === 10 ? 'Invalid mobile number' : 'Invalid card number'
              }
            });
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_BUS_PASS,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CARD_LIST_BUS_PASS_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_BUS_PASS,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CARD_LIST_BUS_PASS_FAILURE,
        responseData: error
      });
    })
}

export const busPassStatus = () => dispatch => {
  let formdata = store.getState().busPass.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: BUS_PASS_STATUS_REQUEST
  });
  Instance
    .post('/citycash-bc/check-pass-renewal-status', {
      initiatorId: user.initiatorId,
      custIdentifier: {
        trimax_card_id: formdata.selectCardInfo.trimaxId
        // trimax_card_id: '1180000000000287'
      },
      initiatorType: user.initiatorType,
      requestId: requestId(),
      txnType: 'check_pass_renewal_status',
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_BUS_PASS,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: BUS_PASS_STATUS_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_BUS_PASS,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: BUS_PASS_STATUS_FAILURE,
        responseData: error
      });
    })
}

export const calculateFare = () => dispatch => {
  let formdata = store.getState().busPass.formdata;
  let passActivationDate = store.getState().busPass.busPassStatus.passActivationDate;
  let user = store.getState().session.user;
  dispatch({
    type: CALCULATE_FARE_REQUEST
  });
  Instance
    .post('/citycash-bc/calculate-fare', {
      initiatorId: user.initiatorId,
      custIdentifier: {
        trimax_card_id: formdata.selectCardInfo.trimaxId
        // trimax_card_id: '1180000000000287'
      },
      initiatorType: user.initiatorType,
      requestId: requestId(),
      txnType: 'pass_renewal_fare_calculation',
      pass_validity: formdata.passValidity,
      bus_route_type: formdata.busRoute,
      pass_activation_date: passActivationDate,
      token: user.tokenInfo.token,
      agentId: user.agentId,
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          // formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_BUS_PASS,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: CALCULATE_FARE_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_BUS_PASS,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: CALCULATE_FARE_FAILURE,
        responseData: error
      });
    })
}

export const busPassRenewal = () => dispatch => {
  let formdata = store.getState().busPass.formdata;
  let fare = store.getState().busPass.fare;
  let passStatus = store.getState().busPass.busPassStatus;
  let chargesList = store.getState().busPass.fare.ChargeList;
  let user = store.getState().session.user;

  let pass_amount_1 = fare.data[0].pass_amount;

  console.log(passStatus.dualRoute);

  dispatch({
    type: BUS_PASS_RENEWAL_REQUEST
  });

  let buspassData = {
    requestId: requestId(),
    token: user.tokenInfo.token,
    agentId: user.agentId,
    depotCode: user.depotCode,
    custIdentifier: {
      trimax_card_id: formdata.selectCardInfo.trimaxId
    },
    txnType: 'bp_bc_debit_cash',
    terminal_code: user.terminalCode,
    ChargeList: {
      LoadingFee: chargesList.LoadingFee,
      CardFee: chargesList.CardFee,
      GST: chargesList.GST,
      BCCharges: chargesList.BCCharges,
      ePurseFees: chargesList.ePurseFees,
      passCharges: chargesList.passCharges,
      TotalCharge: chargesList.TotalCharge,
      discount: chargesList.discount,
      cashbackTw: chargesList.cashbackTw,
      cashbackSw: chargesList.cashbackSw
    },
    pass_amount_1: pass_amount_1,
    pass_amount_2: (function(){ if (passStatus.dualRoute === 'true') return fare.data[1].pass_amount }()),
    pass_validity: formdata.passValidity,
    bus_route_type: formdata.busRoute,
    pass_activation_date: passStatus.newActivationDate,
    initiatorType: user.initiatorType,
    initiatorId: user.initiatorId,
    passExpiry: moment(passStatus.newActivationDate).add(formdata.passValidity.split(' ')[0], formdata.passValidity.split(' ')[1] === 'Month' ? 'months' : formdata.passValidity.split(' ')[1] === 'Days' ? 'days' : 'years').format('YYYY-MM-DD')
  };
  Instance
    .post('/citycash-bc/pass-renewal-online-bc', buspassData)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Charges_List'){
              allSteps.add('Charges_List');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Bus_Pass_Success');
        } else {
          dispatch({
            type: ERROR_MEESAGE_BUS_PASS,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: BUS_PASS_RENEWAL_SUCCESS,
        formdata: formdata,
        busPass: buspassData,
        responseData: response.data,
      });
    })
    .catch(error => {
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Charges_List'){
            allSteps.add('Charges_List');
            allSteps.add('Transaction_Enquiry');
            allSteps.add('Enquiry_Success');
          } else {
            allSteps.add(step);
          }
        });
        // formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = _.without([...allSteps], 'Bus_Pass_Success');
        dispatch({
          type: BUS_PASS_RENEWAL_SUCCESS,
          formdata: formdata,
          busPass: buspassData,
          responseData: error,
        });
      } else{
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Charges_List'){
              allSteps.add('Charges_List');
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = _.without([...allSteps], 'Bus_Pass_Success');
          dispatch({
            type: BUS_PASS_RENEWAL_SUCCESS,
            formdata: formdata,
            busPass: buspassData,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_BUS_PASS,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: BUS_PASS_RENEWAL_FAILURE,
            responseData: error
          });
        }
      }
    })
}

export const busPassTransactionEnquiry = () => dispatch => {
  let formdata = store.getState().busPass.formdata;
  let busPassData = store.getState().busPass.busPass;
  // let passAmount = store.getState().busPass.fare;
  // let passStatus = store.getState().busPass.busPassStatus;
  // let chargesList = store.getState().busPass.fare.ChargeList;
  let user = store.getState().session.user;
  dispatch({
    type: BUS_PASS_TRANSACTION_ENQUIRY_REQUEST
  });
  Instance
    .post('/citycash-bc/bus-pass-enq/', {
      txnType: 'BCBPC_txn_enquiry',
      requestId: requestId(),
      initiatorType: user.initiatorType,
      initiatorId: user.initiatorId,
      agentId: user.agentId,
      token: user.tokenInfo.token,
      depoCode: user.depotCode,
      divisionId: user.divisionId,
      custIdentifier: {
        custCardId: formdata.selectCardInfo.cardId,
        mobileNumber: formdata.selectCardInfo.phoneNumber
      },
      RequestData: busPassData
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 4999){
          dispatch({
            type: ERROR_MEESAGE_BUS_PASS,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          formdata.activeStep += 1;
        }
      }
      dispatch({
        type: BUS_PASS_TRANSACTION_ENQUIRY_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_BUS_PASS,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: BUS_PASS_TRANSACTION_ENQUIRY_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().busPass.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_BUS_PASS,
    error: errorObject
  });
}

export const resetCharges = () => dispatch => {
  dispatch({
    type: RESET_CHARGES
  });
}
