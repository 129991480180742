import React from 'react';
import { Router } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConfirmProvider } from "material-ui-confirm";
// import { renderRoutes } from 'react-router-config';
// import { ConnectedRouter } from 'connected-react-router';
import theme from './theme';
import { configureStore as store } from './store';
import { history } from './store/configureStore';
// import routes from './routes';
// import { AuthGuard } from 'components';
import {
  ScrollReset,
  GoogleAnalytics,
  // CookiesNotification
} from './components';
// import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import 'typeface-roboto';

import Routes from './Routes';

// import NetworkDetector from './Hoc/NetworkDetector';

const App = () => {

  // React.useEffect(() => {
  //   history.listen((newLocation, action) => {
  //     console.log(action);
  //     console.log(newLocation);
  //     history.push({
  //       pathname: '/dashboard/card-acceptance',
  //       search: ''
  //     });
  //   });
  // }, [])

  // window.addEventListener('popstate', (event) => {
  //   console.log('>>>>>', event);
  //   history.go(1);
  // });

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {/* <ConnectedRouter history={history}> */}
          <ConfirmProvider>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              {/* <CookiesNotification /> */}
              <Routes />
            </Router>
          </ConfirmProvider>
          {/* <AuthGuard roles={['GUEST', 'AGENT']}> */}
          {/* {renderRoutes(routes)} */}
          {/* </AuthGuard> */}
          {/* </ConnectedRouter> */}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
