import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.block)

const blockReducer = (state = Data.block, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_BLOCK: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_BLOCK: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.BLOCK_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.BLOCK_CARD_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.BLOCK_CARD_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.BLOCK_CARD_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_BLOCK_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_CHARGES_BLOCK_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        getCharges: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_BLOCK_FAILURE: {
      return {
        ...state,
        loading: false,
        getCharges: action.responseData
      };
    }

    case actionTypes.BLOCK_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.BLOCK_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        blockdata: action.blockdata,
        block: action.responseData,
        transactionEnquiry: action.responseData
      };
    }

    case actionTypes.BLOCK_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        block: action.responseData
      };
    }

    case actionTypes.BLOCK_TRANSACTION_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.BLOCK_TRANSACTION_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        transactionEnquiry: action.responseData
      };
    }

    case actionTypes.BLOCK_TRANSACTION_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        transactionEnquiry: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_BLOCK: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case actionTypes.BLOCK_OTP_SEND_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.BLOCK_OTP_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        otpInfo: action.otpInfo,
        formdata: action.formdata
      }
    }
    case actionTypes.BLOCK_OTP_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        otpInfo: action.otpInfo
      }
    }
    case actionTypes.DIALOG_BLOCK: {
      return {
        ...state,
        dialog: action.dialog
      }
    }

    default: {
      return state;
    }
  }
};

export default blockReducer;
