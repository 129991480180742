import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.packageOTCRegistration);

const packageOTCRegistrationReducer = (state = Data.packageOTCRegistration, action) => {
  switch (action.type) {

    case actionTypes.BACK_HOME_PACKAGE_OTC_CARD_REGISTRATION: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_PACKAGE_OTC_REG: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.PACKAGE_OTC_REGISTRATION_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.CHOOSE_PACKAGE_TYPE:
      state.formdata.packageType = action.packageType
      return {
        ...state
      }
      
    case actionTypes.CUSTOMER_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.GET_PACKAGE_LIST_REQUEST: {
      return {
        ...state,
        loading: false,
      }
    }

    case actionTypes.GET_PACKAGE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        packageList: action.packageList
      }
    }

    case actionTypes.GET_PACKAGE_LIST_FAILURE: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.PACKAGE_CUSTOMER_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.PACKAGE_CUSTOMER_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerSearch: action.customersearch,
        formdata: action.formdata
      }
    }
    case actionTypes.PACKAGE_CUSTOMER_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        customerSearch: action.customersearch
      }
    }

    case actionTypes.PACKAGE_OTC_CARD_CHARGES_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.PACKAGE_OTC_CARD_CHARGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        charges: action.charges,
        formdata: action.formdata
      }
    }
    case actionTypes.PACKAGE_OTC_CARD_CHARGES_FAILURE: {
      return {
        ...state,
        loading: false,
        charges: action.charges
      }
    }
    case actionTypes.PACKAGE_OTP_SEND_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.PACKAGE_OTP_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        authInfo: action.authInfo,
        formdata: action.formdata
      }
    }
    case actionTypes.PACKAGE_OTP_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        authInfo: action.authInfo
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        walletCreation: action.walletCreation
      }
    }
    
    case actionTypes.PACKAGE_WALLET_CREATE_SUCCESS: {
      if (_.has(action.walletCreation.responseData, 'referenceId')) {
        if(_.isEmpty(action.walletCreation.responseData.referenceId)){
          return {
            ...state,
            loading: false,
            walletCreation: action.walletCreation,
            formdata: action.formdata,
            requestData: action.requestData
          }
        } else {
          return {
            ...state,
            loading: false,
            walletCreation: action.walletCreation,
            authInfo: action.walletCreation,
            formdata: action.formdata,
            requestData: action.requestData
          }
        }
      } else {
        return {
          ...state,
          loading: false,
          walletCreation: action.walletCreation,
          formdata: action.formdata,
          requestData: action.requestData
        }
      }
    }

    case actionTypes.PACKAGE_WALLET_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
        walletCreation: action.walletCreation
      }
    }
    case actionTypes.PACKAGE_CHANGE_UNIQUE_ID: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }
    case actionTypes.PACKAGE_CHANGE_UNIQUE_FORM: {
      return {
        ...state,
        uniqueIdForm: action.uniqueIdForm,
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS: {
      if (_.has(action.walletCreationTransactionInquiry.ResponseData, 'referenceId')) {
        if(_.isEmpty(action.walletCreationTransactionInquiry.ResponseData.referenceId)){
          return {
            ...state,
            loading: false,
            walletCreationTransactionInquiry: action.walletCreation,
            formdata: action.formdata
          }
        } else {
          return {
            ...state,
            loading: false,
            walletCreationTransactionInquiry: action.walletCreationTransactionInquiry,
            authInfo: action.walletCreationTransactionInquiry.ResponseData,
            formdata: action.formdata
          }
        }
      } else {
        return {
          ...state,
          loading: false,
          walletCreationTransactionInquiry: action.walletCreationTransactionInquiry,
          formdata: action.formdata
        }
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        walletCreationTransactionInquiry: action.walletCreationTransactionInquiry
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_REPOSTING_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_REPOSTING_SUCCESS: {
      return {
        ...state,
        loading: false,
        walletCreationReposting: action.walletCreationReposting
      }
    }
    case actionTypes.PACKAGE_WALLET_CREATE_REPOSTING_FAILURE: {
      return {
        ...state,
        loading: false,
        walletCreationReposting: action.walletCreationReposting
      }
    }

    case actionTypes.PACKAGE_TOPUP_SW_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.PACKAGE_TOPUP_SW_SUCCESS: {
      return {
        ...state,
        loading: false,
        topupdata: action.topupdata,
        topupSuccess: action.responseData
      }
    }
    case actionTypes.PACKAGE_TOPUP_SW_FAILURE: {
      return {
        ...state,
        loading: false,
        topupdata: action.topupdata,
        topupSuccess: action.responseData
      }
    }

    case actionTypes.PACKAGE_TOPUP_SW_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    
    case actionTypes.PACKAGE_TOPUP_SW_ENQUIRY_SUCCESS: {
      if (action.transactionEnquiry.responseCode === 4648) {
        return {
          ...state,
          loading: false,
          topupEnquiry: action.transactionEnquiry,
          authInfo: action.transactionEnquiry,
        }
      } else {
        return {
          ...state,
          loading: false,
          topupEnquiry: action.transactionEnquiry
        }
      }
    }
    
    case actionTypes.PACKAGE_TOPUP_SW_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        topupEnquiry: action.topupEnquiry
      }
    }

    case actionTypes.PACKAGE_VPA_LINKING_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.PACKAGE_VPA_LINKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        setvpa: action.responseData
      };
    }

    case actionTypes.PACKAGE_VPA_LINKING_FAILURE: {
      return {
        ...state,
        loading: false,
        setvpa: action.responseData
      };
    }

    case actionTypes.PACKAGE_VPA_LINKING_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: false
      };
    }

    case actionTypes.PACKAGE_VPA_LINKING_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        setvpaenquery: action.responseData
      };
    }

    case actionTypes.PACKAGE_VPA_LINKING_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        setvpaenquery: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_PACKAGE_OTC_REG: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case actionTypes.TIMER_PACKAGE_OTC_REG: {
      return {
        ...state,
        timer: action.timer
      }
    }

    case actionTypes.GET_P_TID_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case actionTypes.GET_P_TID_SUCCESS: {
      return {
        ...state,
        loading: false,
        TID: action.responseData
      }
    }
    case actionTypes.GET_P_TID_FAILURE: {
      return {
        ...state,
        loading: false,
        TID: action.responseData
      }
    }

    default: {
      return state;
    }
  }
};

export default packageOTCRegistrationReducer;
