import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import moment from 'moment';
import _ from 'lodash';

import { logout } from 'actions';

// Form Data Change
export const PACKAGE_OTC_REGISTRATION_FORM_DATA_CHANGE = 'PACKAGE_OTC_REGISTRATION_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_PACKAGE_OTC_CARD_REGISTRATION = 'BACK_HOME_PACKAGE_OTC_CARD_REGISTRATION';

// GET PACKAGE LIST
export const GET_PACKAGE_LIST_REQUEST = 'GET_PACKAGE_LIST_REQUEST';
export const GET_PACKAGE_LIST_SUCCESS = 'GET_PACKAGE_LIST_SUCCESS';
export const GET_PACKAGE_LIST_FAILURE = 'GET_PACKAGE_LIST_FAILURE';

// CHOOSE_PACKAGE_TYPE
export const CHOOSE_PACKAGE_TYPE = 'CHOOSE_PACKAGE_TYPE';

// Customer search
export const PACKAGE_CUSTOMER_SEARCH_REQUEST = 'PACKAGE_CUSTOMER_SEARCH_REQUEST';
export const PACKAGE_CUSTOMER_SEARCH_SUCCESS = 'PACKAGE_CUSTOMER_SEARCH_SUCCESS';
export const PACKAGE_CUSTOMER_SEARCH_FAILURE = 'PACKAGE_CUSTOMER_SEARCH_FAILURE';

// Charges
export const PACKAGE_OTC_CARD_CHARGES_REQUEST = 'PACKAGE_OTC_CARD_CHARGES_REQUEST';
export const PACKAGE_OTC_CARD_CHARGES_SUCCESS = 'PACKAGE_OTC_CARD_CHARGES_SUCCESS';
export const PACKAGE_OTC_CARD_CHARGES_FAILURE = 'PACKAGE_OTC_CARD_CHARGES_FAILURE';

// Otp Send
export const PACKAGE_OTP_SEND_REQUEST = 'PACKAGE_OTP_SEND_REQUEST';
export const PACKAGE_OTP_SEND_SUCCESS = 'PACKAGE_OTP_SEND_SUCCESS';
export const PACKAGE_OTP_SEND_FAILURE = 'PACKAGE_OTP_SEND_FAILURE';

// Wallet Created
export const PACKAGE_WALLET_CREATE_REQUEST = 'PACKAGE_WALLET_CREATE_REQUEST';
export const PACKAGE_WALLET_CREATE_SUCCESS = 'PACKAGE_WALLET_CREATE_SUCCESS';
export const PACKAGE_WALLET_CREATE_FAILURE = 'PACKAGE_WALLET_CREATE_FAILURE';

// Wallet Created Transaction Enquiry
export const PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST = 'PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST';
export const PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS = 'PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS';
export const PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE = 'PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE';

// Wallet Created Reposting
export const PACKAGE_WALLET_CREATE_REPOSTING_REQUEST = 'PACKAGE_WALLET_CREATE_REPOSTING_REQUEST';
export const PACKAGE_WALLET_CREATE_REPOSTING_SUCCESS = 'PACKAGE_WALLET_CREATE_REPOSTING_SUCCESS';
export const PACKAGE_WALLET_CREATE_REPOSTING_FAILURE = 'PACKAGE_WALLET_CREATE_REPOSTING_FAILURE';

// PACKAGE TOPUP SW
export const PACKAGE_TOPUP_SW_REQUEST = 'PACKAGE_TOPUP_SW_REQUEST';
export const PACKAGE_TOPUP_SW_SUCCESS = 'PACKAGE_TOPUP_SW_SUCCESS';
export const PACKAGE_TOPUP_SW_FAILURE = 'PACKAGE_TOPUP_SW_FAILURE';

// PACKAGE TOPUP SW
export const PACKAGE_TOPUP_SW_ENQUIRY_REQUEST = 'PACKAGE_TOPUP_SW_ENQUIRY_REQUEST';
export const PACKAGE_TOPUP_SW_ENQUIRY_SUCCESS = 'PACKAGE_TOPUP_SW_ENQUIRY_SUCCESS';
export const PACKAGE_TOPUP_SW_ENQUIRY_FAILURE = 'PACKAGE_TOPUP_SW_ENQUIRY_FAILURE';

// VPA LINKING
export const PACKAGE_VPA_LINKING_REQUEST = 'PACKAGE_VPA_LINKING_REQUEST';
export const PACKAGE_VPA_LINKING_SUCCESS = 'PACKAGE_VPA_LINKING_SUCCESS';
export const PACKAGE_VPA_LINKING_FAILURE = 'PACKAGE_VPA_LINKING_FAILURE';

// SET VPA ENQUIRY
export const PACKAGE_VPA_LINKING_ENQUIRY_REQUEST = 'PACKAGE_VPA_LINKING_ENQUIRY_REQUEST';
export const PACKAGE_VPA_LINKING_ENQUIRY_SUCCESS = 'PACKAGE_VPA_LINKING_ENQUIRY_SUCCESS';
export const PACKAGE_VPA_LINKING_ENQUIRY_FAILURE = 'PACKAGE_VPA_LINKING_ENQUIRY_FAILURE';

// Error Message
export const ERROR_MEESAGE_PACKAGE_OTC_REG = 'ERROR_MEESAGE_PACKAGE_OTC_REG';

// GET TID
export const GET_P_TID_REQUEST = 'GET_P_TID_REQUEST';
export const GET_P_TID_SUCCESS = 'GET_P_TID_SUCCESS';
export const GET_P_TID_FAILURE = 'GET_P_TID_FAILURE';

// Change Unique ID
export const PACKAGE_CHANGE_UNIQUE_FORM = 'PACKAGE_CHANGE_UNIQUE_FORM';
export const PACKAGE_CHANGE_UNIQUE_ID = 'PACKAGE_CHANGE_UNIQUE_ID';

// TIMER
export const TIMER_PACKAGE_OTC_REG = 'TIMER_PACKAGE_OTC_REG';

// Back Action
export const BACK_ERROR_PACKAGE_OTC_REG = 'BACK_ERROR_PACKAGE_OTC_REG';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_PACKAGE_OTC_CARD_REGISTRATION,
  })
}

export const backAction = (status) => dispatch => {
  let formdata = store.getState().packageOTCRegistration.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_PACKAGE_OTC_REG,
    formdata: formdata
  })
}

export const choosePackage = (packageType) => dispatch => {
  dispatch({
    type: CHOOSE_PACKAGE_TYPE,
    packageType: packageType
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: PACKAGE_OTC_REGISTRATION_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const getPackages = () => dispatch => {
  let user = store.getState().session.user;
  
  dispatch({
    type: GET_PACKAGE_LIST_REQUEST,
  });

  Instance
    .post('/citycash-bc/getPackages/', {
      requestId: requestId(),
      txnType: 'get_packages',
      loadType: 'REG',
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200) {
          dispatch({
            type: GET_PACKAGE_LIST_SUCCESS,
            packageList: response.data
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: GET_PACKAGE_LIST_FAILURE,
        packageList: error  
      });
    })
}

export const customerSearch = () => dispatch => {
  let formdata = store.getState().packageOTCRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: PACKAGE_CUSTOMER_SEARCH_REQUEST,
  });

  Instance
    .post('/citycash-bc/OTCCustomerSearch/', {
      requestId: requestId(),
      txnType: 'otc_registration_customer_search',
      cardId: formdata.cardId,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      depotCode: user.depotCode,
      CustInfo: {
        mobileNumber: formdata.mobileNumber,
        dateOfBirth: moment(formdata.dob).format('YYYY-MM-DD')
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: PACKAGE_CUSTOMER_SEARCH_SUCCESS,
        formdata: formdata,
        customersearch: {
          responseData: response.data,
        }
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: PACKAGE_CUSTOMER_SEARCH_FAILURE,
        customersearch: {
          responseData: error
        }
      });
    })
}

export const getCharges = () => dispatch => {
  let getValue = store.getState().packageOTCRegistration;
  let formdata = getValue.formdata;
  let user = store.getState().session.user;

  dispatch({
    type: PACKAGE_OTC_CARD_CHARGES_REQUEST,
  });
 
  Instance
    .post('/citycash-bc/OTCPkgRegCharges/', {
      txnType: 'get_otc_pkg_reg_charges',
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      // initiatorId: 'CC_BC',
      // initiatorType: 'CCR',
      loginId: user.loginId,
      sessionId: user.sessionId,
      packageType: formdata.packageType,
      packageAmount: formdata.amount,
      depoCode: user.depotCode,
      agentId: user.agentId,
      divisionId: user.divisionId,
      couponCode: formdata.couponStatus ? formdata.couponCode : '',
      custIdentifier: {
        mobileNumber: formdata.mobileNumber.toString(),
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: PACKAGE_OTC_CARD_CHARGES_SUCCESS,
        formdata: formdata,
        charges: {
          responseData: response.data
        }
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: PACKAGE_OTC_CARD_CHARGES_FAILURE,
        charges: {
          responseData: error
        }
      });
    })
}

export const sendOtp = (resend, messageId) => dispatch => {
  let formdata = store.getState().packageOTCRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: PACKAGE_OTP_SEND_REQUEST,
  });


  Instance
    .post('/citycash-bc/OTCOtpGenerate/', {
      txnType: 'otc_registration_generate_otp',
      cardType: 'OTC',
      messageId: messageId,
      requestId: requestId(),
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      custIdentifier: {
        mobileNumber: formdata.mobileNumber,
        custCardId: formdata.cardId
      },
      token: user.tokenInfo.token,
      agentId: user.agentId
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.otp = '';
          if(resend === 0){
            formdata.activeStep += 1;
          }
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: `OTP send the mobile number (${formdata.mobileNumber})`
            }
          });
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: PACKAGE_OTP_SEND_SUCCESS,
        formdata: formdata,
        authInfo: {
          responseData: response.data
        }
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: PACKAGE_OTP_SEND_FAILURE,
        authInfo: {
          responseData: error
        }
      });
    })
}

export const walletCreation = () => dispatch => {
  let data = store.getState().packageOTCRegistration;
  let formdata = data.formdata;
  let referenceId = data.authInfo.responseData.referenceId;
  let chargeList = data.charges.responseData.ChargeList;
  let user = store.getState().session.user;
  dispatch({
    type: PACKAGE_WALLET_CREATE_REQUEST,
    walletCreation: {

    },
  });

  let requestData ={
    auth: {
      otp: formdata.otp,
      referenceId: referenceId
    },
    loginId: user.loginId,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    txnType: 'wallet_creation',
    CustInfo: {
      city: formdata.city,
      email: formdata.email,
      state: 'MAHARASHTRA',
      title: 'Mr',
      gender: formdata.gender,
      address: formdata.address.replace(/\n|\r/g, ' ').slice(0, 40),
      address1: formdata.address.replace(/\n|\r/g, ' ').slice(40, 80),
      country: 'IN',
      pincode: formdata.pinCode,
      commLang: '3',
      lastName: formdata.lastName,
      passCode: 'OTC',
      uniqueID: formdata.proof_reference_number,
      firstName: formdata.firstName,
      ChargeList: {
        AF: 0,
        BF: 0,
        PN: 0,
        EPF: 0,
        GST: chargeList.GST,
        SCF: 0,
        CardFee: chargeList.CardFee,
        BonusAmt: chargeList.BonusAmt,
        BCCharges: chargeList.BCChargesReg,
        LoadingFee: 0,
        TotalCharge: chargeList.TotalCharge,
        passCharges: chargeList.passCharges
      },
      ExtraDetails: {
        locality: formdata.locality,
        education: formdata.education,
        occupation: formdata.occupation,
        addressType: formdata.type_of_address,
        totalMembers: formdata.earning_member_in_family,
        depotDistance: formdata.distance_from_the_depot,
        monthlyIncome: formdata.monthly_income,
        maritalStatus: formdata.marital_status
      },
      ePurseFees: chargeList.ePurseFees,
      passAmount: chargeList.passCharges,
      passExpiry: '365 Days',
      dateOfBirth: moment(formdata.dob).format('YYYY-MM-DD'),
      serviceType: '',
      mobileNumber: formdata.mobileNumber.toString(),
      routeFirstTo: '',
      uniqueIDType: formdata.proof_type.toString(),
      isPassCreated: '0',
      routeSecondTo: '',
      routeFirstFrom: '',
      routeSecondFrom: ''
    },
    cardType: 'OTC',
    depoCode: user.depotCode,
    packageType: formdata.packageType,
    packageAmount: formdata.amount,
    trimaxId: formdata.cardId,
    requestId: requestId(),
    sessionId: user.sessionId,
    instaPrint: '',
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    custIdentifier: {
      custCardId: formdata.cardId,
      mobileNumber: formdata.mobileNumber.toString()
    },
    couponCode: formdata.couponStatus ? formdata.couponCode : ''
  }
  
  Instance
    .post('/citycash-bc/OTCPackageRegistration/', requestData)
    .then(response => {
      console.log(response);
      if(response.status === 200) {

        if(response.data.responseCode === 200){
          if(formdata.packageType === 'TW'){
            let allSteps = new Set();
            formdata.steps.forEach(step => {
              if(step === 'Otp_Verify'){
                allSteps.add('Otp_Verify');
                allSteps.add('Success');
                // allSteps.add('UPIID_Linking');
              } else {
                allSteps.add(step);
              }
            });
            formdata.backhide = true;
            formdata.activeStep += 1;
            formdata.steps = [...allSteps];
          } else {
            formdata.backhide = true;
            formdata.activeStep += 1;
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4052) {
          dispatch({
            type: PACKAGE_CHANGE_UNIQUE_FORM,
            uniqueIdForm: true
          });
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add('Transaction_Enquiry');
              // allSteps.add('Enquiry_Success');
            } else {
              if(formdata.packageType === 'TW' && step === 'Success'){
                console.log(`Success Remove ${step}`);
              } else {
                allSteps.add(step);
              }
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else if(response.data.responseCode === 4648) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add('Sw_Topup_OTP_Verify');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else if(response.data.responseCode === 4241) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add('Re_Posting');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: PACKAGE_WALLET_CREATE_SUCCESS,
        formdata: formdata,
        walletCreation: {
          responseData: response.data,
        },
        requestData: requestData
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Otp_Verify'){
            allSteps.add('Otp_Verify');
            allSteps.add('Transaction_Enquiry');
            // allSteps.add('Enquiry_Success');
          } else {
            if(formdata.packageType === 'TW' && step === 'Success'){
              console.log(`Success Remove ${step}`);
            } else {
              allSteps.add(step);
            }
          }
        });
        formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = [...allSteps];
        dispatch({
          type: PACKAGE_WALLET_CREATE_SUCCESS,
          formdata: formdata,
          walletCreation: {
            responseData: error,
          },
          requestData: requestData
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add('Transaction_Enquiry');
              // allSteps.add('Enquiry_Success');
            } else {
              if(formdata.packageType === 'TW' && step === 'Success'){
                console.log(`Success Remove ${step}`);
              } else {
                allSteps.add(step);
              }
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
          dispatch({
            type: PACKAGE_WALLET_CREATE_SUCCESS,
            formdata: formdata,
            walletCreation: {
              responseData: error,
            },
            requestData: requestData
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: PACKAGE_WALLET_CREATE_FAILURE,
            walletCreation: {
              responseData: error
            }
          });
        }
      }
    })
}

export const changeUnique = (values) => dispatch => {
  dispatch({
    type: PACKAGE_CHANGE_UNIQUE_ID,
    formdata: values,
  });
}

export const changeUniqueForm = (values) => dispatch => {
  dispatch({
    type: PACKAGE_CHANGE_UNIQUE_FORM,
    uniqueIdForm: values
  });
}

export const walletCreationTransactionInquiry = () => dispatch => {
  let data = store.getState().packageOTCRegistration;
  let formdata = data.formdata;
  let referenceId = data.authInfo.responseData.referenceId;
  let chargeList = data.charges.responseData.ChargeList;
  let user = store.getState().session.user;

  dispatch({
    type: PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_REQUEST,
  });

  Instance
    .post('/citycash-bc/OTCREGTransactionEnquiry/', {
      txnType: 'reg_txn_enquiry',
      requestId: requestId(),
      referenceId: data.walletCreation.responseData.requestId,
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      depoCode: user.depotCode,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      divisionId: user.divisionId,
      RequestData: {
        auth: {
          otp: formdata.otp,
          referenceId: referenceId
        },
        token: user.tokenInfo.token,
        agentId: user.agentId,
        loginId: user.loginId,
        txnType: 'wallet_creation',
        CustInfo: {
          city: formdata.city,
          email: formdata.email,
          state: 'MAHARASHTRA',
          title: 'Mr',
          gender: formdata.gender,
          address: formdata.address.replace(/\n|\r/g, ' ').slice(0, 40),
          address1: formdata.address.replace(/\n|\r/g, ' ').slice(40, 80),
          country: 'IN',
          pincode: formdata.pinCode,
          commLang: '3',
          lastName: formdata.lastName,
          passCode: 'OTC',
          uniqueID: formdata.proof_reference_number,
          firstName: formdata.firstName,
          ChargeList: {
            AF: 0,
            BF: 0,
            PN: 0,
            EPF: 0,
            GST: chargeList.GST,
            SCF: 0,
            CardFee: chargeList.CardFee,
            BonusAmt: 0,
            BCCharges: chargeList.BCChargesReg,
            LoadingFee: 0,
            TotalCharge: chargeList.TotalCharge,
            passCharges: chargeList.passCharges
          },
          ExtraDetails: {
            locality: formdata.locality,
            education: formdata.education,
            occupation: formdata.occupation,
            addressType: formdata.type_of_address,
            totalMembers: formdata.earning_member_in_family,
            depotDistance: formdata.distance_from_the_depot,
            monthlyIncome: formdata.monthly_income,
            maritalStatus: formdata.marital_status
          },
          ePurseFees: chargeList.ePurseFees,
          passAmount: chargeList.passCharges,
          passExpiry: '365 Days',
          dateOfBirth: moment(formdata.dob).format('YYYY-MM-DD'),
          serviceType: '',
          mobileNumber: formdata.mobileNumber.toString(),
          routeFirstTo: '',
          uniqueIDType: formdata.proof_type.toString(),
          isPassCreated: '0',
          routeSecondTo: '',
          routeFirstFrom: '',
          routeSecondFrom: ''
        },
        cardType: 'OTC',
        depoCode: user.depotCode,
        packageType: formdata.packageType,
        packageAmount: formdata.amount,
        trimaxId: formdata.cardId,
        requestId: data.requestData.requestId,
        sessionId: user.sessionId,
        instaPrint: 0,
        initiatorId: user.initiatorId,
        initiatorType: user.initiatorType,
        custIdentifier: {
          custCardId: formdata.cardId,
          mobileNumber: formdata.mobileNumber.toString()
        },
        couponCode: formdata.couponStatus ? formdata.couponCode : ''
      }
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if([4095, 4079].includes(response.data.ResponseData.responseCode)) {
            dispatch(logout());
          } else if(response.data.ResponseData.responseCode === 200) {
            if(formdata.packageType === 'TW'){
              let allSteps = new Set();
              formdata.steps.forEach(step => {
                if(step === 'Transaction_Enquiry'){
                  allSteps.add('Transaction_Enquiry');
                  allSteps.add('Enquiry_Success');
                  // allSteps.add('UPIID_Linking');
                } else {
                  allSteps.add(step);
                }
              });
              formdata.backhide = true;
              formdata.activeStep += 1;
              formdata.steps = [...allSteps];
            } else {
              formdata.backhide = true;
              formdata.activeStep += 1;
            }
          } else if(response.data.ResponseData.responseCode === 4648) {
            let allSteps = new Set();
            formdata.steps.forEach(step => {
              if(step === 'Transaction_Enquiry'){
                allSteps.add('Transaction_Enquiry');
                allSteps.add('Sw_Topup_OTP_Verify');
              } else {
                allSteps.add(step);
              }
            });
            formdata.backhide = true;
            formdata.activeStep += 1;
            formdata.steps = [...allSteps];
          } else if(response.data.ResponseData.responseCode === 4241) {
            let allSteps = new Set();
            formdata.steps.forEach(step => {
              if(step === 'Transaction_Enquiry'){
                allSteps.add('Transaction_Enquiry');
                allSteps.add('Re_Posting');
              } else {
                allSteps.add(step);
              }
            });
            formdata.backhide = true;
            formdata.activeStep += 1;
            formdata.steps = [...allSteps];
          } else if(response.data.ResponseData.responseCode === 3003) {
            dispatch({
              type: ERROR_MEESAGE_PACKAGE_OTC_REG,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: response.data.ResponseData.responseMessage
              }
            });
          } else {
            let allSteps = new Set();
            formdata.steps.forEach(step => {
              if(step === 'Transaction_Enquiry'){
                allSteps.add('Transaction_Enquiry');
                allSteps.add('Enquiry_Success');
              } else {
                allSteps.add(step);
              }
            });
            formdata.backhide = true;
            formdata.activeStep += 1;
            formdata.steps = [...allSteps];
          }
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Transaction_Enquiry'){
              allSteps.add('Transaction_Enquiry');
              allSteps.add('Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        }
        dispatch({
          type: PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_SUCCESS,
          formdata: formdata,
          walletCreationTransactionInquiry: response.data
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: PACKAGE_WALLET_CREATE_TRANSACTION_INQUIRY_FAILURE,
        walletCreationTransactionInquiry: error
      });
    })
}

export const walletCreationReposting = () => dispatch => {
  let data = store.getState().packageOTCRegistration;
  let formdata = data.formdata;
  let referenceId = data.authInfo.responseData.referenceId;
  let chargeList = data.charges.responseData.ChargeList;
  let user = store.getState().session.user;

  dispatch({
    type: PACKAGE_WALLET_CREATE_REPOSTING_REQUEST,
  });

  Instance
    .post('/citycash-bc/OTCregTxnReposting/', {
      txnType: 'registration_reposting',
      requestId: requestId(),
      initiatorId: user.initiatorId,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      RequestData: {
        auth: {
          otp: formdata.otp,
          referenceId: referenceId
        },
        token: user.tokenInfo.token,
        agentId: user.agentId,
        loginId: user.loginId,
        txnType: 'wallet_creation',
        CustInfo: {
          city: formdata.city,
          email: formdata.email,
          state: 'MAHARASHTRA',
          title: 'Mr',
          gender: formdata.gender,
          address: formdata.address.replace(/\n|\r/g, ' ').slice(0, 40),
          address1: formdata.address.replace(/\n|\r/g, ' ').slice(40, 80),
          country: 'IN',
          pincode: formdata.pinCode,
          commLang: '3',
          lastName: formdata.lastName,
          passCode: 'OTC',
          uniqueID: formdata.proof_reference_number,
          firstName: formdata.firstName,
          ChargeList: {
            AF: 0,
            BF: 0,
            PN: 0,
            EPF: 0,
            GST: chargeList.GST,
            SCF: 0,
            CardFee: chargeList.CardFee,
            BonusAmt: 0,
            BCCharges: chargeList.BCChargesReg,
            LoadingFee: 0,
            TotalCharge: chargeList.TotalCharge,
            passCharges: chargeList.passCharges
          },
          ExtraDetails: {
            locality: formdata.locality,
            education: formdata.education,
            occupation: formdata.occupation,
            addressType: formdata.type_of_address,
            totalMembers: formdata.earning_member_in_family,
            depotDistance: formdata.distance_from_the_depot,
            monthlyIncome: formdata.marital_status,
            maritalStatus: formdata.monthly_income
          },
          ePurseFees: chargeList.ePurseFees,
          passAmount: chargeList.passCharges,
          passExpiry: '365 Days',
          dateOfBirth: formdata.dob,
          serviceType: '',
          mobileNumber: formdata.mobileNumber.toString(),
          routeFirstTo: '',
          uniqueIDType: formdata.proof_type.toString(),
          isPassCreated: '0',
          routeSecondTo: '',
          routeFirstFrom: '',
          routeSecondFrom: ''
        },
        cardType: 'OTC',
        depoCode: user.depotCode,
        packageType: formdata.packageType,
        packageAmount: formdata.amount,
        trimaxId: formdata.cardId,
        requestId: data.requestData.requestId,
        sessionId: user.sessionId,
        instaPrint: '',
        initiatorId: user.initiatorId,
        initiatorType: user.initiatorType,
        custIdentifier: {
          custCardId: formdata.cardId,
          mobileNumber: formdata.mobileNumber.toString()
        },
        couponCode: formdata.couponStatus ? formdata.couponCode : ''
      }
    })
    .then(response => {
      console.log(response);
      if(response.status === 200) {
        if(response.data.responseCode !== 200){
          if([4095, 4079].includes(response.data.responseCode)) {
            dispatch(logout());
          } else {
            dispatch({
              type: ERROR_MEESAGE_PACKAGE_OTC_REG,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: response.data.responseCode
              }
            });
          }
        }
      }
      dispatch({
        type: PACKAGE_WALLET_CREATE_REPOSTING_SUCCESS,
        formdata: formdata,
        walletCreationReposting: {
          responseData: response.data,
        }
      });
    })
    .catch(error => {
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      console.log(error)
      dispatch({
        type: PACKAGE_WALLET_CREATE_REPOSTING_FAILURE,
        walletCreationReposting: {
          responseData: error
        }
      });
    })
}

export const setVPA = (transactionEnquiry) => dispatch => {
  let formdata = store.getState().packageOTCRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: transactionEnquiry ? PACKAGE_VPA_LINKING_ENQUIRY_REQUEST : PACKAGE_VPA_LINKING_REQUEST
  });

  Instance
    .post('/citycash-bc/setVPA', {
      initiatorId: user.initiatorId,
      initiatorType: user.initiatorType,
      token: user.tokenInfo.token,
      agentId: user.agentId,
      requestId: requestId(),
      txnType: 'SET_VPA',
      custIdentifier: {
        custCardId: formdata.cardId
      },
      amount: 1,
      hopType: user.hopType,
      upi_vpa: formdata.upiId,
      InquiryRequestId: transactionEnquiry ? store.getState().packageOTCRegistration.setvpa.requestId : ''
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(!transactionEnquiry){
            formdata.activeStep += 1;
            formdata.transEnquiry = true;
          } else if([4095, 4079].includes(response.data.responseCode)) {
            dispatch(logout());
          } else {
            if(response.data.ResponseData.responseCode !== 4640){
              clearInterval(store.getState().otcRegistration.timer);
            }
          }
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: transactionEnquiry ? PACKAGE_VPA_LINKING_ENQUIRY_SUCCESS : PACKAGE_VPA_LINKING_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: transactionEnquiry ? PACKAGE_VPA_LINKING_ENQUIRY_FAILURE : PACKAGE_VPA_LINKING_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().packageOTCRegistration.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_PACKAGE_OTC_REG,
    error: errorObject
  });
}

export const timerSet = (timer) => dispatch => {
  dispatch({
    type: TIMER_PACKAGE_OTC_REG,
    timer: timer
  });
}

export const topup = () => dispatch => {
  let formdata = store.getState().packageOTCRegistration.formdata;
  let getCharges = store.getState().packageOTCRegistration.charges.responseData;
  let referenceId = _.has(store.getState().packageOTCRegistration, 'authInfo.responseData') ? store.getState().packageOTCRegistration.authInfo.responseData.referenceId : store.getState().packageOTCRegistration.authInfo.referenceId;
  let user = store.getState().session.user;
  
  dispatch({
    type: PACKAGE_TOPUP_SW_REQUEST
  });

  let topupdata = {
    txnType: 'sw_topup',
    paymentMode: formdata.paymentMode,
    loadType: 'REG',
    custIdentifier: {
      mobileNumber: formdata.mobileNumber.toString(),
      custCardId: formdata.cardId
    },
    auth: {
      otp: formdata.topOtp,
      referenceId: referenceId
    },
    amount: formdata.amount,
    amountType: 2,
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    loginId: user.loginId,
    sessionId: user.sessionId,
    depoCode: user.depotCode,
    agentId: user.agentId,
    divisionId: user.divisionId,
    LoadingFeeTw: getCharges.ChargeList.LoadingFeeTw,
    LoadingFeeSw: getCharges.ChargeList.LoadingFeeSw,
    CardFee: getCharges.ChargeList.CardFee,
    GSTTw: getCharges.ChargeList.GSTTw,
    GSTSw: getCharges.ChargeList.GSTSw,
    BCChargesTw: getCharges.ChargeList.BCChargesTw,
    BCChargesSw: getCharges.ChargeList.BCChargesSw,
    ePurseFees: getCharges.ChargeList.ePurseFees,
    passCharges: 0.0,
    TotalCharge: getCharges.ChargeList.TotalCharge,
    BonusAmt: getCharges.ChargeList.BonusAmt,
    discount: getCharges.ChargeList.discount,
    cashbackTw: getCharges.ChargeList.cashbackTw,
    cashbackSw: getCharges.ChargeList.cashbackSw,
    EffectiveAmountSw: getCharges.EffectiveAmountSw,
    EffectiveAmountTw: getCharges.EffectiveAmountTw,
    packageType: formdata.packageType,
    packageAmount: formdata.amount,
    token: user.tokenInfo.token,
  };
  
  Instance
    .post('/citycash-bc/SWTopUp/', topupdata)
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep += 1;
          formdata.backhide = true;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Sw_Topup_OTP_Verify'){
              allSteps.add('Sw_Topup_OTP_Verify');
              allSteps.add('sw_eqniury');
            } else {
              allSteps.add(step);
            }
          });
          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: PACKAGE_TOPUP_SW_SUCCESS,
        formdata: formdata,
        topupdata: topupdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Sw_Topup_OTP_Verify'){
            allSteps.add('Sw_Topup_OTP_Verify');
            allSteps.add('sw_eqniury');
          } else {
            allSteps.add(step);
          }
        });
        // formdata.backhide = true;
        formdata.activeStep += 1;
        formdata.steps = [...allSteps];
        dispatch({
          type: PACKAGE_TOPUP_SW_SUCCESS,
          formdata: formdata,
          topupdata: topupdata,
          responseData: error,
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Sw_Topup_OTP_Verify'){
              allSteps.add('Sw_Topup_OTP_Verify');
              allSteps.add('sw_eqniury');
            } else {
              allSteps.add(step);
            }
          });
          // formdata.backhide = true;
          formdata.activeStep += 1;
          formdata.steps = [...allSteps];
          dispatch({
            type: PACKAGE_TOPUP_SW_SUCCESS,
            formdata: formdata,
            topupdata: topupdata,
            responseData: error,
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: PACKAGE_TOPUP_SW_FAILURE,
            formdata: formdata,
            topupdata: topupdata,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiry = () => dispatch => {
  let formdata = store.getState().packageOTCRegistration.formdata;
  let user = store.getState().session.user;
  let txnType = 'sw_txn_enquiry';
  dispatch({
    type: PACKAGE_TOPUP_SW_ENQUIRY_REQUEST
  });

  let topupEnquiry = {
    txnType: txnType,
    requestId: requestId(),
    initiatorId: user.initiatorId,
    initiatorType: user.initiatorType,
    depoCode: user.depotCode,
    token: user.tokenInfo.token,
    agentId: user.agentId,
    divisionId: user.divisionId,
    custIdentifier: {
      mobileNumber: formdata.mobileNumber.toString(),
      custCardId: formdata.cardId
    },
    RequestData: store.getState().packageOTCRegistration.topupdata
  };

  let url = '/citycash-bc/SWTopUpEnquiry/';
  Instance
    .post(url , topupEnquiry)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          dispatch({
            type: ERROR_MEESAGE_PACKAGE_OTC_REG,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else if(response.data.responseCode === 4648) {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Otp_Verify'){
              allSteps.add('Otp_Verify');
              allSteps.add('Sw_Topup_OTP_Verify');
            } else {
              if (step !== 'sw_eqniury') {
                allSteps.add(step);
              }
            }
          });
          formdata.backhide = true;
          formdata.activeStep = 8;
          formdata.topOtp = '';
          formdata.steps = [...allSteps];
          dispatch({
            type: PACKAGE_TOPUP_SW_ENQUIRY_SUCCESS,
            formdata: formdata,
            transactionEnquiry: response.data,
          });
        } else {
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'sw_eqniury'){
              allSteps.add('sw_eqniury');
              allSteps.add('SW_Topup_Enquiry_Success');
            } else {
              allSteps.add(step);
            }
          });
          formdata.steps = [...allSteps];
          formdata.backhide = true;
          formdata.activeStep += 1;
          dispatch({
            type: PACKAGE_TOPUP_SW_ENQUIRY_SUCCESS,
            formdata: formdata,
            transactionEnquiry: response.data,
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_PACKAGE_OTC_REG,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: PACKAGE_TOPUP_SW_ENQUIRY_FAILURE,
        formdata: formdata,
        transactionEnquiry: error
      });
    })
}

export const getTID = () => dispatch => {
  let formdata = store.getState().otcRegistration.formdata;
  let user = store.getState().session.user;
  dispatch({
    type: GET_P_TID_REQUEST
  });

  Instance
    .post('/citycash-bc/getAvailableCards/', {
      token: user.tokenInfo.token,
      agentId: user.agentId,
      requestId: requestId(),
      txnType: 'get_available_cards',
    })
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          // dispatch({
          //   type: ERROR_MEESAGE_OTC_REG,
          //   error: {
          //     errorStatus: true,
          //     errorType: 'info',
          //     errorMessage: response.data.responseMessage
          //   }
          // });
        }
      }
      dispatch({
        type: GET_P_TID_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      // dispatch({
      //   type: ERROR_MEESAGE_OTC_REG,
      //   error: {
      //     errorStatus: true,
      //     errorType: 'error',
      //     errorMessage: error.message
      //   }
      // });
      dispatch({
        type: GET_P_TID_FAILURE,
        responseData: error
      });
    })
}
