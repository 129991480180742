import * as actionTypes from 'actions';
import * as Data from 'data';

const sessionReducer = (state = Data.session, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.SESSION_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        user: action.user,
        loggedIn: action.loggedIn,
        loginStatus: action.responseData
      };
    }

    case actionTypes.USER_UPDATE: {
      return {
        ...state,
        user: action.user,
      }
    }

    case actionTypes.SESSION_LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        loginStatus: action.responseData
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: null
      };
    }

    case actionTypes.ERROR_MEESAGE_USER_LOGIN: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
