import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.surrender)

const surrenderReducer = (state = Data.surrender, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_SURRENDER: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.BACK_ERROR_SURRENDER: {
      return {
        ...state,
        formdata: action.formdata,
      }
    }

    case actionTypes.SURRENDER_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.SURRENDER_CARD_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.SURRENDER_CARD_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.SURRENDER_CARD_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_SURRENDER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_CHARGES_SURRENDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        getCharges: action.responseData
      };
    }

    case actionTypes.GET_CHARGES_SURRENDER_FAILURE: {
      return {
        ...state,
        loading: false,
        getCharges: action.responseData
      };
    }

    case actionTypes.SURRENDER_VALIDATION_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.SURRENDER_VALIDATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        pre_validation: action.responseData
      };
    }

    case actionTypes.SURRENDER_VALIDATION_FAILURE: {
      return {
        ...state,
        loading: false,
        pre_validation: action.responseData
      };
    }

    case actionTypes.SURRENDER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.SURRENDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        surrenderdata: action.surrenderdata,
        surrender: action.responseData,
        transactionEnquiry: action.responseData,
      };
    }

    case actionTypes.SURRENDER_FAILURE: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        surrender: action.responseData
      };
    }

    case actionTypes.GET_SURRENDER_TRANSACTION_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_SURRENDER_TRANSACTION_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        transactionEnquiry: action.responseData
      };
    }

    case actionTypes.GET_SURRENDER_TRANSACTION_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        transactionEnquiry: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_SURRENDER: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }

    case actionTypes.SURRENDER_OTP_SEND_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case actionTypes.SURRENDER_OTP_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        otpInfo: action.otpInfo,
        formdata: action.formdata
      }
    }
    case actionTypes.SURRENDER_OTP_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        otpInfo: action.otpInfo
      }
    }

    default: {
      return state;
    }
  }
};

export default surrenderReducer;
