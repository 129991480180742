// import hash from 'hash.js';
import { v4 as uuidv4 } from 'uuid';
import version from './version';
const S4 = () =>  {
  return (uuidv4());
}

// const stringToHex = (str) => {

//   //converting string into buffer
//   let bufStr = Buffer.from(str, 'utf8');

//   //with buffer, you can convert it into hex with following code
//   return bufStr.toString('hex');
// }

const requestId = (id=undefined) => {
  const agent = localStorage.getItem('user');
  let shacode = null;
  if(agent){
    shacode = JSON.parse(agent).agentId.toString();
  } else {
    shacode = id + S4().substring(1,4);
  }
  const timeelapsed = Date.now().toString();
  return (timeelapsed + shacode + version.number);
}

export default requestId;
