import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.forgotPassword)

const forgotPasswordReducer = (state = Data.forgotPassword, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_FORGOT_PASSWORD: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.FORGOT_PASSWORD_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.AGENT_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.AGENT_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        agentSearch: action.responseData
      };
    }

    case actionTypes.AGENT_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        agentSearch: action.responseData
      };
    }

    case actionTypes.OTP_VERIFY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.OTP_VERIFY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        otpVerify: action.responseData
      };
    }

    case actionTypes.OTP_VERIFY_FAILURE: {
      return {
        ...state,
        loading: false,
        otpVerify: action.responseData
      };
    }

    case actionTypes.PASSWORD_CHANGE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.PASSWORD_CHANGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        passwordChange: action.responseData
      };
    }

    case actionTypes.PASSWORD_CHANGE_FAILURE: {
      return {
        ...state,
        loading: false,
        passwordChange: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_FORGOT_PASSWORD: {
      return {
        ...state,
        error: action.error
      }
    }

    default: {
      return state;
    }
  }
};

export default forgotPasswordReducer;
