import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';

// AGENT SEARCH
export const AGENT_SEARCH_REQUEST = 'AGENT_SEARCH_REQUEST';
export const AGENT_SEARCH_SUCCESS = 'AGENT_SEARCH_SUCCESS';
export const AGENT_SEARCH_FAILURE = 'AGENT_SEARCH_FAILURE';

// OTP VERIFY
export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAILURE = 'OTP_VERIFY_FAILURE';

// PASSWORD CHANGE
export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';

// Form Data Change
export const FORGOT_PASSWORD_FORM_DATA_CHANGE = 'FORGOT_PASSWORD_FORM_DATA_CHANGE';

// DATA RESET
export const BACK_HOME_FORGOT_PASSWORD  ='BACK_HOME_FORGOT_PASSWORD';

// Error Message
export const ERROR_MEESAGE_FORGOT_PASSWORD = 'ERROR_MEESAGE_FORGOT_PASSWORD';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_FORGOT_PASSWORD,
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const agentSearch = (resend) => dispatch => {
  let formdata = store.getState().forgotPassword.formdata;
  dispatch({
    type: AGENT_SEARCH_REQUEST
  });
  Instance
    .post('/citycash-bc/merchant-send-otp', {
      requestId: requestId(formdata.username),
      txnType: 'send_merchant_otp',
      phoneNumber: formdata.username
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          if(resend === 0) {
            formdata.activeStep = formdata.activeStep + 1;
          } else {
            formdata.otp = '';
            dispatch({
              type: ERROR_MEESAGE_FORGOT_PASSWORD,
              error: {
                errorStatus: true,
                errorType: 'info',
                errorMessage: 'otp send your register phone number'
              }
            });
          }
        } else {
          dispatch({
            type: ERROR_MEESAGE_FORGOT_PASSWORD,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: AGENT_SEARCH_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_FORGOT_PASSWORD,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: AGENT_SEARCH_FAILURE,
        responseData: error
      });
    })
}

export const otpVerify = () => dispatch => {
  let formdata = store.getState().forgotPassword.formdata;
  let agentSearch = store.getState().forgotPassword.agentSearch;
  dispatch({
    type: OTP_VERIFY_REQUEST
  });
  Instance
    .post('/citycash-bc/merchant-verify-otp', {
      requestId: requestId(formdata.username),
      txnType: 'verify_merchant_otp',
      phoneNumber: formdata.username,
      auth: {
        otp: formdata.otp,
        referenceId: agentSearch.referenceId
      }
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          formdata.activeStep = formdata.activeStep + 1;
        } else {
          dispatch({
            type: ERROR_MEESAGE_FORGOT_PASSWORD,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: OTP_VERIFY_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_FORGOT_PASSWORD,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: OTP_VERIFY_FAILURE,
        responseData: error
      });
    })
}

export const passwordChange = () => dispatch => {
  let formdata = store.getState().forgotPassword.formdata;
  dispatch({
    type: PASSWORD_CHANGE_REQUEST
  });
  Instance
    .post('/citycash-bc/merchant-forgot-password', {
      requestId: requestId(formdata.username),
      txnType: 'merchant_change_password',
      phoneNumber: formdata.username,
      password: window.btoa(formdata.password)
    })
    .then(response => {
      if(response.status === 200) {
        if(response.data.responseCode === 200){
          dispatch({
            type: ERROR_MEESAGE_FORGOT_PASSWORD,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: 'Password Change Successfully'
            }
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_FORGOT_PASSWORD,
            error: {
              errorStatus: true,
              errorType: 'info',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: PASSWORD_CHANGE_SUCCESS,
        formdata: formdata,
        responseData: response.data,
      });
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_FORGOT_PASSWORD,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: PASSWORD_CHANGE_FAILURE,
        responseData: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().forgotPassword.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_FORGOT_PASSWORD,
    error: errorObject
  });
}
