// import _ from 'lodash';
import { Instance } from '../utils/axiosConfig';
import requestId from '../utils/requestId';
import { configureStore as store } from './../store';
import { logout } from 'actions';

// ADD BALANCE AGENT
export const ADD_BALANCE_REQUEST = 'ADD_BALANCE_REQUEST';
export const ADD_BALANCE_SUCCESS = 'ADD_BALANCE_SUCCESS';
export const ADD_BALANCE_FAILURE = 'ADD_BALANCE_FAILURE';

// Load Enquiry
export const LOAD_ENQUIRY_REQUEST = 'LOAD_ENQUIRY_REQUEST';
export const LOAD_ENQUIRY_SUCCESS = 'LOAD_ENQUIRY_SUCCESS';
export const LOAD_ENQUIRY_FAILURE = 'LOAD_ENQUIRY_FAILURE';

// Form Data Change
export const ADD_BALANCE_FORM_DATA_CHANGE = 'ADD_BALANCE_FORM_DATA_CHANGE';

// Back Navigation
export const BACK_HOME_ADD_BALANCE = 'BACK_HOME_ADD_BALANCE';

// Error Message
export const ERROR_MEESAGE_ADD_BALANCE = 'ERROR_MEESAGE_ADD_BALANCE';

// Back Action
export const BACK_ERROR_ADD_BALANCE = 'BACK_ERROR_ADD_BALANCE';

export const backHome = () => dispatch => {
  dispatch({
    type: BACK_HOME_ADD_BALANCE,
  })
}

export const backAction = (status) => dispatch => {
  let formdata = store.getState().addBalance.formdata;
  formdata.backhide = status;
  dispatch({
    type: BACK_ERROR_ADD_BALANCE,
    formdata: formdata
  })
}

export const formDataChange = (values) => dispatch => {
  dispatch({
    type: ADD_BALANCE_FORM_DATA_CHANGE,
    formdata: values,
  });
}

export const addBalance = () => dispatch => {
  let formdata = store.getState().addBalance.formdata;
  let user = store.getState().session.user;

  dispatch({
    type: ADD_BALANCE_REQUEST
  });

  let requestData = {
    initiatorId: user.initiatorId,
    merchantId: user.agentId,
    initiatorType: user.initiatorType,
    amount: formdata.amount,
    paymentMode: formdata.paymentMode,
    otherDetails: formdata.otherDetails,
    requestId: requestId(),
    txnType: 'agent_bal_load',
    token: user.tokenInfo.token,
    agentId: user.agentId,
  }

  Instance
    .post('/citycash-bc/citycash-bc-merchant-balance-load', requestData)
    .then(response => {
      if(response.status === 200) {
        if([200, '200'].includes(response.data.responseCode)){
          formdata.activeStep = formdata.activeStep + 1;
          formdata.backhide = true;
        } else if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERROR_MEESAGE_ADD_BALANCE,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        }
      }
      dispatch({
        type: ADD_BALANCE_SUCCESS,
        formdata: formdata,
        responseData: response.data,
        enquiry: requestData
      });
    })
    .catch(error => {
      console.log(JSON.stringify(error));
      if(error.code === 'ECONNABORTED') {
        let allSteps = new Set();
        formdata.steps.forEach(step => {
          if(step === 'Load_Amount_Form'){
            allSteps.add('Load_Amount_Form');
            allSteps.add('Enquiry');
          } else {
            allSteps.add(step);
          }
        });
        formdata.steps = [...allSteps];
        formdata.backhide = true;
        formdata.activeStep += 1;
        dispatch({
          type: ADD_BALANCE_SUCCESS,
          formdata: formdata,
          enquiry: requestData,
          responseData: error,  
        });
      } else {
        if(error.message === 'Network Error'){
          let allSteps = new Set();
          formdata.steps.forEach(step => {
            if(step === 'Load_Amount_Form'){
              allSteps.add('Load_Amount_Form');
              allSteps.add('Enquiry');
            } else {
              allSteps.add(step);
            }
          });
          formdata.steps = [...allSteps];
          formdata.backhide = true;
          formdata.activeStep += 1;
          dispatch({
            type: ADD_BALANCE_SUCCESS,
            formdata: formdata,
            enquiry: requestData,
            responseData: error,  
          });
        } else {
          dispatch({
            type: ERROR_MEESAGE_ADD_BALANCE,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: error.message
            }
          });
          dispatch({
            type: ADD_BALANCE_FAILURE,
            responseData: error
          });
        }
      }
    })
}

export const transactionEnquiry = () => dispatch => {
  let formdata = store.getState().addBalance.formdata;
  let user = store.getState().session.user;

  dispatch({
    type: LOAD_ENQUIRY_REQUEST
  });

  let Enquiry = {
    requestId: requestId(),
    txnType: 'agent_bal_load_enquiry',
    token: user.tokenInfo.token,
    merchantId: user.agentId,
    enquiryRequestId: store.getState().addBalance.enquiry.requestId
  };

  Instance
    .post('/citycash-bc/citycash-bc-merchant-balance-load-enquiry' , Enquiry)
    .then(response => {
      if(response.status === 200) {
        if([4095, 4079].includes(response.data.responseCode)) {
          dispatch(logout());
        } else if(response.data.responseCode === 4999) {
          dispatch({
            type: ERROR_MEESAGE_ADD_BALANCE,
            error: {
              errorStatus: true,
              errorType: 'error',
              errorMessage: response.data.responseMessage
            }
          });
        } else {
          formdata.backhide = true;
          formdata.activeStep += 1;
          dispatch({
            type: LOAD_ENQUIRY_SUCCESS,
            formdata: formdata,
            transactionEnquiry: response.data,
          });
        }
      }
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: ERROR_MEESAGE_ADD_BALANCE,
        error: {
          errorStatus: true,
          errorType: 'error',
          errorMessage: error.message
        }
      });
      dispatch({
        type: LOAD_ENQUIRY_FAILURE,
        formdata: formdata,
        transactionEnquiry: error
      });
    })
}

export const errorMessage = (message) => dispatch => {
  let errorObject = {};
  if(message.status){
    errorObject = {
      errorStatus: message.status,
      errorType: message.type,
      errorMessage: message.message
    }
  } else {
    let error = store.getState().addBalance.error;
    errorObject = {
      errorStatus: message.status,
      errorType: error.errorType,
      errorMessage: error.errorMessage
    }
  }
  dispatch({
    type: ERROR_MEESAGE_ADD_BALANCE,
    error: errorObject
  });
}
