import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { backHome as BackLoginPage } from './../../../../actions/forgotPasswordActions';
import { push } from 'connected-react-router';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  logo: {
    height: 36
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar align="center">
        <RouterLink onClick={() => {
          dispatch(BackLoginPage()) 
          dispatch(push('/auth/login'))
          }} style={{ display: "block", margin: "0 auto" }}>
          <img
            className={classes.logo}
            alt="Logo"
            src="/images/logos/logo.png"
            align="center"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
