import * as actionTypes from 'actions';
import * as Data from 'data';
import _ from 'lodash';

const copyInitialValue = _.cloneDeep(Data.upiLink)

const upiLinkReducer = (state = Data.upiLink, action) => {
  switch (action.type) {
    case actionTypes.BACK_HOME_UPI_LINK: {
      return _.cloneDeep(copyInitialValue)
    }

    case actionTypes.UPI_LINK_FORM_DATA_CHANGE:
      return {
        ...state,
        formdata: action.formdata,
      }

    case actionTypes.CARD_LIST_UPI_LINK_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CARD_LIST_UPI_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        cardList: action.responseData
      };
    }

    case actionTypes.CARD_LIST_UPI_LINK_FAILURE: {
      return {
        ...state,
        loading: false,
        cardList: action.responseData
      };
    }

    case actionTypes.SET_VPA_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.SET_VPA_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        setvpa: action.responseData
      };
    }

    case actionTypes.SET_VPA_FAILURE: {
      return {
        ...state,
        loading: false,
        setvpa: action.responseData
      };
    }

    case actionTypes.SET_VPA_ENQUIRY_REQUEST: {
      return {
        ...state,
        loading: false
      };
    }

    case actionTypes.SET_VPA_ENQUIRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        formdata: action.formdata,
        setvpaenquery: action.responseData
      };
    }

    case actionTypes.SET_VPA_ENQUIRY_FAILURE: {
      return {
        ...state,
        loading: false,
        setvpaenquery: action.responseData
      };
    }

    case actionTypes.ERROR_MEESAGE_UPI_LINK: {
      return {
        ...state,
        error: action.error
      }
    }

    case actionTypes.TIMER_UPI_LINK: {
      return {
        ...state,
        timer: action.timer
      }
    }

    default: {
      return state;
    }
  }
};

export default upiLinkReducer;
